import React from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import { Title } from "../ui/Titles";
import { CustomSelect, InputTextField, InputYear } from "../ui/Inputs";

const useStyles = makeStyles((theme) => ({
	quote__form: {
		[theme.breakpoints.down(600)]: {
			padding: "0 15px !important",
		},
	},
}));

export const FormVehicle = React.memo((props) => {
	const classes = useStyles();

	const {
		data,
		brands,
		models,
		state,
		onHandleVerify,
		onHandleChangeStateObj,
		onHandleChangeState,
	} = props;

	console.log("data[0]", data[0]);

	return (
		<form onSubmit={onHandleVerify}>
			<Grid container spacing={3} className={classes.quote__form}>
				{data[0].vehicleInfo?.brand &&
				data[0].vehicleInfo?.model &&
				data[0].vehicleInfo?.brand !== "" &&
				data[0].vehicleInfo?.model !== "" ? (
					<>
						<Grid item xs={12} sm={6} md={6} style={{ zIndex: 9 }}>
							<Box>
								<Title variant="h6" text={state.nameBrand} />
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} style={{ zIndex: 9 }}>
							<Box>
								<Title variant="h6" text={state.nameModel} />
							</Box>
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={12} sm={6} md={6} style={{ zIndex: 9 }}>
							<CustomSelect
								name="brand"
								nameLabel="nameBrand"
								text="Seleccione marca"
								data={brands}
								onHandleChange={onHandleChangeStateObj}
							/>
						</Grid>

						<Grid item xs={12} sm={6} md={6}>
							<CustomSelect
								name="model"
								nameLabel="nameModel"
								text="Seleccione modelo"
								data={models}
								onHandleChange={onHandleChangeStateObj}
							/>
						</Grid>
					</>
				)}

				<Grid item xs={12} sm={6} md={6}>
					{data[0].vehicleInfo?.year && data[0].vehicleInfo?.year !== "" ? (
						<Box>
							<Title variant="h6" text={state.yearSanitized} />
						</Box>
					) : (
						<InputYear
							name="year"
							text="Año"
							value={state.year}
							onHandleChange={onHandleChangeStateObj}
						/>
					)}
				</Grid>

				<Grid item xs={12} sm={6} md={6}>
					{data[0].vehicleInfo?.price && data[0].vehicleInfo?.price > 0 ? (
						<Box>
							<Title variant="h6" text={state.price} />
						</Box>
					) : (
						<InputTextField
							type="number"
							name="price"
							text="Precio"
							value={state.price}
							onHandleChange={onHandleChangeState}
							iconDecoration
							icon={AttachMoneyIcon}
							placeholder="25000"
						/>
					)}
				</Grid>
			</Grid>
			<button type="submit" style={{ display: "none" }} />
		</form>
	);
});
