import { useState, useEffect } from "react";
import { Box, makeStyles, Slider } from "@material-ui/core";

import { numberFormat, validateQuantity } from "../../utils/validations";
import { Title } from "../ui/Titles";

const useStyles = makeStyles((theme) => ({
	quote__info: {
		width: "100%",

		[theme.breakpoints.down(600)]: {
			width: "initial",
			padding: "0 15px !important",
			flexDirection: "column",
		},
	},
}));

export const InfoVehicle = ({
	state,
	plate,
	showrange,
	onHandleChangeStateObj,
	onSetMovedSlider,
}) => {
	const classes = useStyles();

	const [value, setValue] = useState(state.price);
	const [changed, setChanged] = useState(false);

	const handleChange = (event, newValue) => {
		// console.log("newValue", newValue);
		setValue(newValue);

		if (value !== newValue) {
			onSetMovedSlider();
		}

		onHandleChangeStateObj({
			porcentaje: newValue,
		});
	};

	useEffect(() => {
		(function () {
			if (state.price > 0 && !changed) {
				setValue(state.price);
				setChanged((c) => !c);
			}
		})();
	}, [state.price]);

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			className={classes.quote__info}
		>
			<Box width="100%">
				<Title variant="h6" text={plate} uppercase />
				<Title variant="h6" text={state.nameBrand} />
				<Title
					variant="h6"
					text={`${validateQuantity(state.nameModel)} ${state.yearSanitized}`}
				/>
			</Box>
			<Box display="flex" flexDirection="column">
				{showrange ? (
					<>
						<Title variant="h6" text={`${numberFormat(state.porcentaje)}`} />

						<Box mt={3}>
							<Title
								variant="body1"
								text="Modifica el precio de tu vehiculo +/- 10%"
							/>
							<Slider
								value={value}
								min={state.price - state.price * 0.1}
								max={state.price + state.price * 0.1}
								onChange={handleChange}
								color="secondary"
								marks
								step={50}
								aria-labelledby="continuous-slider"
								valueLabelDisplay="off"
							/>
						</Box>
					</>
				) : (
					<Title variant="h6" text={`${numberFormat(state.price)}`} />
				)}
			</Box>
		</Box>
	);
};
