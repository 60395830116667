import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import GA4React from "ga-4-react";

import QuoteProvider from "../context/quote/QuoteContext";
import AlertProvider from "../context/alert/AlertContext";

import { RouterApp } from "../routes";

import "animate.css/animate.min.css";
import UserProvider from "../context/user/UserProvider";

const useStyles = makeStyles((theme) => ({
	app: {
		// height: "100vh",

		[theme.breakpoints.down(600)]: {
			height: "fit-content",
		},
	},
}));

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#071432",
		},
		secondary: {
			main: "#9a324e",
			light: "#9a324e",
		},
		text: {
			primary: "#5a5f64",
		},
	},
});

const ga4react = new GA4React("G-V1VR9LT1RY");
ga4react.initialize();
console.log("ga4react", ga4react);

const App = () => {
	const classes = useStyles();

	return (
		<div className={classes.app}>
			<ThemeProvider theme={theme}>
				<UserProvider>
					<QuoteProvider>
						<AlertProvider>
							<RouterApp />
						</AlertProvider>
					</QuoteProvider>
				</UserProvider>
			</ThemeProvider>
		</div>
	);
};

export default App;
