import { useState, useEffect } from "react";
import {
	makeStyles,
	CircularProgress,
	LinearProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	simpleLoading: {
		color: ({ color }) =>
			color ? theme.palette.secondary.main : theme.palette.common.white,
	},
}));

export const SimpleLoading = ({ size = 40, color }) => {
	const classes = useStyles({ color });

	return (
		<>
			<CircularProgress className={classes.simpleLoading} size={size} />
		</>
	);
};

export const LinearDeterminate = ({
	setHiddenProgress,
	handleSetLoadingProduct,
}) => {
	const [progress, setProgress] = useState(0);
	const [timer, setTimer] = useState(0);

	useEffect(() => {
		const timerInterval = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 100;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		setTimer(timerInterval);

		return () => clearInterval(timer);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (progress === 100) {
			handleSetLoadingProduct();
			setHiddenProgress(true);
			clearInterval(timer);
		}
		// eslint-disable-next-line
	}, [progress, timer, setHiddenProgress]);

	return (
		<>
			<LinearProgress
				color="secondary"
				variant="determinate"
				value={progress}
			/>
		</>
	);
};
