import { useState, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
	makeStyles,
	Box,
	Container,
	Grid,
	Button,
	Hidden,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Modal,
	Backdrop,
	Fade,
} from "@material-ui/core";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { quoteContext } from "../context/quote/QuoteContext";
import { CustomIconButton } from "../components/ui/Buttons";
import { Title } from "../components/ui/Titles";
import { CardPassenger, CardShare } from "../components/ui/Cards";

const useStyles = makeStyles((theme) => ({
	quoteTravelProduct: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
	},
	quoteTravelProduct__container: {
		maxWidth: "1050px",
		width: "100%",
		borderRadius: 15,
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",

		"& .MuiContainer-root": {
			height: "100%",
		},
		"& .MuiGrid-root": {
			height: "100%",
			margin: "0 !important",
		},

		[theme.breakpoints.down(600)]: {
			maxWidth: "520px",
		},
	},
	quoteTravelProduct__content: {
		height: "100%",
		padding: "30px 0 10px",
		overflow: "hidden",
	},
	quoteTravelProduct__content_passengers: {
		maxHeight: "730px",
		height: "100%",
		overflowY: "hidden",
		overflowX: "hidden",
		padding: "0 10px",

		"&:hover": {
			overflowY: "scroll",
		},

		[theme.breakpoints.down(600)]: {
			maxHeight: "fit-content",
		},
	},
	quoteTravelProduct__gridContainer: {
		[theme.breakpoints.down(600)]: {
			padding: "0 !important",
			width: "100%",
		},
	},
	modal: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: "white",
	},
}));

export const QuoteTravelProduct = () => {
	const classes = useStyles();

	const [expanded, setExpanded] = useState(1);
	const [openmodal, setOpenModal] = useState(false);

	const history = useHistory();

	const { selectedProduct, numberPassengers, handleNameCountries } =
		useContext(quoteContext);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const showAccordeon = useCallback(() => {
		const arry = [];

		for (let index = 0; index < numberPassengers; index++) {
			arry.push(index);
		}

		return arry.map((item, index) => (
			<Accordion
				key={index}
				expanded={expanded === index + 1}
				onChange={handleChange(index + 1)}
				style={{ marginBottom: 10 }}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography style={{ fontWeight: 600 }}>
						Pasajero {index + 1}
					</Typography>
				</AccordionSummary>

				<AccordionDetails style={{ padding: "0 0 16px" }}>
					<CardPassenger />
				</AccordionDetails>
			</Accordion>
		));
	}, [numberPassengers, expanded]);

	return (
		<Box className={classes.quoteTravelProduct} py={2}>
			<Box height="100%" className={classes.quoteTravelProduct__container}>
				<Container maxWidth="lg">
					<Grid
						container
						spacing={2}
						className={classes.quoteTravelProduct__gridContainer}
					>
						<Grid item xs={12} sm={12} md={8}>
							<Box
								height="100%"
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
							>
								<Box display="flex">
									<CustomIconButton
										padding="5px"
										backgroundNone
										icon={ArrowBackIosOutlinedIcon}
										onHandleClick={() => history.goBack()}
									/>

									<Box width="100%" textAlign="center">
										<Title
											variant="h6"
											text="Datos de los pasajeros"
											color="black"
										/>
									</Box>
								</Box>

								<Box className={classes.quoteTravelProduct__content}>
									<Box
										className={classes.quoteTravelProduct__content_passengers}
									>
										{showAccordeon()}
									</Box>
								</Box>

								<Box width="100%" display="flex" justifyContent="space-between">
									<Button
										style={{ width: "60%" }}
										variant="outlined"
										color="secondary"
										onClick={() => console.log("save passengers")}
									>
										Guardar
									</Button>

									<Hidden only={["md", "lg", "xl"]}>
										<Button
											style={{ width: "30%" }}
											variant="contained"
											color="secondary"
											onClick={() => setOpenModal((c) => !c)}
										>
											Ver plan
										</Button>
									</Hidden>
								</Box>
							</Box>
						</Grid>
						<Hidden only={["xs", "sm"]}>
							<Grid item xs={12} sm={6} md={4}>
								<Box display="flex" justifyContent="center" py={2}>
									<CardShare
										travel
										selectedPlan
										product={selectedProduct}
										onGetNameCountries={handleNameCountries}
									/>
								</Box>
							</Grid>
						</Hidden>
					</Grid>
				</Container>
			</Box>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={openmodal}
				onClose={() => setOpenModal(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openmodal}>
					<Box className={classes.paper}>
						<CardShare
							travel
							selectedPlan
							product={selectedProduct}
							onGetNameCountries={handleNameCountries}
						/>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};
