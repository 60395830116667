export const formatDate = (date) => {
	const day = new Date(date).getUTCDate();
	const month = new Date(date).getUTCMonth() + 1;
	const year = new Date(date).getUTCFullYear();

	// console.log("date formated", `${day}/${month}/${year}`);

	// return new Date(date).toLocaleDateString();
	return `${day}/${month}/${year}`;
};

export const customGetFullYear = (date) => {
	const year = new Date(date).getUTCFullYear();

	return year;
};

export const getDaysAfter = () => {
	var someDate = new Date();
	var numberOfDaysToAdd = 6;
	return someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
};
