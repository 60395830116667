import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router";

export const PrivateRoute = React.memo(
	({ data, component: Component, ...res }) => {
		// console.log("data", data);

		return (
			<Route
				{...res}
				component={(props) =>
					data !== "" ? <Component {...props} /> : <Redirect to="/" />
				}
			/>
		);
	}
);

PrivateRoute.propTypes = {
	data: PropTypes.string.isRequired,
	component: PropTypes.any.isRequired,
};
