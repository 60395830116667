import axios from "axios";
import { environment } from "./environment";

console.log("enviroment => ", environment.baseURL)

export const clientAxios = axios.create({
	baseURL: environment.baseURL,
	headers: {
		"Content-Type": "application/json;charset=UTF-8",
		"x-api-key": environment.apiKey,
	},
});
