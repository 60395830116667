import PropTypes from "prop-types";
import { makeStyles, Button, IconButton } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CallIcon from "@material-ui/icons/Call";

import { SimpleLoading } from "./Loadings";

const useStyles = makeStyles((theme) => ({
	mainButton: {
		"&:disabled": {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	wppButton: {
		backgroundColor: "#479a53",
		color: theme.palette.common.white,

		"&:hover": {
			backgroundColor: "#429e50",
		},
	},
	customIconButton: {
		backgroundColor: ({ backgroundNone }) =>
			backgroundNone ? "none !important" : theme.palette.secondary.main,
		color: ({ backgroundNone }) =>
			backgroundNone
				? theme.palette.secondary.main
				: theme.palette.common.white,
		padding: ({ padding }) => (padding ? padding : null),

		"&:hover": {
			backgroundColor: ({ backgroundNone }) =>
				backgroundNone ? "none !important" : theme.palette.secondary.dark,
		},
	},
}));

export const MainButton = ({ size = false, text, loading }) => {
	const classes = useStyles();

	return (
		<>
			<Button
				className={`mainButton ${classes.mainButton}`}
				type="submit"
				variant="contained"
				color="secondary"
				fullWidth={size ? true : false}
				disabled={loading ? true : false}
			>
				{loading ? <SimpleLoading size={25} /> : text}
			</Button>
		</>
	);
};

export const PhoneButton = ({ number }) => {
	return (
		<Button variant="outlined" size="large" startIcon={<CallIcon />}>
			{number}
		</Button>
	);
};

export const WppButton = ({ text, number = "34123456789", size }) => {
	const classes = useStyles();

	const handleClick = () => {
		window.location.href = `https://api.whatsapp.com/send?phone=${number}`;
	};

	return (
		<Button
			variant="contained"
			className={classes.wppButton}
			size={size ? "medium" : "large"}
			startIcon={<WhatsAppIcon />}
			onClick={handleClick}
		>
			{text}
		</Button>
	);
};

export const CustomIconButton = ({
	icon: Icon,
	onHandleClick,
	padding,
	backgroundNone,
}) => {
	const classes = useStyles({ padding, backgroundNone });

	return (
		<IconButton
			className={classes.customIconButton}
			onClick={onHandleClick}
			aria-label="icon button"
		>
			<Icon />
		</IconButton>
	);
};

// proptypes //

MainButton.propTypes = {
	size: PropTypes.bool,
	text: PropTypes.string.isRequired,
	loading: PropTypes.bool,
};
WppButton.propTypes = {
	text: PropTypes.string.isRequired,
	number: PropTypes.string,
};
CustomIconButton.propTypes = {
	icon: PropTypes.any.isRequired,
	onHandleClick: PropTypes.func,
};
