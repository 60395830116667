// types for alerts
export const SET_ALERT = "SET_ALERT";

// types for user
export const SET_USER = "SET_USER";

// types for countries
export const LOADING_COUNTRIES = "LOADING_COUNTRIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const ERROR_COUNTRIES = "ERROR_COUNTRIES";

// types for quotes
export const SET_QUOTE_TYPE = "SET_QUOTE_TYPE";
export const SET_QUOTE_LOADING = "SET_QUOTE_LOADING";
export const SET_QUOTE_DATA = "SET_QUOTE_DATA";
export const SET_QUOTE_ERROR = "SET_QUOTE_ERROR";
export const CHANGE_GLOBAL_STATE = "CHANGE_GLOBAL_STATE";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_LOADINGPRODUCT = "SET_LOADINGPRODUCT";
export const SET_NUMBERPASSENGERS = "SET_NUMBERPASSENGERS";
export const SET_EMPTY_STATE = "SET_EMPTY_STATE";
export const SET_DATA_RESPONSE = "SET_DATA_RESPONSE";
export const SET_WPP_VIEW = "SET_WPP_VIEW";
