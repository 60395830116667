import { useContext, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { makeStyles, Box, Grid, Hidden } from "@material-ui/core";
import ReactGA from "react-ga";

import { MainContainer } from "../components/containers/MainContainer";
import { quoteContext } from "../context/quote/QuoteContext";
import { CardShare } from "../components/ui/Cards";
import { SimpleLoading } from "../components/ui/Loadings";
import { Title } from "../components/ui/Titles";
import { PhoneButton, WppButton } from "../components/ui/Buttons";

const useStyles = makeStyles((theme) => ({
	share: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	share__boxContainerImg: {},
	share__content: {
		height: "100%",
		width: "100%",
		padding: "20px 0",
	},
	products__btns: {
		[theme.breakpoints.down(600)]: {
			flexDirection: "column",

			"& button:first-child": {
				marginBottom: 20,
			},
		},
	},
}));

export const Share = () => {
	const classes = useStyles();

	const { search } = useLocation();

	const [safe, setSafe] = useState("");
	const [expanded, setExpanded] = useState(false);

	const {
		loadingCountries,
		errorCountries,
		handleGetCountries,
		handleNameCountries,
	} = useContext(quoteContext);

	const handleChangeAccordeon = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const data = Object.fromEntries(new URLSearchParams(search.replace("?", "")));
	console.log("data share", data);

	useEffect(() => {
		(function () {
			ReactGA.pageview("/quote");
		})();
	}, []);

	useEffect(() => {
		(function () {
			if (Object.keys(data).length === 0) {
				return <Redirect to="/" />;
			} else {
				if (data.type) {
					handleGetCountries();

					if (data.type === "vehicle") {
						setSafe("vehicle");
					}
					if (data.type === "travel") {
						setSafe("travel");
					}
				} else {
					return <Redirect to="/" />;
				}
			}
		})();
		// eslint-disable-next-line
	}, []);

	const showInfo = () => {
		if (loadingCountries) {
			return (
				<Box
					className="animate__animated animate__fadeIn"
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
				>
					<SimpleLoading color />
				</Box>
			);
		}

		if (!loadingCountries && errorCountries) {
			return (
				<Box>
					<h1>Error</h1>
				</Box>
			);
		}

		if (safe === "vehicle") {
			return (
				<Box
					className="animate__animated animate__fadeIn"
					display="flex"
					justifyContent="center"
				>
					<CardShare
						shared
						product={data}
						priceProduct={data.priceProduct}
						accordeon={1}
						expanded={expanded}
						onHandleChangeAccordeon={handleChangeAccordeon}
					/>
				</Box>
			);
		}

		if (safe === "travel") {
			if (!loadingCountries && !errorCountries) {
				return (
					<Box
						className="animate__animated animate__fadeIn"
						display="flex"
						justifyContent="center"
					>
						<CardShare
							shared
							travel
							majorPassenger
							product={data}
							fileUrlPdf={`${data.fileUrl}&producto=${data.producto}&tarifa=${data.tarifa}&edad=${data.edad}&idLanguage=${data.idLanguage}&anual=${data.anual}`}
							onGetNameCountries={handleNameCountries}
						/>
					</Box>
				);
			}
		}
	};

	return (
		<Box className={classes.share} display="flex">
			<Box
				className={classes.share__content}
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				<MainContainer>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						marginBottom={4}
					>
						<Title
							text="Las mejores opciones de cobertura"
							variant="h6"
							color="#9a324e"
						/>
						<Title
							text="Contáctanos directamente"
							variant="subtitle1"
							color="#6f6c6d"
						/>

						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							className={classes.products__btns}
						>
							<PhoneButton number="04-463-3383" />

							<Hidden only={["xs", "sm"]}>
								<Box marginX={2} />
							</Hidden>

							<WppButton text="0985689332" number="0985689332" />
						</Box>
					</Box>
					<Grid container>
						<Grid item xs={12}>
							{showInfo()}
						</Grid>
					</Grid>
				</MainContainer>
			</Box>
		</Box>
	);
};
