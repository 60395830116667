import React from "react";
import { Box, Grid } from "@material-ui/core";

import { TitleMain, TitleWithLineBreak } from "../ui/Titles";
import { InputDate, InputTextField } from "../ui/Inputs";

export const InfoClient = (props) => {
	const {
		data,
		state,
		surnames,
		showsurnames,
		// onHandleChangeState,
		onHandleBlur,
		onHandleChangeSurnames,
		onHandleBlurSurnames,
		onHandleChangeStateObj,
	} = props;

	return (
		<>
			<Box width="100%" textAlign="center">
				<TitleMain variant="h6" text="Tu información" color grid />
			</Box>

			<Grid item xs={12} sm={showsurnames ? 6 : 4} md={showsurnames ? 6 : 4}>
				{data[0].clientInfo.name === "" && state.name === "" && (
					<InputTextField
						name="name"
						text="Nombre Completo"
						value={surnames.name}
						limit={60}
						onHandleChange={onHandleChangeSurnames}
						onHandleBlur={(e) => onHandleBlur(e, "stateLocal")}
					/>
				)}

				{state.name !== "" && (
					<TitleWithLineBreak title="Nombres" text={state.name} />
				)}
			</Grid>

			{showsurnames && (
				<Grid item xs={12} sm={6} md={6}>
					<TitleWithLineBreak
						title="Apellidos"
						text={`${state.surname} ${
							state.lastname !== "" ? state.lastname : ""
						}`}
					/>
				</Grid>
			)}

			{data[0].clientInfo.surname === "" && state.surname === "" && (
				<Grid item xs={12} sm={12} md={4} lg={4}>
					<InputTextField
						name="surname"
						text="Primer Apellido"
						value={surnames.surname}
						limit={60}
						onHandleChange={onHandleChangeSurnames}
						onHandleBlur={onHandleBlurSurnames}
					/>
				</Grid>
			)}

			{state.surname !== "" && !showsurnames && (
				<TitleWithLineBreak title="Primer Apellido" text={state.surname} />
			)}

			{data[0].clientInfo.lastname === "" && state.lastname === "" && (
				<Grid item xs={12} sm={12} md={4} lg={4}>
					<InputTextField
						name="lastname"
						text="Segundo Apellido"
						value={surnames.lastname}
						limit={60}
						onHandleChange={onHandleChangeSurnames}
						onHandleBlur={onHandleBlurSurnames}
					/>
				</Grid>
			)}

			{state.lastname !== "" && !showsurnames && (
				<TitleWithLineBreak title="Segundo Apellido" text={state.lastname} />
			)}

			<Grid item xs={12} sm={6} md={6}>
				{state.age > 0 ? (
					<TitleWithLineBreak title="Edad" text={state.age} />
				) : (
					<TitleWithLineBreak title="Edad" text="Ingrese fecha de nacimiento" />
				)}
			</Grid>

			<Grid item xs={12} sm={6} md={6}>
				{data[0].clientInfo.birthdate !== "" ? (
					<TitleWithLineBreak
						title="Fecha de nacimiento"
						text={state.birthdate}
					/>
				) : (
					<>
						<InputDate
							name="birthdate"
							text="Fecha de nacimiento"
							value={state.birthdateDate}
							birthdate
							onHandleChange={onHandleChangeStateObj}
						/>
						{/* {!state.birthdate && (
							<InputDate
								name="birthdate"
								text="Fecha de nacimiento"
								value={new Date()}
								birthdate
								onHandleChange={onHandleChangeStateObj}
							/>
						) : (
							<TitleWithLineBreak
								title="Fecha de nacimiento"
								text={state.birthdate}
							/>
						)} */}
					</>
				)}
			</Grid>
		</>
	);
};
