
export const mookBrand = [
  {
    "_id": "60993e8c3656b5b1d039f9a2",
    "idMarca": 79,
    "nombreMarca": "CHANGAN",
    "codigoSise": 437,
    "models": [
      {
        "idModelo": 11846,
        "nombreModelo": "ALSVIN AC 1.4",
        "idMarca": 79,
        "idCategoria": 1,
        "codigoSise": 35,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8472,
        "nombreModelo": "CS15 1.5",
        "idMarca": 79,
        "idCategoria": 1,
        "codigoSise": 10,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11143,
        "nombreModelo": "CS35 COMFORT AC 1.6",
        "idMarca": 79,
        "idCategoria": 1,
        "codigoSise": 30,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11503,
        "nombreModelo": "CS55 LUXURY AC 1.5",
        "idMarca": 79,
        "idCategoria": 1,
        "codigoSise": 34,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6808,
        "nombreModelo": "CS75 ÉLITE 1.8TURBO 1.8L",
        "idMarca": 79,
        "idCategoria": 1,
        "codigoSise": 22,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11379,
        "nombreModelo": "STAR 5 PICK UP COMFORT",
        "idMarca": 79,
        "idCategoria": 1,
        "codigoSise": 31,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f99b",
    "idMarca": 110,
    "nombreMarca": "BUICK",
    "codigoSise": 527,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9d1",
    "idMarca": 27,
    "nombreMarca": "HAFEI",
    "codigoSise": 528,
    "models": [
      {
        "idModelo": 970,
        "nombreModelo": "Minitruck 1L P/U C/S 4x2 T/M STD",
        "idMarca": 27,
        "idCategoria": 1,
        "codigoSise": 21,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 972,
        "nombreModelo": "Minivan Cargo 1.1L P/U C/S 4x2 carga T/M STD",
        "idMarca": 27,
        "idCategoria": 1,
        "codigoSise": 23,
        "codTipoVehiculo": 83
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9e0",
    "idMarca": 75,
    "nombreMarca": "INTERNATIONAL",
    "codigoSise": 387,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa0a",
    "idMarca": 43,
    "nombreMarca": "MITSUBISHI",
    "codigoSise": 375,
    "models": [
      {
        "idModelo": 5104,
        "nombreModelo": "ASX",
        "idMarca": 43,
        "idCategoria": 3,
        "codigoSise": 473,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9925,
        "nombreModelo": "ECLIPSE",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 1858,
        "nombreModelo": "Galant",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 576,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11708,
        "nombreModelo": "L 200",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 769,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5780,
        "nombreModelo": "L 300",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 649,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9912,
        "nombreModelo": "LANCER",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 529,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9898,
        "nombreModelo": "MONTERO",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 11,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 4312,
        "nombreModelo": "Nativa",
        "idMarca": 43,
        "idCategoria": 2,
        "codigoSise": 351,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3718,
        "nombreModelo": "Outlander",
        "idMarca": 43,
        "idCategoria": 3,
        "codigoSise": 526,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7277,
        "nombreModelo": "PANEL L 300",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 697,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 9910,
        "nombreModelo": "RVR ES AC 2.0 5P 4X2 TM",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 441,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10288,
        "nombreModelo": "SPACE WAGON",
        "idMarca": 43,
        "idCategoria": 1,
        "codigoSise": 716,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa23",
    "idMarca": 135,
    "nombreMarca": "RANGER",
    "codigoSise": 454,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa2f",
    "idMarca": 95,
    "nombreMarca": "SEAT",
    "codigoSise": 706,
    "models": [
      {
        "idModelo": 11952,
        "nombreModelo": "ALTEA XL AC 1.6 5P 4X2 TM",
        "idMarca": 95,
        "idCategoria": 1,
        "codigoSise": 9,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 7077,
        "nombreModelo": "TOLEDO ECOMOTIVE AC 1.6 5P 4X2 TM DIESEL",
        "idMarca": 95,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa46",
    "idMarca": 80,
    "nombreMarca": "TUKO",
    "codigoSise": 751,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa47",
    "idMarca": 67,
    "nombreMarca": "TUNDRA",
    "codigoSise": 655,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa51",
    "idMarca": 173,
    "nombreMarca": "VYCAST",
    "codigoSise": 703,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa5a",
    "idMarca": 85,
    "nombreMarca": "ZASTAVA",
    "codigoSise": 85,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9c1",
    "idMarca": 65,
    "nombreMarca": "FARGO",
    "codigoSise": 7337,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9c2",
    "idMarca": 132,
    "nombreMarca": "FAW",
    "codigoSise": 483,
    "models": [
      {
        "idModelo": 10245,
        "nombreModelo": "CA6440A45 V80 AC 1.5 5P 4X2 TM",
        "idMarca": 132,
        "idCategoria": 1,
        "codigoSise": 34,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10610,
        "nombreModelo": "CA7158E5Q1 COMFORT D60 AC 1.5 5P 4X2 TM",
        "idMarca": 132,
        "idCategoria": 1,
        "codigoSise": 30,
        "codTipoVehiculo": 76
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9ca",
    "idMarca": 217,
    "nombreMarca": "FUSO",
    "codigoSise": 25,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9de",
    "idMarca": 227,
    "nombreMarca": "ICS",
    "codigoSise": 729,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9ee",
    "idMarca": 222,
    "nombreMarca": "JMCGL",
    "codigoSise": 7371,
    "models": [
      {
        "idModelo": 11440,
        "nombreModelo": "T7 AC 2.2 CD 4X2 TM",
        "idMarca": 222,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9f9",
    "idMarca": 175,
    "nombreMarca": "LAMBORBINI",
    "codigoSise": 629,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa17",
    "idMarca": 136,
    "nombreMarca": "ORO MOTO",
    "codigoSise": 7343,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa1e",
    "idMarca": 46,
    "nombreMarca": "PORSCHE",
    "codigoSise": 378,
    "models": [
      {
        "idModelo": 2216,
        "nombreModelo": "718 Boxster S Turbo",
        "idMarca": 46,
        "idCategoria": 1,
        "codigoSise": 73,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 8889,
        "nombreModelo": "911",
        "idMarca": 46,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2223,
        "nombreModelo": "Boxster",
        "idMarca": 46,
        "idCategoria": 1,
        "codigoSise": 42,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 2226,
        "nombreModelo": "Cayenne",
        "idMarca": 46,
        "idCategoria": 1,
        "codigoSise": 24,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 2233,
        "nombreModelo": "Cayman",
        "idMarca": 46,
        "idCategoria": 1,
        "codigoSise": 28,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 2239,
        "nombreModelo": "Macan",
        "idMarca": 46,
        "idCategoria": 1,
        "codigoSise": 79,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 6936,
        "nombreModelo": "PANAMERA",
        "idMarca": 46,
        "idCategoria": 1,
        "codigoSise": 109,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa32",
    "idMarca": 96,
    "nombreMarca": "SINOTRUK",
    "codigoSise": 717,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa40",
    "idMarca": 116,
    "nombreMarca": "TEKNO",
    "codigoSise": 506,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa4d",
    "idMarca": 105,
    "nombreMarca": "VESPA",
    "codigoSise": 427,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa53",
    "idMarca": 203,
    "nombreMarca": "WILLYS",
    "codigoSise": 7363,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa56",
    "idMarca": 84,
    "nombreMarca": "YAMAHA",
    "codigoSise": 430,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa59",
    "idMarca": 158,
    "nombreMarca": "ZANYA",
    "codigoSise": 7345,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f98e",
    "idMarca": 161,
    "nombreMarca": "ATUL",
    "codigoSise": 7347,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9aa",
    "idMarca": 214,
    "nombreMarca": "D LONG FC",
    "codigoSise": 592,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f997",
    "idMarca": 239,
    "nombreMarca": "BEST",
    "codigoSise": 7379,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9a7",
    "idMarca": 10,
    "nombreMarca": "CHEVROLET",
    "codigoSise": 360,
    "models": [
      {
        "idModelo": 5405,
        "nombreModelo": "AVEO EMOTION",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7192,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12111,
        "nombreModelo": " BEAT LS AC",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8253,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5532,
        "nombreModelo": " D-MAX CRDI",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7413,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5367,
        "nombreModelo": " SAIL AC",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7130,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9468,
        "nombreModelo": ": GRAND VITARA",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 3896,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3965,
        "nombreModelo": "Alto 1L 5p",
        "idMarca": 10,
        "idCategoria": 6,
        "codigoSise": 1228,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 270,
        "nombreModelo": "Astra GLS",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 1123,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12018,
        "nombreModelo": "BEAT LS AC",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8215,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9210,
        "nombreModelo": "BLAZER 4.3 V6 4X4",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7353,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9437,
        "nombreModelo": "BRT HILUX",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 3613,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9669,
        "nombreModelo": "C1500 TAHOE",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 6084,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11500,
        "nombreModelo": "CAMARO AC",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8099,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 302,
        "nombreModelo": "Captiva 3.2L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 2287,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 307,
        "nombreModelo": "Cavalier DLX",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 2926,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9609,
        "nombreModelo": "CHEVROLET",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 5565,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9240,
        "nombreModelo": "CHEVY VAN",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 294,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 5777,
        "nombreModelo": "CHEVYTAXI",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7481,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9227,
        "nombreModelo": "CHEYENNE 4X4 T/A A/A",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 118,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 3971,
        "nombreModelo": "Corsa 1.4L 3p A/A VEN",
        "idMarca": 10,
        "idCategoria": 6,
        "codigoSise": 2269,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5799,
        "nombreModelo": "CRUZE 1.4L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7491,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 348,
        "nombreModelo": "D-Max 2.4L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 5509,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 9091,
        "nombreModelo": "EQ6400LF 1,3L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 4822,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 5502,
        "nombreModelo": "EQUINOX  LT AC 1.5",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7403,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9151,
        "nombreModelo": "ESTEEM 4P 1.6L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 6128,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11401,
        "nombreModelo": "FORSA 1.0",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8057,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9680,
        "nombreModelo": "FTR 32M 7.1",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 6157,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 9644,
        "nombreModelo": "GRAND VITARA 2.0L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 5953,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12112,
        "nombreModelo": "HHR",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8254,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 9306,
        "nombreModelo": "HILUX",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 1242,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11812,
        "nombreModelo": "JIMNY",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8178,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5935,
        "nombreModelo": "K 15",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7517,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 9418,
        "nombreModelo": "LUV  D-MAX 3.0L DIESEL CD TM 4X4 EXTR",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 3340,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 6101,
        "nombreModelo": "MINI BLAZER 4X2 T/A A/A",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7548,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9409,
        "nombreModelo": "N200 VAN PASAJEROS 1.2L TM",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 3235,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 9154,
        "nombreModelo": "N300 MAX CARGO  AC 1.2 5P 4X2 TM",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 6133,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 11544,
        "nombreModelo": "NLR 511 EIV 3.0",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8111,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 5201,
        "nombreModelo": "OPTRA  5P 1.8",
        "idMarca": 10,
        "idCategoria": 3,
        "codigoSise": 6567,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11564,
        "nombreModelo": "ORLANDO 2.4",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 8116,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 468,
        "nombreModelo": "Rodeo 2.6L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 2344,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9236,
        "nombreModelo": "S GRAND VITARA",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 271,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9417,
        "nombreModelo": "SAIL  4P 1.4L",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 3337,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 503,
        "nombreModelo": "San Remo 1.6L 4p",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 290,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5139,
        "nombreModelo": "SILVERADO 4X2 T/A",
        "idMarca": 10,
        "idCategoria": 4,
        "codigoSise": 1239,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 3145,
        "nombreModelo": "Spark 0.8L 5p",
        "idMarca": 10,
        "idCategoria": 6,
        "codigoSise": 2960,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 9218,
        "nombreModelo": "SUPER BRIGADIER",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 50,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 9497,
        "nombreModelo": "SUZUKI GRAND VITARA",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 4186,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4002,
        "nombreModelo": "Swift 1.6L 4p",
        "idMarca": 10,
        "idCategoria": 3,
        "codigoSise": 2703,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9303,
        "nombreModelo": "TAHOE",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 1148,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 9189,
        "nombreModelo": "TOOPER 5P T/M DLX",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 6283,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 4163,
        "nombreModelo": "Tracker LS",
        "idMarca": 10,
        "idCategoria": 4,
        "codigoSise": 5128,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 4165,
        "nombreModelo": "TRAILBLAZER",
        "idMarca": 10,
        "idCategoria": 3,
        "codigoSise": 6192,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9793,
        "nombreModelo": "TRAX AC",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 7439,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5006,
        "nombreModelo": "Trooper 2.3L SUV 4x4 3p",
        "idMarca": 10,
        "idCategoria": 6,
        "codigoSise": 3129,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9107,
        "nombreModelo": "VANS",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 5914,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 9519,
        "nombreModelo": "VITARA",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 4428,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11507,
        "nombreModelo": "VIVANT 2.0 GL TM",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 6012,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 11095,
        "nombreModelo": "ZAFIRA",
        "idMarca": 10,
        "idCategoria": 1,
        "codigoSise": 312,
        "codTipoVehiculo": 83
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9b2",
    "idMarca": 172,
    "nombreMarca": "DAYANG",
    "codigoSise": 396,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9d5",
    "idMarca": 64,
    "nombreMarca": "HINO",
    "codigoSise": 386,
    "models": [
      {
        "idModelo": 12151,
        "nombreModelo": "FC9JJSA 5.1 2P 4X2 TM DIESEL",
        "idMarca": 64,
        "idCategoria": 1,
        "codigoSise": 543,
        "codTipoVehiculo": 84
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9dc",
    "idMarca": 108,
    "nombreMarca": "HYOSUNG",
    "codigoSise": 7340,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9e6",
    "idMarca": 31,
    "nombreMarca": "JEEP",
    "codigoSise": 440,
    "models": [
      {
        "idModelo": 7689,
        "nombreModelo": "CHEROKEE",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3470,
        "nombreModelo": "Compass",
        "idMarca": 31,
        "idCategoria": 3,
        "codigoSise": 52,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10917,
        "nombreModelo": "GRAN CHEROKEE AC",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 364,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7694,
        "nombreModelo": "JEEP WRANGLER SPORT",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 198,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11805,
        "nombreModelo": "LIBERTY",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 399,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10657,
        "nombreModelo": "LIBERTY SPORT",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 354,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10697,
        "nombreModelo": "NEW COMPASS LIMITED AC",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 355,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11597,
        "nombreModelo": "PATRIOT 2.0 5P",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 386,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7363,
        "nombreModelo": "WRANGLER AC 3.8",
        "idMarca": 31,
        "idCategoria": 1,
        "codigoSise": 340,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa4b",
    "idMarca": 103,
    "nombreMarca": "UM",
    "codigoSise": 664,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa52",
    "idMarca": 168,
    "nombreMarca": "WESTERN STAR",
    "codigoSise": 442,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa5c",
    "idMarca": 61,
    "nombreMarca": "ZOTYE",
    "codigoSise": 569,
    "models": [
      {
        "idModelo": 4499,
        "nombreModelo": "Duna 1.6L",
        "idMarca": 61,
        "idCategoria": 6,
        "codigoSise": 21,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11416,
        "nombreModelo": "HUNTER",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 32,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7985,
        "nombreModelo": "NOMADA 1.6",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 62,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3038,
        "nombreModelo": "T600 1.5L",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 67,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 4500,
        "nombreModelo": "XS 1.3L",
        "idMarca": 61,
        "idCategoria": 6,
        "codigoSise": 22,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7979,
        "nombreModelo": "XS6400 1.6 5P 4X2 TM",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 28,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3045,
        "nombreModelo": "Z100 1L",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 70,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 3046,
        "nombreModelo": "Z300 1.5L",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 54,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7007,
        "nombreModelo": "Z360 1.5L",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 78,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3047,
        "nombreModelo": "Z500 1.5L",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 55,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7008,
        "nombreModelo": "Z560 1.5L",
        "idMarca": 61,
        "idCategoria": 1,
        "codigoSise": 79,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9ae",
    "idMarca": 15,
    "nombreMarca": "DAEWOO",
    "codigoSise": 363,
    "models": [
      {
        "idModelo": 7177,
        "nombreModelo": "CIELO BX",
        "idMarca": 15,
        "idCategoria": 1,
        "codigoSise": 119,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4036,
        "nombreModelo": "Espero 2000i 2L 4p",
        "idMarca": 15,
        "idCategoria": 5,
        "codigoSise": 19,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7988,
        "nombreModelo": "LANOS 3P DH",
        "idMarca": 15,
        "idCategoria": 1,
        "codigoSise": 67,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5712,
        "nombreModelo": "MATIZ A/C",
        "idMarca": 15,
        "idCategoria": 1,
        "codigoSise": 109,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4045,
        "nombreModelo": "Nubira 2L 4p Full",
        "idMarca": 15,
        "idCategoria": 5,
        "codigoSise": 61,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7991,
        "nombreModelo": "RACER ETI",
        "idMarca": 15,
        "idCategoria": 1,
        "codigoSise": 105,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4049,
        "nombreModelo": "Tacuma CDX 2L T/M",
        "idMarca": 15,
        "idCategoria": 5,
        "codigoSise": 34,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 5786,
        "nombreModelo": "TICO SL",
        "idMarca": 15,
        "idCategoria": 1,
        "codigoSise": 110,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9bd",
    "idMarca": 210,
    "nombreMarca": "E-Z-GO",
    "codigoSise": 7367,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9c0",
    "idMarca": 163,
    "nombreMarca": "FANGPOWER",
    "codigoSise": 7349,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9cc",
    "idMarca": 107,
    "nombreMarca": "GALARDI",
    "codigoSise": 456,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9cd",
    "idMarca": 24,
    "nombreMarca": "GEELY",
    "codigoSise": 560,
    "models": [
      {
        "idModelo": 887,
        "nombreModelo": "CK GL 1.5L 4p",
        "idMarca": 24,
        "idCategoria": 1,
        "codigoSise": 6,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7230,
        "nombreModelo": "MK 1.6GB",
        "idMarca": 24,
        "idCategoria": 1,
        "codigoSise": 13,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9d4",
    "idMarca": 219,
    "nombreMarca": "HERO",
    "codigoSise": 7370,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9e4",
    "idMarca": 30,
    "nombreMarca": "JAC MOTORS",
    "codigoSise": 7330,
    "models": [
      {
        "idModelo": 1233,
        "nombreModelo": "A-Class",
        "idMarca": 30,
        "idCategoria": 1,
        "codigoSise": 24,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1237,
        "nombreModelo": "B-Class",
        "idMarca": 30,
        "idCategoria": 1,
        "codigoSise": 28,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1239,
        "nombreModelo": "S2 HCF4GB2.3D",
        "idMarca": 30,
        "idCategoria": 1,
        "codigoSise": 30,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1240,
        "nombreModelo": "S3 CKD",
        "idMarca": 30,
        "idCategoria": 1,
        "codigoSise": 31,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1241,
        "nombreModelo": "S5 2.0T 2L SUV",
        "idMarca": 30,
        "idCategoria": 1,
        "codigoSise": 32,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1242,
        "nombreModelo": "T6 HFC 1037DKF",
        "idMarca": 30,
        "idCategoria": 1,
        "codigoSise": 33,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9e7",
    "idMarca": 243,
    "nombreMarca": "JETOUR",
    "codigoSise": 823,
    "models": [
      {
        "idModelo": 12154,
        "nombreModelo": "X70 AC 1.5 5P 4X2 TA",
        "idMarca": 243,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9e8",
    "idMarca": 171,
    "nombreMarca": "JIALING",
    "codigoSise": 398,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9f4",
    "idMarca": 34,
    "nombreMarca": "KIA",
    "codigoSise": 370,
    "models": [
      {
        "idModelo": 5414,
        "nombreModelo": "RIO 1.4L EX 5P",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1457,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5467,
        "nombreModelo": " SPORTAGE LX",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1605,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1316,
        "nombreModelo": "Cadenza CVVT",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 554,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7429,
        "nombreModelo": "CARENS 7P",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1067,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7516,
        "nombreModelo": "CARNIVAL 11 PAS",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 976,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 7423,
        "nombreModelo": "CERATO 1.6",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1058,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4228,
        "nombreModelo": "Grand Carnival",
        "idMarca": 34,
        "idCategoria": 4,
        "codigoSise": 574,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 7493,
        "nombreModelo": "K2700 AC",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 626,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 6115,
        "nombreModelo": "MAGENTIS",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1660,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7463,
        "nombreModelo": "MATRIX GL",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 169,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11871,
        "nombreModelo": "MOHAVE 3.0L",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1888,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6859,
        "nombreModelo": "NEW RIO GT",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1672,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7534,
        "nombreModelo": "NIRO AC",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1409,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5595,
        "nombreModelo": "OPIRUS EX",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1615,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5158,
        "nombreModelo": "PICANTO 1.0L LX",
        "idMarca": 34,
        "idCategoria": 5,
        "codigoSise": 1116,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 7458,
        "nombreModelo": "PREGIO 12P",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1593,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 1411,
        "nombreModelo": "Quoris 3.8L",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 829,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10437,
        "nombreModelo": "RIO 1.4L",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1743,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5151,
        "nombreModelo": "RONDO 5DR",
        "idMarca": 34,
        "idCategoria": 3,
        "codigoSise": 1104,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 5762,
        "nombreModelo": "SEDAN GT LINE AC",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1625,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 11887,
        "nombreModelo": "SOLUTO LX",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1890,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 7467,
        "nombreModelo": "SORENTO",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 242,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7529,
        "nombreModelo": "SOUL",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1308,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7459,
        "nombreModelo": "SPECTRA HB",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1599,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7425,
        "nombreModelo": "SPORTAGE 2",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1060,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1507,
        "nombreModelo": "Stinger GT Biturbo",
        "idMarca": 34,
        "idCategoria": 1,
        "codigoSise": 1591,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa01",
    "idMarca": 102,
    "nombreMarca": "MACK",
    "codigoSise": 389,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa03",
    "idMarca": 77,
    "nombreMarca": "MAN",
    "codigoSise": 390,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa0f",
    "idMarca": 100,
    "nombreMarca": "MOTOR UNO",
    "codigoSise": 343,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa37",
    "idMarca": 53,
    "nombreMarca": "SSANGYONG",
    "codigoSise": 2823,
    "models": [
      {
        "idModelo": 10695,
        "nombreModelo": "KORANDO",
        "idMarca": 53,
        "idCategoria": 1,
        "codigoSise": 93,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10934,
        "nombreModelo": "KYRON CT",
        "idMarca": 53,
        "idCategoria": 1,
        "codigoSise": 96,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 4436,
        "nombreModelo": "Musso",
        "idMarca": 53,
        "idCategoria": 6,
        "codigoSise": 35,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 9802,
        "nombreModelo": "REXTON",
        "idMarca": 53,
        "idCategoria": 1,
        "codigoSise": 14,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 9814,
        "nombreModelo": "S SANGYONG KYRON",
        "idMarca": 53,
        "idCategoria": 1,
        "codigoSise": 52,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 4441,
        "nombreModelo": "Stavic 2.7L DIE",
        "idMarca": 53,
        "idCategoria": 6,
        "codigoSise": 39,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 10778,
        "nombreModelo": "TIVOLI",
        "idMarca": 53,
        "idCategoria": 1,
        "codigoSise": 94,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5927,
        "nombreModelo": "XLV",
        "idMarca": 53,
        "idCategoria": 1,
        "codigoSise": 90,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa4c",
    "idMarca": 118,
    "nombreMarca": "VENTURA",
    "codigoSise": 209,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa4e",
    "idMarca": 190,
    "nombreMarca": "VIASA",
    "codigoSise": 7360,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f988",
    "idMarca": 129,
    "nombreMarca": "AKT",
    "codigoSise": 765,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9ad",
    "idMarca": 101,
    "nombreMarca": "DACIA",
    "codigoSise": 507,
    "models": [
      {
        "idModelo": 10709,
        "nombreModelo": "DUSTER AC 1.5",
        "idMarca": 101,
        "idCategoria": 1,
        "codigoSise": 24,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10754,
        "nombreModelo": "NUEVO DUSTER CONFORT",
        "idMarca": 101,
        "idCategoria": 1,
        "codigoSise": 25,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12157,
        "nombreModelo": "SANDERO AC 1.5",
        "idMarca": 101,
        "idCategoria": 1,
        "codigoSise": 29,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9b8",
    "idMarca": 19,
    "nombreMarca": "DS AUTOMOBILES",
    "codigoSise": 7334,
    "models": [
      {
        "idModelo": 718,
        "nombreModelo": "DS3 Turbo 1.6L 3p",
        "idMarca": 19,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 719,
        "nombreModelo": "DS4 1.6L Turbo 5p",
        "idMarca": 19,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 722,
        "nombreModelo": "DS5 2L Turbo 5p 6T/M A/A 163CV 340Nm Euro V F",
        "idMarca": 19,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 76
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9bf",
    "idMarca": 88,
    "nombreMarca": "FACTORY BIKE",
    "codigoSise": 7339,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9c4",
    "idMarca": 230,
    "nombreMarca": "FLYER II",
    "codigoSise": 7375,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9c6",
    "idMarca": 22,
    "nombreMarca": "FORD",
    "codigoSise": 367,
    "models": [
      {
        "idModelo": 4968,
        "nombreModelo": "Ecosport 2L",
        "idMarca": 22,
        "idCategoria": 3,
        "codigoSise": 509,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5814,
        "nombreModelo": "EDGE",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1237,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6056,
        "nombreModelo": "ESCAPE 3.0 V6 4WD AT",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1249,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10170,
        "nombreModelo": "EXPEDITION EDDIE BAUER",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1312,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5370,
        "nombreModelo": "EXPLORER",
        "idMarca": 22,
        "idCategoria": 3,
        "codigoSise": 16,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10701,
        "nombreModelo": "F 100",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1348,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5423,
        "nombreModelo": "F 150 CABINA SENCILLA",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1125,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7122,
        "nombreModelo": "F 250",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1285,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10156,
        "nombreModelo": "F 350",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1310,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7298,
        "nombreModelo": "F 400",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1300,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10279,
        "nombreModelo": "F 500",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1316,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7144,
        "nombreModelo": "F 550 CHASIS CABINADO",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1289,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7638,
        "nombreModelo": "F 600",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 51,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 7639,
        "nombreModelo": "F 700",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 52,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 11975,
        "nombreModelo": "F 7000",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1451,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 11866,
        "nombreModelo": "F 750",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1442,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 7654,
        "nombreModelo": "F150 AC 3.7 CD 4X2 TA",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 580,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7628,
        "nombreModelo": "F150RC 4X4 4.6L",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 939,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 843,
        "nombreModelo": "F-150XL 3.5L P/U C/S 4x2 T/A A/A",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1162,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 3259,
        "nombreModelo": "Festiva Ávila 1.3L 4p A/A",
        "idMarca": 22,
        "idCategoria": 5,
        "codigoSise": 534,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 5580,
        "nombreModelo": "FESTIVA GL",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1219,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 7659,
        "nombreModelo": "FOCUS 2.0",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 613,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4188,
        "nombreModelo": "Fusion 2.5L 4p",
        "idMarca": 22,
        "idCategoria": 2,
        "codigoSise": 647,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10247,
        "nombreModelo": "H1-J AC 2.0 5P",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1315,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10341,
        "nombreModelo": "KUGA AC 1.5 5P 4X2 TM DIESEL",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1320,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12077,
        "nombreModelo": "MONDEO TREND 2.5 4P 4X2 TA",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1436,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7259,
        "nombreModelo": "MUSTANG",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1295,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11298,
        "nombreModelo": "NEW EXPEDITION XLT",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1395,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11789,
        "nombreModelo": "NEW FIESTA PLUS",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1437,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 868,
        "nombreModelo": "Ranger 2.5L CRDi",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1181,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 884,
        "nombreModelo": "Sport Trac 4L",
        "idMarca": 22,
        "idCategoria": 1,
        "codigoSise": 1197,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9d2",
    "idMarca": 197,
    "nombreMarca": "HANTENG",
    "codigoSise": 7361,
    "models": [
      {
        "idModelo": 10855,
        "nombreModelo": "X5 AC 1.5 5P 4X2 TA",
        "idMarca": 197,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10650,
        "nombreModelo": "X7 AC 2.0 5P 4X2 TM",
        "idMarca": 197,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12109,
        "nombreModelo": "X7S AC 2.0T 5P 4X2 TA",
        "idMarca": 197,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9d7",
    "idMarca": 90,
    "nombreMarca": "HOWO",
    "codigoSise": 601,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9eb",
    "idMarca": 32,
    "nombreMarca": "JINBEI",
    "codigoSise": 578,
    "models": [
      {
        "idModelo": 7993,
        "nombreModelo": "DRAGÓN DORADO 2.4L DIE 17P",
        "idMarca": 32,
        "idCategoria": 1,
        "codigoSise": 7,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 1303,
        "nombreModelo": "H1 Haise SY6480J",
        "idMarca": 32,
        "idCategoria": 1,
        "codigoSise": 27,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 1305,
        "nombreModelo": "H2L SY6548J",
        "idMarca": 32,
        "idCategoria": 1,
        "codigoSise": 29,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 4894,
        "nombreModelo": "Haise 2L",
        "idMarca": 32,
        "idCategoria": 6,
        "codigoSise": 10,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 1313,
        "nombreModelo": "Mini Truck",
        "idMarca": 32,
        "idCategoria": 1,
        "codigoSise": 19,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 7994,
        "nombreModelo": "SY6483Q3",
        "idMarca": 32,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 7996,
        "nombreModelo": "SY654BJ1S3BH",
        "idMarca": 32,
        "idCategoria": 1,
        "codigoSise": 20,
        "codTipoVehiculo": 81
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9ec",
    "idMarca": 143,
    "nombreMarca": "JINBEI HAISE",
    "codigoSise": 7344,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9fa",
    "idMarca": 36,
    "nombreMarca": "LAND ROVER",
    "codigoSise": 372,
    "models": [
      {
        "idModelo": 10220,
        "nombreModelo": "DEFENDER 110",
        "idMarca": 36,
        "idCategoria": 1,
        "codigoSise": 175,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5112,
        "nombreModelo": "DISCOVERY",
        "idMarca": 36,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1541,
        "nombreModelo": "Discovery3 HSE",
        "idMarca": 36,
        "idCategoria": 1,
        "codigoSise": 139,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1547,
        "nombreModelo": "Discovery4 2L",
        "idMarca": 36,
        "idCategoria": 1,
        "codigoSise": 140,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 9894,
        "nombreModelo": "FREELANDER",
        "idMarca": 36,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10141,
        "nombreModelo": "RANGE ROVER",
        "idMarca": 36,
        "idCategoria": 1,
        "codigoSise": 174,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9fb",
    "idMarca": 177,
    "nombreMarca": "LANDWIND",
    "codigoSise": 784,
    "models": [
      {
        "idModelo": 10662,
        "nombreModelo": "X2 DE LUXE AC 1.6 5P 4X2 TM",
        "idMarca": 177,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10443,
        "nombreModelo": "X5 PLUS AC 1.5 5P 4X2 TM",
        "idMarca": 177,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa10",
    "idMarca": 206,
    "nombreMarca": "NIMBUS",
    "codigoSise": 631,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa11",
    "idMarca": 44,
    "nombreMarca": "NISSAN",
    "codigoSise": 376,
    "models": [
      {
        "idModelo": 1933,
        "nombreModelo": "1200 1.2L",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 334,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8025,
        "nombreModelo": "X TRAL XTTEME",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1677,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1934,
        "nombreModelo": "2400 2.4L",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2100,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11171,
        "nombreModelo": "44 PATROL WAGON",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2378,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8101,
        "nombreModelo": "ALMERA 1.6",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 466,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10578,
        "nombreModelo": "ALTIMA AC",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1877,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11599,
        "nombreModelo": "ARMADA",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2426,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 8010,
        "nombreModelo": "FRONTIER",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1587,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11843,
        "nombreModelo": "JUKE",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2446,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 5232,
        "nombreModelo": "KICKS",
        "idMarca": 44,
        "idCategoria": 5,
        "codigoSise": 1916,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8000,
        "nombreModelo": "MARCH",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 587,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 10711,
        "nombreModelo": "MAXIMA",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2317,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7998,
        "nombreModelo": "MURANO",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 18,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3756,
        "nombreModelo": "Navara",
        "idMarca": 44,
        "idCategoria": 3,
        "codigoSise": 835,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 12093,
        "nombreModelo": "NEW X-TRAIL EXCLUSIVE",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2471,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8024,
        "nombreModelo": "NISSAN TIIDA",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1676,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1994,
        "nombreModelo": "Note",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1756,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8081,
        "nombreModelo": "NP300 FRONTIER",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2190,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7256,
        "nombreModelo": "PATHFINDER",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2242,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4321,
        "nombreModelo": "Patrol",
        "idMarca": 44,
        "idCategoria": 2,
        "codigoSise": 850,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8154,
        "nombreModelo": "PICK UP",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1578,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8133,
        "nombreModelo": "PRIMERA GXE M/T",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1060,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8034,
        "nombreModelo": "Qashqai",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1688,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5247,
        "nombreModelo": "ROGUE",
        "idMarca": 44,
        "idCategoria": 3,
        "codigoSise": 314,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 2027,
        "nombreModelo": "SE-2.0 Sport",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 784,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8020,
        "nombreModelo": "SENTRA",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1671,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5430,
        "nombreModelo": "SILVERSA NOTE",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2098,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8145,
        "nombreModelo": "TIDDA TIIDA",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1358,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11735,
        "nombreModelo": "TITAN PK",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2436,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 2058,
        "nombreModelo": "Urvan",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 861,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 10345,
        "nombreModelo": "VERSA",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2271,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8048,
        "nombreModelo": "X - TRAIL",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1717,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10527,
        "nombreModelo": "XTERRA",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 2294,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8012,
        "nombreModelo": "X-TRAIL",
        "idMarca": 44,
        "idCategoria": 1,
        "codigoSise": 1656,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa16",
    "idMarca": 242,
    "nombreMarca": "ORION",
    "codigoSise": 822,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa20",
    "idMarca": 47,
    "nombreMarca": "QMC",
    "codigoSise": 520,
    "models": [
      {
        "idModelo": 3777,
        "nombreModelo": "Fine",
        "idMarca": 47,
        "idCategoria": 6,
        "codigoSise": 92,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3779,
        "nombreModelo": "Flying",
        "idMarca": 47,
        "idCategoria": 6,
        "codigoSise": 75,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3784,
        "nombreModelo": "Ventura",
        "idMarca": 47,
        "idCategoria": 5,
        "codigoSise": 82,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f98a",
    "idMarca": 165,
    "nombreMarca": "AM GENERAL",
    "codigoSise": 7350,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f991",
    "idMarca": 63,
    "nombreMarca": "AXXO",
    "codigoSise": 680,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f993",
    "idMarca": 109,
    "nombreMarca": "BAJAJ",
    "codigoSise": 392,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f99a",
    "idMarca": 205,
    "nombreMarca": "BRILLIANCE",
    "codigoSise": 7364,
    "models": [
      {
        "idModelo": 10856,
        "nombreModelo": "V3 AC 1.5 5P 4X2 TM",
        "idMarca": 205,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9a6",
    "idMarca": 9,
    "nombreMarca": "CHERY",
    "codigoSise": 570,
    "models": [
      {
        "idModelo": 236,
        "nombreModelo": "A1 Nice 1.3L 5p",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 255,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 10523,
        "nombreModelo": "A13T TIGGO2 AC 1.5 5P",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 244,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 4142,
        "nombreModelo": "A520 2L 4p",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 8,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 239,
        "nombreModelo": "Arizzo 3 1.6L 4p SOHC 107hp T/M A/A 2AB ABS",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 257,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 5064,
        "nombreModelo": "Cowin 1.5L 4p Acteco SOHC 107hp 2AB ABS EBD F/E",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 143,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 241,
        "nombreModelo": "Fullwin Facelift 1.5L 4p",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 258,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 3107,
        "nombreModelo": "Grand Tiggo 2L SUV",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 172,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6810,
        "nombreModelo": "NEW TIGGO3 1.6L",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 294,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 248,
        "nombreModelo": "Practivan 1.2L",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 263,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 7745,
        "nombreModelo": "Q AC 1.0",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 230,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 5198,
        "nombreModelo": "Q22L AC 1.2 5P",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 137,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 7744,
        "nombreModelo": "Q5 AC 1.0 5P 4X2 TM",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 248,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6160,
        "nombreModelo": "QQ3 0.8",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 293,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 3111,
        "nombreModelo": "QQ308 0.8L",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 24,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 253,
        "nombreModelo": "QQ311 1.1L 5p",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 268,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 5579,
        "nombreModelo": "QQ6",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 3113,
        "nombreModelo": "Tiggo 1.6L SUV",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 146,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 257,
        "nombreModelo": "Tiggo2 1.5L SUV",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 272,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 5383,
        "nombreModelo": "TIGGO3 - T11FL3 AC",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 253,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 263,
        "nombreModelo": "Tiggo5 2L SUV",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 278,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 7022,
        "nombreModelo": "TIGGO-T11FL 1.6",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 296,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7738,
        "nombreModelo": "TIGO 5P",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 201,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3116,
        "nombreModelo": "VanCargo 1.3L",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 22,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 3117,
        "nombreModelo": "VanPass 1.3L D/H P/E A/A ABS EBD",
        "idMarca": 9,
        "idCategoria": 6,
        "codigoSise": 26,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 11409,
        "nombreModelo": "X1",
        "idMarca": 9,
        "idCategoria": 1,
        "codigoSise": 322,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9b7",
    "idMarca": 18,
    "nombreMarca": "DONGFENG",
    "codigoSise": 540,
    "models": [
      {
        "idModelo": 11517,
        "nombreModelo": "AX4 AC 1.6 5P",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 155,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 11565,
        "nombreModelo": "AX7 AC 2.0 5P",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 141,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9041,
        "nombreModelo": "C37 AC 1.4 4P 4X2 TM",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 39,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 9051,
        "nombreModelo": "DFSK 11 PAX DONGFENG V29",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 70,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9044,
        "nombreModelo": "DONGFENG K07II AC 1.3 5P 4X2 TM",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 53,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9032,
        "nombreModelo": "EQ1020TF 1.0",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 15,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11824,
        "nombreModelo": "GLORY 560 AC",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 161,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9052,
        "nombreModelo": "H30 CROSS AC",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 78,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 9050,
        "nombreModelo": "K07II  AC",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 69,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10503,
        "nombreModelo": "RICH ZN1023U5N4 AC",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 139,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 9055,
        "nombreModelo": "V29 AC 1.3 5P 4X2 TM",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 86,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 715,
        "nombreModelo": "ZNA Nissan 2.4L P/U D/C 4x2 T/M A/A 137hp 217Nm",
        "idMarca": 18,
        "idCategoria": 1,
        "codigoSise": 127,
        "codTipoVehiculo": 1
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9c9",
    "idMarca": 125,
    "nombreMarca": "FREIGHTLINER",
    "codigoSise": 385,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9f1",
    "idMarca": 181,
    "nombreMarca": "KAWEI AUTO",
    "codigoSise": 7356,
    "models": [
      {
        "idModelo": 11289,
        "nombreModelo": "K150 AC",
        "idMarca": 181,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9f2",
    "idMarca": 174,
    "nombreMarca": "KEEWAY",
    "codigoSise": 68,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9ff",
    "idMarca": 126,
    "nombreMarca": "LONCIN",
    "codigoSise": 509,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa13",
    "idMarca": 141,
    "nombreMarca": "OLDSMOBILE",
    "codigoSise": 496,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa2e",
    "idMarca": 124,
    "nombreMarca": "SCION",
    "codigoSise": 639,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa36",
    "idMarca": 167,
    "nombreMarca": "SPEED FIRE",
    "codigoSise": 7351,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa3b",
    "idMarca": 157,
    "nombreMarca": "SUKIDA",
    "codigoSise": 455,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa55",
    "idMarca": 186,
    "nombreMarca": "XMOTOS",
    "codigoSise": 7358,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f998",
    "idMarca": 4,
    "nombreMarca": "BMW",
    "codigoSise": 359,
    "models": [
      {
        "idModelo": 3059,
        "nombreModelo": "116i Twin Power Turbo",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 191,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 81,
        "nombreModelo": "118i Twin Power Turbo",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 424,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 6002,
        "nombreModelo": "120I",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 525,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3062,
        "nombreModelo": "125i Coupe 2.5L 2p",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 160,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 89,
        "nombreModelo": "135i Twin Turbo Coupe",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 382,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 91,
        "nombreModelo": "218i Active Tourer Twin Power",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 327,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 92,
        "nombreModelo": "235i Coupe 3L 2p Turbo",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 287,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 93,
        "nombreModelo": "240i Coupe Twin Power Turbo",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 432,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 94,
        "nombreModelo": "316i 1.6L 4p T/M A/A",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 354,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10591,
        "nombreModelo": "318I",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 575,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4136,
        "nombreModelo": "320i 2L 4p",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 328,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12041,
        "nombreModelo": "323",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 628,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7187,
        "nombreModelo": "325",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 551,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7390,
        "nombreModelo": "328I 1600",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 364,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7396,
        "nombreModelo": "330I 258HP 3L",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 16,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7392,
        "nombreModelo": "335I",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 420,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 125,
        "nombreModelo": "420i Coupe Twin Power Turbo",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 452,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 3077,
        "nombreModelo": "428i Coupe 2L 2p",
        "idMarca": 4,
        "idCategoria": 3,
        "codigoSise": 288,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 128,
        "nombreModelo": "430i Coupe Twin Power Turbo",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 455,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 7385,
        "nombreModelo": "520I AC 2.0 4P 4X2 TA",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 353,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 131,
        "nombreModelo": "523i 2.3L 4p",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 344,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3079,
        "nombreModelo": "525i 2.5L 4p",
        "idMarca": 4,
        "idCategoria": 2,
        "codigoSise": 217,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7384,
        "nombreModelo": "528I",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 64,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 137,
        "nombreModelo": "530i 3L 4p",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 331,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 141,
        "nombreModelo": "535i 3L 4p",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 464,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11328,
        "nombreModelo": "550I",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 607,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 144,
        "nombreModelo": "630i Cabriolet 3L",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 467,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 10825,
        "nombreModelo": "640I GRAN COUPE",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 585,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 146,
        "nombreModelo": "650i Cabriolet",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 469,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10225,
        "nombreModelo": "740I AC 3.0 4P 4X2 TA",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 556,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 149,
        "nombreModelo": "745i 5L 4p",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 345,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11978,
        "nombreModelo": "ACTIVE 3 AC",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 624,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11305,
        "nombreModelo": "M2 COMPETITION AC",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 606,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12085,
        "nombreModelo": "M3 4.0 3P",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 630,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 159,
        "nombreModelo": "M4 Twin Turbo 4L",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 481,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 160,
        "nombreModelo": "M5 4p T/A A/A",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 482,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 161,
        "nombreModelo": "M6 Cabriolet T/A A/A",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 483,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 7155,
        "nombreModelo": "SUV X3 3.0",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 550,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11691,
        "nombreModelo": "X1 SDRIVE 18I",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 615,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6787,
        "nombreModelo": "X2 SDRIVE 20I TWIN TURBO",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 540,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10794,
        "nombreModelo": "X3 2.5",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 583,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11480,
        "nombreModelo": "X4 AC",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 609,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10317,
        "nombreModelo": "X5 3.0SI",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 559,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10250,
        "nombreModelo": "X6 AC 3.0",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 557,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11055,
        "nombreModelo": "XG31-528I AC",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 601,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10966,
        "nombreModelo": "Z3 M",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 596,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5700,
        "nombreModelo": "Z4",
        "idMarca": 4,
        "idCategoria": 1,
        "codigoSise": 513,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9da",
    "idMarca": 208,
    "nombreMarca": "HUSQVARNA",
    "codigoSise": 419,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9e1",
    "idMarca": 155,
    "nombreMarca": "ISUZU",
    "codigoSise": 438,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9e2",
    "idMarca": 114,
    "nombreMarca": "IVECO",
    "codigoSise": 112,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9e5",
    "idMarca": 204,
    "nombreMarca": "JAGUAR",
    "codigoSise": 435,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa0b",
    "idMarca": 238,
    "nombreMarca": "MITSUBISHI FUSO",
    "codigoSise": 52,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa27",
    "idMarca": 140,
    "nombreMarca": "SAAB",
    "codigoSise": 723,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa35",
    "idMarca": 52,
    "nombreMarca": "SOUEAST",
    "codigoSise": 763,
    "models": [
      {
        "idModelo": 2473,
        "nombreModelo": "DX3",
        "idMarca": 52,
        "idCategoria": 1,
        "codigoSise": 6,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 2475,
        "nombreModelo": "DX7",
        "idMarca": 52,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f999",
    "idMarca": 201,
    "nombreMarca": "BONLUCK",
    "codigoSise": 746,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9a9",
    "idMarca": 12,
    "nombreMarca": "CITROEN",
    "codigoSise": 362,
    "models": [
      {
        "idModelo": 10198,
        "nombreModelo": "BERLINGO B9 AC",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 323,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3163,
        "nombreModelo": "C elysee 1.6L 4p",
        "idMarca": 12,
        "idCategoria": 4,
        "codigoSise": 154,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4167,
        "nombreModelo": "C2 X Pack",
        "idMarca": 12,
        "idCategoria": 4,
        "codigoSise": 73,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 7344,
        "nombreModelo": "C3 AC 1.2",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 322,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11353,
        "nombreModelo": "C4 1.6I SX NIVEL",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 350,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11908,
        "nombreModelo": "C5 AIRCROSS AC",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 370,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 616,
        "nombreModelo": "C6 N3 BVA",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 220,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7183,
        "nombreModelo": "C-ELYSEE AC",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 319,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8489,
        "nombreModelo": "DS3 AC 1.6 3P 4X2 TM",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 118,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11716,
        "nombreModelo": "DS4 N2 AC 1.6 5P 4X2 TA",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 365,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 10952,
        "nombreModelo": "JUMPER FT35L3H2",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 347,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 6820,
        "nombreModelo": "JUMPY 2.0T",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 313,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 617,
        "nombreModelo": "New C4 Exclusive",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 123,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 623,
        "nombreModelo": "Picasso 1.6L",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 292,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11710,
        "nombreModelo": "SAXO SX",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 364,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 10488,
        "nombreModelo": "SPACETOURER AC",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 330,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 626,
        "nombreModelo": "Xsara Picasso",
        "idMarca": 12,
        "idCategoria": 1,
        "codigoSise": 62,
        "codTipoVehiculo": 76
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9d3",
    "idMarca": 123,
    "nombreMarca": "HARLEY DAVIDSON",
    "codigoSise": 410,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9dd",
    "idMarca": 29,
    "nombreMarca": "HYUNDAI",
    "codigoSise": 369,
    "models": [
      {
        "idModelo": 5411,
        "nombreModelo": "ACCENT 1.4 4P",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2672,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5402,
        "nombreModelo": "SONATA AC 2.0 4P",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2668,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5572,
        "nombreModelo": " TUCSON TL AC 2.0 5P 4X2 TM",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3118,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8522,
        "nombreModelo": "110 AC TM 1.2 5P 4X2",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1334,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 11148,
        "nombreModelo": "ATOS 5DR STD",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3183,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11742,
        "nombreModelo": "AZERA 3.0 4P 4X2 TA",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3278,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8519,
        "nombreModelo": "COUNTY T/M 3.9 2P 4X2 LWB TOUR",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 949,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5683,
        "nombreModelo": "CRETA 1.6 TM AC 4X2 5P",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2903,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11762,
        "nombreModelo": "ELANTRA 1.6",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3281,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1054,
        "nombreModelo": "Equus GLS 3.8L 4p Shiftronic V-6 rin 18 iPod",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2110,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1055,
        "nombreModelo": "Galloper 3L",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2712,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1056,
        "nombreModelo": "Genesis 3.8L 4p",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1043,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8693,
        "nombreModelo": "GETZ  5P 1.4 AC",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1345,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 8812,
        "nombreModelo": "GRAND I10 1.2 4P 4X2 TM",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2632,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6048,
        "nombreModelo": "H1 STAREX",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2949,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 1099,
        "nombreModelo": "H-100 2.4L van",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2269,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 8746,
        "nombreModelo": "HD270 CC I TM 12.92 2P 4X2",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1835,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 8517,
        "nombreModelo": "HD-65",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 940,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8690,
        "nombreModelo": "HD72 LWB I TM",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1313,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 8706,
        "nombreModelo": "HD75 SWB WC 4.0T I TM 3.9 2P",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1537,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 8789,
        "nombreModelo": "HD78 LWB I TM 3.9 2P 4X2 TM DIESEL",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2427,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 3395,
        "nombreModelo": "i-10 1.1L 5p 12val Tapacubos",
        "idMarca": 29,
        "idCategoria": 5,
        "codigoSise": 313,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 3403,
        "nombreModelo": "i-30 1.6L 5p A/A T/M neblineros",
        "idMarca": 29,
        "idCategoria": 4,
        "codigoSise": 1057,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 11966,
        "nombreModelo": "IONIQ 88 KW AC 5P 4X2 TA EV",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3299,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8567,
        "nombreModelo": "MATRIX 1.8L",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2526,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 4074,
        "nombreModelo": "New Accent 1.4L 4p A/A T/M 2AB",
        "idMarca": 29,
        "idCategoria": 5,
        "codigoSise": 2060,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3411,
        "nombreModelo": "New Elantra 1.6L 4p A/A T/M",
        "idMarca": 29,
        "idCategoria": 4,
        "codigoSise": 1063,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1138,
        "nombreModelo": "New i-30 1.8L 5p",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1874,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 1141,
        "nombreModelo": "New i-40 2L 5p",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2280,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 3412,
        "nombreModelo": "New Santa Fé 2.4L",
        "idMarca": 29,
        "idCategoria": 3,
        "codigoSise": 1292,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1151,
        "nombreModelo": "New Sonata 2.4L 4p",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2784,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11360,
        "nombreModelo": "NEW TUCSON AC",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3219,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8702,
        "nombreModelo": "PICANTO R 1.0L AC",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 1486,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 12011,
        "nombreModelo": "SANTA FE 2.2",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3305,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 10617,
        "nombreModelo": "TERRACAN 2.5",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3100,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8796,
        "nombreModelo": "TQ 12 P 2.5",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2461,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 8551,
        "nombreModelo": "TUCSON IX",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 2203,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11285,
        "nombreModelo": "VELOSTER 1.6",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3211,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 11990,
        "nombreModelo": "VERACRUZ 3.0",
        "idMarca": 29,
        "idCategoria": 1,
        "codigoSise": 3302,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9df",
    "idMarca": 138,
    "nombreMarca": "IGM",
    "codigoSise": 753,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9ea",
    "idMarca": 237,
    "nombreMarca": "JIEDA",
    "codigoSise": 524,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9ed",
    "idMarca": 33,
    "nombreMarca": "JMC",
    "codigoSise": 584,
    "models": [
      {
        "idModelo": 1314,
        "nombreModelo": "JX1021 2.8L",
        "idMarca": 33,
        "idCategoria": 1,
        "codigoSise": 12,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10384,
        "nombreModelo": "S350 AC",
        "idMarca": 33,
        "idCategoria": 1,
        "codigoSise": 45,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10514,
        "nombreModelo": "VIGUS AC",
        "idMarca": 33,
        "idCategoria": 1,
        "codigoSise": 41,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa06",
    "idMarca": 41,
    "nombreMarca": "MERCEDES BENZ",
    "codigoSise": 374,
    "models": [
      {
        "idModelo": 4772,
        "nombreModelo": "190 E 2L 4p T/M",
        "idMarca": 41,
        "idCategoria": 6,
        "codigoSise": 250,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6128,
        "nombreModelo": "230 E",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 672,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4103,
        "nombreModelo": "230 E 2.3L",
        "idMarca": 41,
        "idCategoria": 6,
        "codigoSise": 251,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10119,
        "nombreModelo": "250 S",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 578,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1739,
        "nombreModelo": "280 SE 2.8L 4p T/M A/A V6",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 521,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7026,
        "nombreModelo": "300 E",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 688,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1740,
        "nombreModelo": "A 160 1.8L 5p T/M",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 255,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3669,
        "nombreModelo": "A 180 1.6L 5p T/M 122CV Turbo",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 410,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3670,
        "nombreModelo": "A 200 2L 5p T/M 136CV",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 247,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1743,
        "nombreModelo": "A 45 AMG",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 411,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11278,
        "nombreModelo": "ACTROS 3346 S AC",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 767,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 10353,
        "nombreModelo": "AMG GT COUPE AC 4.0 2P 4X2 TA",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 718,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 10096,
        "nombreModelo": "ATEGO 1728 S",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 217,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 1744,
        "nombreModelo": "B 200 2L 5p T/M A/A",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 585,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11912,
        "nombreModelo": "C 180 1.8 4P 4X2 TM EMB",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 796,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1749,
        "nombreModelo": "C 200 2L 4p",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 590,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 7184,
        "nombreModelo": "C 220",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 696,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1755,
        "nombreModelo": "C 230 2.6L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 264,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4778,
        "nombreModelo": "C 240 2.4L 4p V6",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 267,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1760,
        "nombreModelo": "C 250 2L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 595,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 11585,
        "nombreModelo": "C 280",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 774,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10086,
        "nombreModelo": "C 300",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 575,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1768,
        "nombreModelo": "C 320 3.2L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 465,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1770,
        "nombreModelo": "C 350 3.5L 4p",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 467,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1772,
        "nombreModelo": "C 43 AMG",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 601,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 1773,
        "nombreModelo": "C 63 AMG",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 469,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 4278,
        "nombreModelo": "C AMG 63",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 346,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3678,
        "nombreModelo": "C180 CGI Turbo",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 347,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1775,
        "nombreModelo": "C200 AMG Line 2L 4p 7GTronic A/A 184CV Turbo T/C cuero",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 603,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10555,
        "nombreModelo": "C250 AC 1.8 4P 4X2 TA",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 726,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11249,
        "nombreModelo": "C43 AMG SPORT AC 3.0 4P 4X4 TA",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 766,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5482,
        "nombreModelo": "C-CLASS AC 2.0 4P 4X2 TA",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 645,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3679,
        "nombreModelo": "CLA 180 1.6L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 414,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1779,
        "nombreModelo": "CLK 200 2L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 370,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 1784,
        "nombreModelo": "CLS 350 3.5L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 470,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10099,
        "nombreModelo": "E 190",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 240,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5154,
        "nombreModelo": "E 200 BERLINA AC 2.0 4P 4X2 TA",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 457,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10692,
        "nombreModelo": "E 230",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 737,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4776,
        "nombreModelo": "E 240 2.4L 4p",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 33,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3682,
        "nombreModelo": "E 250 2L 4p",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 279,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10564,
        "nombreModelo": "E 280",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 727,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1799,
        "nombreModelo": "E 300 3.5L 4p",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 614,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5510,
        "nombreModelo": "E 320",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 647,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1803,
        "nombreModelo": "E 350 3.5L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 616,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 1806,
        "nombreModelo": "E 400 Coupe 3L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 477,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1808,
        "nombreModelo": "E 63 AMG 5.5L 4p",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 479,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10450,
        "nombreModelo": "GL 400 AC 3.0",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 722,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6892,
        "nombreModelo": "GL 450 3.5L SUV",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 687,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11011,
        "nombreModelo": "GLA 180 AC",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 758,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1810,
        "nombreModelo": "GLA 250 4MATIC 2L",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 619,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 1812,
        "nombreModelo": "GLA 45 AMG 2L SUV",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 621,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 12102,
        "nombreModelo": "GLC 200 BASICO",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 812,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1813,
        "nombreModelo": "GLC 250 4MATIC",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 622,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10118,
        "nombreModelo": "GLC 260 4MATIC",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 577,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12048,
        "nombreModelo": "GLE",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 806,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1819,
        "nombreModelo": "GLK",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 628,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1824,
        "nombreModelo": "GLS",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 633,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 10109,
        "nombreModelo": "MERCEDES BENZ",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 459,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4281,
        "nombreModelo": "ML",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 286,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5855,
        "nombreModelo": "PKW",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 666,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 1839,
        "nombreModelo": "R 350",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 490,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10085,
        "nombreModelo": "S 400",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 574,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10125,
        "nombreModelo": "SLC",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 675,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1844,
        "nombreModelo": "SLK",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 492,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 7201,
        "nombreModelo": "SPRINTER",
        "idMarca": 41,
        "idCategoria": 1,
        "codigoSise": 697,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa19",
    "idMarca": 137,
    "nombreMarca": "PEGASO",
    "codigoSise": 687,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa1c",
    "idMarca": 134,
    "nombreMarca": "PIAGGIO",
    "codigoSise": 425,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa1f",
    "idMarca": 184,
    "nombreMarca": "QINGQI",
    "codigoSise": 468,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa22",
    "idMarca": 121,
    "nombreMarca": "RAMBLER",
    "codigoSise": 7342,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa24",
    "idMarca": 225,
    "nombreMarca": "RANGER SH",
    "codigoSise": 7373,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa29",
    "idMarca": 111,
    "nombreMarca": "SAEHAN",
    "codigoSise": 68,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa2c",
    "idMarca": 70,
    "nombreMarca": "SANYA",
    "codigoSise": 7333,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa3d",
    "idMarca": 55,
    "nombreMarca": "TATA",
    "codigoSise": 497,
    "models": [
      {
        "idModelo": 2519,
        "nombreModelo": "Xenon 2.2L VVT",
        "idMarca": 55,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa3f",
    "idMarca": 207,
    "nombreMarca": "TAYLOR DUNN",
    "codigoSise": 7365,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9a0",
    "idMarca": 233,
    "nombreMarca": "CFMOTO",
    "codigoSise": 7377,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9a5",
    "idMarca": 159,
    "nombreMarca": "CHENGLONG",
    "codigoSise": 7346,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9c5",
    "idMarca": 211,
    "nombreMarca": "FODAY",
    "codigoSise": 7368,
    "models": [
      {
        "idModelo": 11040,
        "nombreModelo": "NHQ1030F6-H AC 2.4 CD 4X2",
        "idMarca": 211,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11753,
        "nombreModelo": "NHQ1030N4Y-H AC 1.9 CD 4X4 TM",
        "idMarca": 211,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11446,
        "nombreModelo": "NHQ6480K5Y-H AC 2.0 5P 4X4 TM",
        "idMarca": 211,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9d8",
    "idMarca": 148,
    "nombreMarca": "HUANGHAI",
    "codigoSise": 781,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9fd",
    "idMarca": 37,
    "nombreMarca": "LEXUS",
    "codigoSise": 439,
    "models": [
      {
        "idModelo": 5143,
        "nombreModelo": "CT 200",
        "idMarca": 37,
        "idCategoria": 5,
        "codigoSise": 41,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10805,
        "nombreModelo": "DD HS250 HYBRID",
        "idMarca": 37,
        "idCategoria": 1,
        "codigoSise": 84,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1577,
        "nombreModelo": "GS 450 H Limited",
        "idMarca": 37,
        "idCategoria": 1,
        "codigoSise": 71,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10751,
        "nombreModelo": "HS 250 HYBRID",
        "idMarca": 37,
        "idCategoria": 1,
        "codigoSise": 83,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12155,
        "nombreModelo": "LEXUS RX 450",
        "idMarca": 37,
        "idCategoria": 1,
        "codigoSise": 97,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7850,
        "nombreModelo": "RX 300",
        "idMarca": 37,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11315,
        "nombreModelo": "UCF30L-AEAGKA",
        "idMarca": 37,
        "idCategoria": 1,
        "codigoSise": 89,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa02",
    "idMarca": 39,
    "nombreMarca": "MAHINDRA",
    "codigoSise": 673,
    "models": [
      {
        "idModelo": 11182,
        "nombreModelo": "KUV100 NXT LHD K6",
        "idMarca": 39,
        "idCategoria": 1,
        "codigoSise": 61,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 7275,
        "nombreModelo": "PICK UP CRDI",
        "idMarca": 39,
        "idCategoria": 1,
        "codigoSise": 56,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1604,
        "nombreModelo": "Scorpio 2.2L",
        "idMarca": 39,
        "idCategoria": 1,
        "codigoSise": 46,
        "codTipoVehiculo": 1
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa05",
    "idMarca": 40,
    "nombreMarca": "MAZDA",
    "codigoSise": 373,
    "models": [
      {
        "idModelo": 11951,
        "nombreModelo": " CX-9 TOP GRADE AC",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 2099,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1606,
        "nombreModelo": "2 Hatchback",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 780,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7914,
        "nombreModelo": "3 SDN AC 2.0",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 1604,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11082,
        "nombreModelo": "323",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 2035,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3603,
        "nombreModelo": "5 Van 2L van",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 1652,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 7906,
        "nombreModelo": "6 2.0L SEDAN T/M",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 1572,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1647,
        "nombreModelo": "626 2L 4p",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 1074,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1651,
        "nombreModelo": "Allegro 323",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 1824,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12097,
        "nombreModelo": "B2200 CABINA DOBLE",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 1910,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 3615,
        "nombreModelo": "B2200i  2.2L",
        "idMarca": 40,
        "idCategoria": 4,
        "codigoSise": 1312,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 3623,
        "nombreModelo": "B2600i 2.6L P/U C/S 4x4",
        "idMarca": 40,
        "idCategoria": 4,
        "codigoSise": 850,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7901,
        "nombreModelo": "BT -50 2,5 TD CM",
        "idMarca": 40,
        "idCategoria": 1,
        "codigoSise": 1559,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa12",
    "idMarca": 119,
    "nombreMarca": "NISSAN DIESEL",
    "codigoSise": 609,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa26",
    "idMarca": 154,
    "nombreMarca": "ROYAL ENFIELD",
    "codigoSise": 722,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa28",
    "idMarca": 187,
    "nombreMarca": "SACHS",
    "codigoSise": 7359,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa2d",
    "idMarca": 198,
    "nombreMarca": "SCANIA",
    "codigoSise": 391,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa45",
    "idMarca": 82,
    "nombreMarca": "TRAXX",
    "codigoSise": 525,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa48",
    "idMarca": 216,
    "nombreMarca": "TVS",
    "codigoSise": 208,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa49",
    "idMarca": 57,
    "nombreMarca": "UAZ",
    "codigoSise": 156,
    "models": [
      {
        "idModelo": 2763,
        "nombreModelo": "Patriot 2.7L",
        "idMarca": 57,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 2764,
        "nombreModelo": "Pickup 2.7L",
        "idMarca": 57,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11726,
        "nombreModelo": "UAZ PATRIOT",
        "idMarca": 57,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa4a",
    "idMarca": 176,
    "nombreMarca": "UD TRUCKS",
    "codigoSise": 675,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa50",
    "idMarca": 59,
    "nombreMarca": "VOLVO",
    "codigoSise": 384,
    "models": [
      {
        "idModelo": 7972,
        "nombreModelo": "850 GLT",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 65,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4564,
        "nombreModelo": "960 Turbo",
        "idMarca": 59,
        "idCategoria": 6,
        "codigoSise": 98,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10979,
        "nombreModelo": "C30",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 330,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2981,
        "nombreModelo": "C70",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 100,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 11110,
        "nombreModelo": "S40 2.4I",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 334,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2992,
        "nombreModelo": "S60",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 224,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3007,
        "nombreModelo": "S70",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 169,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3009,
        "nombreModelo": "S80",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 237,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3011,
        "nombreModelo": "V40",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 277,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 3021,
        "nombreModelo": "V70",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 244,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4495,
        "nombreModelo": "XC",
        "idMarca": 59,
        "idCategoria": 2,
        "codigoSise": 161,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7260,
        "nombreModelo": "XC40",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 312,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3026,
        "nombreModelo": "XC60",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 248,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3029,
        "nombreModelo": "XC70",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 251,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10172,
        "nombreModelo": "XC90",
        "idMarca": 59,
        "idCategoria": 1,
        "codigoSise": 314,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f990",
    "idMarca": 131,
    "nombreMarca": "AUSTIN",
    "codigoSise": 451,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f994",
    "idMarca": 3,
    "nombreMarca": "BAW",
    "codigoSise": 613,
    "models": [
      {
        "idModelo": 76,
        "nombreModelo": "Furgoneta 2.7L",
        "idMarca": 3,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 77,
        "nombreModelo": "PJ 2.2L P/U",
        "idMarca": 3,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 79,
        "nombreModelo": "Socool 1.4L",
        "idMarca": 3,
        "idCategoria": 1,
        "codigoSise": 9,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f99e",
    "idMarca": 94,
    "nombreMarca": "CADILLAC",
    "codigoSise": 646,
    "models": [
      {
        "idModelo": 11905,
        "nombreModelo": "ESCALADE 4WD HYBRID",
        "idMarca": 94,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9ab",
    "idMarca": 13,
    "nombreMarca": "D.F.P.V. (DONGFENG)",
    "codigoSise": 7327,
    "models": [
      {
        "idModelo": 633,
        "nombreModelo": "H30 1.6LSUV",
        "idMarca": 13,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 634,
        "nombreModelo": "S30 1.6L 4p",
        "idMarca": 13,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 1
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9b5",
    "idMarca": 17,
    "nombreMarca": "DODGE",
    "codigoSise": 365,
    "models": [
      {
        "idModelo": 689,
        "nombreModelo": "Caravan 3.3L van T/A 3F",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 35,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 10763,
        "nombreModelo": "DAKOTA",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 136,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7320,
        "nombreModelo": "DURANGO 4X4",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 127,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 692,
        "nombreModelo": "Grand Caravan 3.5L van T/A 3F",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 38,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 7083,
        "nombreModelo": "JOURNEY 2.4 5P 4X2 TA",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 122,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 699,
        "nombreModelo": "Neon 2L 4p T/A",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 40,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 701,
        "nombreModelo": "Nitro 2.8L DIE SUV 4x2 T/M A/A",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 94,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10674,
        "nombreModelo": "RAM 150 QUAD CAB 4X4",
        "idMarca": 17,
        "idCategoria": 1,
        "codigoSise": 134,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9bb",
    "idMarca": 78,
    "nombreMarca": "EBRO",
    "codigoSise": 101,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa14",
    "idMarca": 231,
    "nombreMarca": "OMOTO",
    "codigoSise": 7376,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa2b",
    "idMarca": 50,
    "nombreMarca": "SAIC WULING",
    "codigoSise": 554,
    "models": [
      {
        "idModelo": 10068,
        "nombreModelo": "5V BASICA SUPER VAN",
        "idMarca": 50,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 10069,
        "nombreModelo": "CAMIONETA SUPERVAN PASAJEROS BASICAS VERSION CHINA",
        "idMarca": 50,
        "idCategoria": 1,
        "codigoSise": 13,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 4108,
        "nombreModelo": "Mini Pick up 1L P/U C/S",
        "idMarca": 50,
        "idCategoria": 6,
        "codigoSise": 23,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10078,
        "nombreModelo": "MINI VAN CARGA",
        "idMarca": 50,
        "idCategoria": 1,
        "codigoSise": 43,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11119,
        "nombreModelo": "SUPER VAN",
        "idMarca": 50,
        "idCategoria": 1,
        "codigoSise": 45,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10076,
        "nombreModelo": "VAN",
        "idMarca": 50,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 83
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f995",
    "idMarca": 153,
    "nombreMarca": "BEIBEN",
    "codigoSise": 620,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9ac",
    "idMarca": 14,
    "nombreMarca": "D.F.S.K. (DONGFENG)",
    "codigoSise": 7328,
    "models": [
      {
        "idModelo": 635,
        "nombreModelo": "Cityvan C37B11",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 638,
        "nombreModelo": "EQ1020TF 1.1L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 640,
        "nombreModelo": "EQ1021NF 1.1L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 642,
        "nombreModelo": "EQ5021XXYF 1.1L van carga A/A",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 7,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 645,
        "nombreModelo": "EQ6360 1.1L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 10,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 647,
        "nombreModelo": "EQ6380LF 1.1L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 12,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 649,
        "nombreModelo": "EQ6400LF 1.3L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 14,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 652,
        "nombreModelo": "Funvan O17B11 1.3L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 17,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 653,
        "nombreModelo": "K01 Minitruck 1.1L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 31,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 654,
        "nombreModelo": "K02 Minitruck 1.1L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 32,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 655,
        "nombreModelo": "V-Force V29B11 1.3L",
        "idMarca": 14,
        "idCategoria": 1,
        "codigoSise": 18,
        "codTipoVehiculo": 83
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9b0",
    "idMarca": 16,
    "nombreMarca": "DAIHATSU",
    "codigoSise": 364,
    "models": [
      {
        "idModelo": 3170,
        "nombreModelo": "Aplausse 1.6L 5p carburador",
        "idMarca": 16,
        "idCategoria": 4,
        "codigoSise": 54,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9027,
        "nombreModelo": "CINASCAR XS 6400 Y/O NOMADA",
        "idMarca": 16,
        "idCategoria": 1,
        "codigoSise": 66,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 675,
        "nombreModelo": "Copen 1.3L 2p Cabriolet T/M A/A",
        "idMarca": 16,
        "idCategoria": 1,
        "codigoSise": 99,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 3171,
        "nombreModelo": "Cuore 1L 5p A/A 55hp 12val",
        "idMarca": 16,
        "idCategoria": 4,
        "codigoSise": 56,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 3173,
        "nombreModelo": "Grand Move van 5px",
        "idMarca": 16,
        "idCategoria": 4,
        "codigoSise": 59,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 3174,
        "nombreModelo": "Sirion 1.3L",
        "idMarca": 16,
        "idCategoria": 4,
        "codigoSise": 40,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 4173,
        "nombreModelo": "Terios 1.3L",
        "idMarca": 16,
        "idCategoria": 4,
        "codigoSise": 41,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9be",
    "idMarca": 20,
    "nombreMarca": "F.A.W.",
    "codigoSise": 7329,
    "models": [
      {
        "idModelo": 723,
        "nombreModelo": "Actis V80 1.5L",
        "idMarca": 20,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 726,
        "nombreModelo": "D60 Confort 1.5L",
        "idMarca": 20,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 729,
        "nombreModelo": "R7 1.6L SUV",
        "idMarca": 20,
        "idCategoria": 1,
        "codigoSise": 11,
        "codTipoVehiculo": 1
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9c3",
    "idMarca": 21,
    "nombreMarca": "FIAT",
    "codigoSise": 366,
    "models": [
      {
        "idModelo": 7598,
        "nombreModelo": "150",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 50,
        "codTipoVehiculo": 44
      },
      {
        "idModelo": 7596,
        "nombreModelo": "500 1.2",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 387,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6202,
        "nombreModelo": "500X 140CV TREKKING",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 393,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 11320,
        "nombreModelo": "FIAT 500 ABARTH TURBO",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 417,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 745,
        "nombreModelo": "Fiorino Fire furgón",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 341,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 749,
        "nombreModelo": "Fullback GLS 2.5L DID",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 345,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 752,
        "nombreModelo": "G.Siena Attractive",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 247,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4515,
        "nombreModelo": "Idea Adventure 1.8L 5p",
        "idMarca": 21,
        "idCategoria": 5,
        "codigoSise": 96,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 11297,
        "nombreModelo": "LINEA 8V ACTIVE",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 415,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7588,
        "nombreModelo": "NOVA STRADA WORKING",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 243,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11896,
        "nombreModelo": "NUEVO PALIO ESSENCE",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 431,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 757,
        "nombreModelo": "Nuevo Fiorino furgón 1.4L",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 348,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 762,
        "nombreModelo": "Nuevo Uno Vivace 1.4L",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 251,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 7582,
        "nombreModelo": "PALIO 2V EX 5P 1.3 FIRE 16V",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 196,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 11756,
        "nombreModelo": "PREMIO CS",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 429,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7203,
        "nombreModelo": "SIENA ATTRACTIVE",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 395,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10232,
        "nombreModelo": "STILO 1.8 FL",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 399,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7578,
        "nombreModelo": "STRADA  WORKING 1.4",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 151,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7595,
        "nombreModelo": "UNO FIRE 1.3",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 327,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 3220,
        "nombreModelo": "Uno Mille 1.3L 3p",
        "idMarca": 21,
        "idCategoria": 3,
        "codigoSise": 146,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 7315,
        "nombreModelo": "UNO WAY 1.4 5P 4X2 TM",
        "idMarca": 21,
        "idCategoria": 1,
        "codigoSise": 397,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9c8",
    "idMarca": 23,
    "nombreMarca": "FOTON",
    "codigoSise": 529,
    "models": [
      {
        "idModelo": 10511,
        "nombreModelo": "GRATOUR BJ6425",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 77,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 12073,
        "nombreModelo": "MINITRUCK BJ1030",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 96,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 886,
        "nombreModelo": "Minivan Gratour",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 51,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 10916,
        "nombreModelo": "TUNLAND C",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 81,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7754,
        "nombreModelo": "VIEW C BJ6536",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 27,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11224,
        "nombreModelo": "VIEW C2 K1",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 87,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 12042,
        "nombreModelo": "VIEW CARGO CS2 BJ5039 AC 2.8 4P 4X2 TM DIESEL",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 95,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10217,
        "nombreModelo": "VIEW CS2 K1 WIDE BJ6549B1PDA-AA AC 2.8 4P 4X2 TM DIESEL",
        "idMarca": 23,
        "idCategoria": 1,
        "codigoSise": 62,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9d6",
    "idMarca": 28,
    "nombreMarca": "HONDA",
    "codigoSise": 368,
    "models": [
      {
        "idModelo": 11758,
        "nombreModelo": "ACCORD 4P",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 715,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7823,
        "nombreModelo": "CARGO 150",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 449,
        "codTipoVehiculo": 44
      },
      {
        "idModelo": 7069,
        "nombreModelo": "CIVIC 3P D/H T/M T/C",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 612,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10254,
        "nombreModelo": "CR-V",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 635,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7788,
        "nombreModelo": "CR-Z",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 118,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10821,
        "nombreModelo": "FIT AC 1.5 5P 4X2 TM",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 669,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7781,
        "nombreModelo": "HONDA - RU573GL KK HRV",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 416,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7782,
        "nombreModelo": "HRV EXL 1.8L SUV 4X4 T/A A/A I VTEC 141HP T/C CUE",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 482,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1015,
        "nombreModelo": "Integra 1.8L 4p PGMFI T/M A/A",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 563,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7783,
        "nombreModelo": "ODYSSEY 3.5L V 6",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 483,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 4933,
        "nombreModelo": "Pilot 3.5L SUV",
        "idMarca": 28,
        "idCategoria": 2,
        "codigoSise": 413,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10987,
        "nombreModelo": "RE383BLX - KK LK",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 677,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1022,
        "nombreModelo": "Ridge Line 3.5L P/U D/C 4x4 V-6 DOHC 24v",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 212,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5852,
        "nombreModelo": "RL586FJX KK  ODYSSEY AC 3.5 5P 4X2 TA",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 589,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7836,
        "nombreModelo": "RM383FL KK CR-V AC 2.4 5P 4X2 TA",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 515,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10727,
        "nombreModelo": "RU573GL KK HR-V LX AC 1.8 5P 4X2 TM",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 659,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11976,
        "nombreModelo": "WRV AC 1.5 5P 4X2 TA",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 721,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6168,
        "nombreModelo": "YF382FE KK PILOT AC 3.5 5P 4X2 TA",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 609,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7837,
        "nombreModelo": "YF581GE KK PILOT LX 6 AC 3.5 5P 4X2 TA",
        "idMarca": 28,
        "idCategoria": 1,
        "codigoSise": 517,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9e3",
    "idMarca": 74,
    "nombreMarca": "JAC",
    "codigoSise": 530,
    "models": [
      {
        "idModelo": 11815,
        "nombreModelo": "HFC 1035KD AC 2.8 2P 4X2",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 204,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 12015,
        "nombreModelo": "HFC1037",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 209,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10745,
        "nombreModelo": "HFC1037DEV INTER AC 2.0 4P 4X2 TM",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 155,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11394,
        "nombreModelo": "HFC1037DKF AC",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 134,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11358,
        "nombreModelo": "HFC1037DKST AC",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 145,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11117,
        "nombreModelo": "HFC6491KMD AC",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 187,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10557,
        "nombreModelo": "J4 AC 1.5",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 174,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10689,
        "nombreModelo": "KFC1037DEV LUXURY AC",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 178,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10350,
        "nombreModelo": "S2 AC",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 170,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10577,
        "nombreModelo": "S3 AC 1.6 5P 4X2 TM",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 150,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5962,
        "nombreModelo": "S5 AC 2.0 5P 4X2 TM",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 158,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11125,
        "nombreModelo": "S7 AC 2.0 5P 4X2 TA",
        "idMarca": 74,
        "idCategoria": 1,
        "codigoSise": 189,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9f3",
    "idMarca": 127,
    "nombreMarca": "KENWORTH",
    "codigoSise": 388,
    "models": [
      {
        "idModelo": 7192,
        "nombreModelo": "T800 6X4 TRACTOCAMION",
        "idMarca": 127,
        "idCategoria": 1,
        "codigoSise": 87,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa09",
    "idMarca": 42,
    "nombreMarca": "MINI",
    "codigoSise": 626,
    "models": [
      {
        "idModelo": 10652,
        "nombreModelo": "COOPER",
        "idMarca": 42,
        "idCategoria": 1,
        "codigoSise": 23,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11806,
        "nombreModelo": "JOHN COOPER WORKS",
        "idMarca": 42,
        "idCategoria": 1,
        "codigoSise": 31,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10171,
        "nombreModelo": "MINI COOPER COUNTRYMAN SPORT ACTIVITY",
        "idMarca": 42,
        "idCategoria": 1,
        "codigoSise": 21,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10747,
        "nombreModelo": "ONE CLUBMAN",
        "idMarca": 42,
        "idCategoria": 1,
        "codigoSise": 25,
        "codTipoVehiculo": 76
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa21",
    "idMarca": 48,
    "nombreMarca": "RAM",
    "codigoSise": 764,
    "models": [
      {
        "idModelo": 2256,
        "nombreModelo": "Laramie 2500 Hemi",
        "idMarca": 48,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 7555,
        "nombreModelo": "RAM 2500 LARAMIE CREW",
        "idMarca": 48,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa38",
    "idMarca": 215,
    "nombreMarca": "STERLING",
    "codigoSise": 612,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa3e",
    "idMarca": 72,
    "nombreMarca": "TATA DAEWOO",
    "codigoSise": 683,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa41",
    "idMarca": 130,
    "nombreMarca": "THUNDER",
    "codigoSise": 572,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f98c",
    "idMarca": 164,
    "nombreMarca": "AMERICAN MOTORS",
    "codigoSise": 5,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9a3",
    "idMarca": 7,
    "nombreMarca": "CHANGHE",
    "codigoSise": 537,
    "models": [
      {
        "idModelo": 8476,
        "nombreModelo": "CH1012 LC 1.0 CS 4X2 TM",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 53,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7241,
        "nombreModelo": "CH1020 LE AC 1.1 CS 4X2 TM",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 105,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8484,
        "nombreModelo": "CH6390 CARGA",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 49,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 7014,
        "nombreModelo": "CH6391C4",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 104,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 219,
        "nombreModelo": "CH6430T2 1.2L",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 84,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 8478,
        "nombreModelo": "CH7111A IDEAL",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 20,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 5116,
        "nombreModelo": "CH7142  C AC 14 5P 4X2 TM",
        "idMarca": 7,
        "idCategoria": 6,
        "codigoSise": 77,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6064,
        "nombreModelo": "CH7152AB22 Q35",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 103,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5069,
        "nombreModelo": "Coolcar 1.4L",
        "idMarca": 7,
        "idCategoria": 6,
        "codigoSise": 24,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 222,
        "nombreModelo": "Freeca 1.1L van",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 26,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 5067,
        "nombreModelo": "Freedom CH6390 1.1L",
        "idMarca": 7,
        "idCategoria": 6,
        "codigoSise": 28,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 4512,
        "nombreModelo": "Ideal 1,1L 5p A/A",
        "idMarca": 7,
        "idCategoria": 6,
        "codigoSise": 32,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 230,
        "nombreModelo": "Mini Truck CH1012LC",
        "idMarca": 7,
        "idCategoria": 1,
        "codigoSise": 91,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9af",
    "idMarca": 93,
    "nombreMarca": "DAF",
    "codigoSise": 682,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9b1",
    "idMarca": 117,
    "nombreMarca": "DATSUN",
    "codigoSise": 465,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9b4",
    "idMarca": 66,
    "nombreMarca": "DFSK",
    "codigoSise": 2827,
    "models": [
      {
        "idModelo": 10315,
        "nombreModelo": "C35 AC 1.5 4P 4X2 TM",
        "idMarca": 66,
        "idCategoria": 1,
        "codigoSise": 64,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5454,
        "nombreModelo": "C37 AC 1.5 4P 4X2 TM",
        "idMarca": 66,
        "idCategoria": 1,
        "codigoSise": 51,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11859,
        "nombreModelo": "GLORY 560 AC 1.8 5P 4X2 TM",
        "idMarca": 66,
        "idCategoria": 1,
        "codigoSise": 73,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7251,
        "nombreModelo": "K01 AC 1.1 CS 4X2 TM",
        "idMarca": 66,
        "idCategoria": 1,
        "codigoSise": 62,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9ba",
    "idMarca": 122,
    "nombreMarca": "DUKARE",
    "codigoSise": 630,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9bc",
    "idMarca": 224,
    "nombreMarca": "ENRANGER",
    "codigoSise": 7372,
    "models": [
      {
        "idModelo": 11478,
        "nombreModelo": "G3 LUXURY AC 1.5",
        "idMarca": 224,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11651,
        "nombreModelo": "G5 LUXURY AC 1.5",
        "idMarca": 224,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9c7",
    "idMarca": 199,
    "nombreMarca": "FORLAND",
    "codigoSise": 648,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9fc",
    "idMarca": 234,
    "nombreMarca": "LEOPAARD",
    "codigoSise": 819,
    "models": [
      {
        "idModelo": 11818,
        "nombreModelo": "CT7 AC 2.4 CD 4X2 TM",
        "idMarca": 234,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa00",
    "idMarca": 202,
    "nombreMarca": "LOTUS",
    "codigoSise": 7362,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa04",
    "idMarca": 178,
    "nombreMarca": "MAVERICK",
    "codigoSise": 7353,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa07",
    "idMarca": 226,
    "nombreMarca": "MERCURY",
    "codigoSise": 674,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa25",
    "idMarca": 49,
    "nombreMarca": "RENAULT",
    "codigoSise": 379,
    "models": [
      {
        "idModelo": 8945,
        "nombreModelo": "CLIO",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 200,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2264,
        "nombreModelo": "Duster",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 909,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 8961,
        "nombreModelo": "HOVER",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 727,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 2275,
        "nombreModelo": "Kangoo Maxi",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 819,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 4697,
        "nombreModelo": "Koleos",
        "idMarca": 49,
        "idCategoria": 4,
        "codigoSise": 354,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4401,
        "nombreModelo": "Laguna ",
        "idMarca": 49,
        "idCategoria": 5,
        "codigoSise": 128,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5361,
        "nombreModelo": "LOGAN",
        "idMarca": 49,
        "idCategoria": 5,
        "codigoSise": 511,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8928,
        "nombreModelo": "MEGANE",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 772,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11420,
        "nombreModelo": "NUEVO LOGAN",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 1041,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10882,
        "nombreModelo": "OROCH AC",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 1014,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8924,
        "nombreModelo": "SANDERO",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 741,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 4414,
        "nombreModelo": "Scenic 1.6L van 5p",
        "idMarca": 49,
        "idCategoria": 5,
        "codigoSise": 100,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 8940,
        "nombreModelo": "STEPWAY 1.6 MT",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 138,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 4415,
        "nombreModelo": "Symbol",
        "idMarca": 49,
        "idCategoria": 5,
        "codigoSise": 293,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11679,
        "nombreModelo": "TRAFIC",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 1052,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 8896,
        "nombreModelo": "TWINGO",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 701,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2330,
        "nombreModelo": "Twizy Carga Z.E.",
        "idMarca": 49,
        "idCategoria": 1,
        "codigoSise": 825,
        "codTipoVehiculo": 79
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa2a",
    "idMarca": 232,
    "nombreMarca": "SAIC GM WULING",
    "codigoSise": 7336,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa31",
    "idMarca": 235,
    "nombreMarca": "SIMCA",
    "codigoSise": 7378,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa33",
    "idMarca": 51,
    "nombreMarca": "SKODA",
    "codigoSise": 380,
    "models": [
      {
        "idModelo": 7946,
        "nombreModelo": "FABIA",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 378,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2400,
        "nombreModelo": "Felicia",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 119,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2405,
        "nombreModelo": "Kodiaq Ambiente",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 461,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 2409,
        "nombreModelo": "Octavia",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 228,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2449,
        "nombreModelo": "Rapid",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 465,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2454,
        "nombreModelo": "Roomster",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 258,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 6961,
        "nombreModelo": "SUPERB",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 519,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10961,
        "nombreModelo": "YETI",
        "idMarca": 51,
        "idCategoria": 1,
        "codigoSise": 560,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa5d",
    "idMarca": 62,
    "nombreMarca": "ZX AUTO",
    "codigoSise": 583,
    "models": [
      {
        "idModelo": 4502,
        "nombreModelo": "Admiral",
        "idMarca": 62,
        "idCategoria": 6,
        "codigoSise": 17,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 3052,
        "nombreModelo": "Grand Tiger",
        "idMarca": 62,
        "idCategoria": 1,
        "codigoSise": 33,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 3055,
        "nombreModelo": "Land Mark",
        "idMarca": 62,
        "idCategoria": 1,
        "codigoSise": 36,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f996",
    "idMarca": 81,
    "nombreMarca": "BENELLI",
    "codigoSise": 761,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f99c",
    "idMarca": 97,
    "nombreMarca": "BULTACO",
    "codigoSise": 714,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9b9",
    "idMarca": 92,
    "nombreMarca": "DUCATI",
    "codigoSise": 416,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9cf",
    "idMarca": 25,
    "nombreMarca": "GOLDEN DRAGON",
    "codigoSise": 662,
    "models": [
      {
        "idModelo": 5420,
        "nombreModelo": "XML6531E22 AC 2.5 4P 4X2 TM DIESEL",
        "idMarca": 25,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 83
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9f5",
    "idMarca": 182,
    "nombreMarca": "KING LONG",
    "codigoSise": 7357,
    "models": [
      {
        "idModelo": 11722,
        "nombreModelo": "NEW  KINGWIN AC 2.5",
        "idMarca": 182,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 10481,
        "nombreModelo": "XMQ6520E AC 2.8",
        "idMarca": 182,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 83
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa08",
    "idMarca": 209,
    "nombreMarca": "MERCURY LINCOLN",
    "codigoSise": 7366,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa1b",
    "idMarca": 45,
    "nombreMarca": "PEUGEOT",
    "codigoSise": 377,
    "models": [
      {
        "idModelo": 11241,
        "nombreModelo": "2008 ACT",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 867,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8857,
        "nombreModelo": "206",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 219,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10556,
        "nombreModelo": "207 AC 1.4",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 826,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7376,
        "nombreModelo": "208 AC",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 812,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10550,
        "nombreModelo": "3008",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 825,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8887,
        "nombreModelo": "3008N",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 780,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11649,
        "nombreModelo": "301 ACT",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 887,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2156,
        "nombreModelo": "306",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 526,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2158,
        "nombreModelo": "307",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 614,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6116,
        "nombreModelo": "308",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 793,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4724,
        "nombreModelo": "405",
        "idMarca": 45,
        "idCategoria": 3,
        "codigoSise": 334,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4717,
        "nombreModelo": "406",
        "idMarca": 45,
        "idCategoria": 3,
        "codigoSise": 335,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4386,
        "nombreModelo": "407",
        "idMarca": 45,
        "idCategoria": 3,
        "codigoSise": 337,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4389,
        "nombreModelo": "408",
        "idMarca": 45,
        "idCategoria": 3,
        "codigoSise": 344,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8886,
        "nombreModelo": "5008",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 731,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4391,
        "nombreModelo": "508",
        "idMarca": 45,
        "idCategoria": 2,
        "codigoSise": 421,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7042,
        "nombreModelo": "607",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 800,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4719,
        "nombreModelo": "807",
        "idMarca": 45,
        "idCategoria": 3,
        "codigoSise": 348,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 8867,
        "nombreModelo": "BERLINA 206",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 496,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2202,
        "nombreModelo": "Boxer",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 636,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 5477,
        "nombreModelo": "BREAK",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 770,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10815,
        "nombreModelo": "COUPE CABRIOLET 206 CC 2.0",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 843,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8881,
        "nombreModelo": "P84 FIN",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 698,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 4392,
        "nombreModelo": "Partner",
        "idMarca": 45,
        "idCategoria": 3,
        "codigoSise": 351,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 10634,
        "nombreModelo": "PEU5576W PARTNER",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 833,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10733,
        "nombreModelo": "PR FGCRT1 16HDI BM5 AC 1.6 5P 4X2 TM DIESEL",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 822,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 4394,
        "nombreModelo": "RCZ Turbo THP",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 353,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 11509,
        "nombreModelo": "RIFTER ALL 16H B5",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 883,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 11499,
        "nombreModelo": "TRVL BSN LG 20H",
        "idMarca": 45,
        "idCategoria": 1,
        "codigoSise": 882,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa30",
    "idMarca": 71,
    "nombreMarca": "SHINERAY",
    "codigoSise": 432,
    "models": [
      {
        "idModelo": 6941,
        "nombreModelo": "MPV750 DLDG15",
        "idMarca": 71,
        "idCategoria": 1,
        "codigoSise": 39,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 6942,
        "nombreModelo": "X30 1.5L VAN",
        "idMarca": 71,
        "idCategoria": 1,
        "codigoSise": 40,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa39",
    "idMarca": 169,
    "nombreMarca": "STIFF 150",
    "codigoSise": 7352,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa3a",
    "idMarca": 99,
    "nombreMarca": "SUBARU",
    "codigoSise": 464,
    "models": [
      {
        "idModelo": 5746,
        "nombreModelo": "IMPREZA",
        "idMarca": 99,
        "idCategoria": 1,
        "codigoSise": 11,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa3c",
    "idMarca": 54,
    "nombreMarca": "SUZUKI",
    "codigoSise": 381,
    "models": [
      {
        "idModelo": 8197,
        "nombreModelo": "FORSA",
        "idMarca": 54,
        "idCategoria": 1,
        "codigoSise": 43,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 8182,
        "nombreModelo": "G.VITARA",
        "idMarca": 54,
        "idCategoria": 1,
        "codigoSise": 750,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11473,
        "nombreModelo": "GRAND NOMADE",
        "idMarca": 54,
        "idCategoria": 1,
        "codigoSise": 1092,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8255,
        "nombreModelo": "SWIFT BLACK-WHITE EDICION ESPECIAL",
        "idMarca": 54,
        "idCategoria": 1,
        "codigoSise": 866,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10911,
        "nombreModelo": "VITARA PRIVILEGE ALLGRIP",
        "idMarca": 54,
        "idCategoria": 1,
        "codigoSise": 1065,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa4f",
    "idMarca": 58,
    "nombreMarca": "VOLKSWAGEN",
    "codigoSise": 383,
    "models": [
      {
        "idModelo": 9952,
        "nombreModelo": "2009  NEW GOL SEDAN",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 756,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4572,
        "nombreModelo": "Amarok 2.0TSI",
        "idMarca": 58,
        "idCategoria": 3,
        "codigoSise": 583,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9963,
        "nombreModelo": "BEETLE 5C12S1",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1407,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 10186,
        "nombreModelo": "BORA",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1835,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11615,
        "nombreModelo": "CRAFTER",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1964,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 3884,
        "nombreModelo": "Cross Fox",
        "idMarca": 58,
        "idCategoria": 3,
        "codigoSise": 377,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11208,
        "nombreModelo": "E GOLF",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1919,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10012,
        "nombreModelo": "ESCARABAJO",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 308,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 9979,
        "nombreModelo": "FOX 1.6 2P",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1307,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 10041,
        "nombreModelo": "GOL",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1045,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 11725,
        "nombreModelo": "GOLF",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1972,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9948,
        "nombreModelo": "JEEP CROSSFOX",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 277,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5676,
        "nombreModelo": "JETTA",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1736,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2877,
        "nombreModelo": "New Beetle",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1381,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 10716,
        "nombreModelo": "NEW CROSSFOX",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1866,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9968,
        "nombreModelo": "NEW GOL SEDAN POWER",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1358,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2884,
        "nombreModelo": "New Jetta 2L 4p",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1652,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 5616,
        "nombreModelo": "PARATI 1.6",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1727,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5736,
        "nombreModelo": "PASSAT",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1743,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4606,
        "nombreModelo": "Polo",
        "idMarca": 58,
        "idCategoria": 5,
        "codigoSise": 609,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 6017,
        "nombreModelo": "SANTANA",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1774,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9946,
        "nombreModelo": "SAVEIRO",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 22,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2941,
        "nombreModelo": "Sedan",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1106,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2942,
        "nombreModelo": "Spacefox",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 785,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10031,
        "nombreModelo": "T5 TRANSPORTER",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 824,
        "codTipoVehiculo": 83
      },
      {
        "idModelo": 11791,
        "nombreModelo": "T-CROSS TRENDLINE",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1974,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10003,
        "nombreModelo": "TIGUAN 2.0 5P",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1706,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3945,
        "nombreModelo": "Toaureg R5 PJW",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 726,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5152,
        "nombreModelo": "VENTO",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1288,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10953,
        "nombreModelo": "VIRTUS",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1900,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11337,
        "nombreModelo": "VOYAGE",
        "idMarca": 58,
        "idCategoria": 1,
        "codigoSise": 1935,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa57",
    "idMarca": 142,
    "nombreMarca": "YUTONG",
    "codigoSise": 571,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa5b",
    "idMarca": 60,
    "nombreMarca": "ZNA",
    "codigoSise": 2826,
    "models": [
      {
        "idModelo": 7558,
        "nombreModelo": "RICH",
        "idMarca": 60,
        "idCategoria": 1,
        "codigoSise": 7,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f98d",
    "idMarca": 228,
    "nombreMarca": "APRILIA",
    "codigoSise": 704,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9a1",
    "idMarca": 6,
    "nombreMarca": "CHANA",
    "codigoSise": 614,
    "models": [
      {
        "idModelo": 217,
        "nombreModelo": "SC1022BB23D 1.1L P/U D/C",
        "idMarca": 6,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9ce",
    "idMarca": 104,
    "nombreMarca": "GMC",
    "codigoSise": 433,
    "models": [
      {
        "idModelo": 5815,
        "nombreModelo": "ACADIA AC",
        "idMarca": 104,
        "idCategoria": 1,
        "codigoSise": 26,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11314,
        "nombreModelo": "BLAZER",
        "idMarca": 104,
        "idCategoria": 1,
        "codigoSise": 37,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11553,
        "nombreModelo": "CAYON AC",
        "idMarca": 104,
        "idCategoria": 1,
        "codigoSise": 39,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 12007,
        "nombreModelo": "K 1500 YUKON HYBRID 4WD",
        "idMarca": 104,
        "idCategoria": 1,
        "codigoSise": 41,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11091,
        "nombreModelo": "SIERRA 1500 AWD HYBRID",
        "idMarca": 104,
        "idCategoria": 1,
        "codigoSise": 33,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10592,
        "nombreModelo": "YUKON",
        "idMarca": 104,
        "idCategoria": 1,
        "codigoSise": 30,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9db",
    "idMarca": 133,
    "nombreMarca": "HUSSAR",
    "codigoSise": 447,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9f0",
    "idMarca": 112,
    "nombreMarca": "KAWASAKI",
    "codigoSise": 429,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9f7",
    "idMarca": 83,
    "nombreMarca": "KTM",
    "codigoSise": 420,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9f8",
    "idMarca": 35,
    "nombreMarca": "LADA",
    "codigoSise": 371,
    "models": [
      {
        "idModelo": 3571,
        "nombreModelo": "110 1.5L",
        "idMarca": 35,
        "idCategoria": 6,
        "codigoSise": 56,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3572,
        "nombreModelo": "111 1.5L",
        "idMarca": 35,
        "idCategoria": 6,
        "codigoSise": 57,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 7715,
        "nombreModelo": "11183 SEDAN KALINA",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 73,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3573,
        "nombreModelo": "112",
        "idMarca": 35,
        "idCategoria": 6,
        "codigoSise": 58,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3575,
        "nombreModelo": "115 GL",
        "idMarca": 35,
        "idCategoria": 6,
        "codigoSise": 59,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7713,
        "nombreModelo": "2107",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7724,
        "nombreModelo": "21101 SEDAN DINASTIA",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 69,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11190,
        "nombreModelo": "21310 NIVA 5 PUERTAS",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 109,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3577,
        "nombreModelo": "Campero 1.7L SUV",
        "idMarca": 35,
        "idCategoria": 6,
        "codigoSise": 46,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 1517,
        "nombreModelo": "Dinastía",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 89,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7714,
        "nombreModelo": "JEEP",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 45,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3580,
        "nombreModelo": "Kalina 1.6L 4p 80hp",
        "idMarca": 35,
        "idCategoria": 6,
        "codigoSise": 62,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7218,
        "nombreModelo": "NIVA 2121",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 103,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3588,
        "nombreModelo": "Novo 1.5L 4p",
        "idMarca": 35,
        "idCategoria": 6,
        "codigoSise": 66,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7719,
        "nombreModelo": "PREMIER",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 11,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10879,
        "nombreModelo": "SERIE 100 115 GL",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 106,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1527,
        "nombreModelo": "Sport 1.6L 5p T/M STD",
        "idMarca": 35,
        "idCategoria": 1,
        "codigoSise": 99,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9fe",
    "idMarca": 38,
    "nombreMarca": "LIFAN",
    "codigoSise": 333,
    "models": [
      {
        "idModelo": 4252,
        "nombreModelo": "320 LF 132 1.3L",
        "idMarca": 38,
        "idCategoria": 6,
        "codigoSise": 29,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4835,
        "nombreModelo": "520 LF160L1 1.6L",
        "idMarca": 38,
        "idCategoria": 6,
        "codigoSise": 14,
        "codTipoVehiculo": 44
      },
      {
        "idModelo": 4837,
        "nombreModelo": "620 LF7162C 1.6L",
        "idMarca": 38,
        "idCategoria": 6,
        "codigoSise": 31,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1582,
        "nombreModelo": "Foison LF6401 DX 1.3L",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 71,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 1584,
        "nombreModelo": "LF 1022 1.3L",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 55,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7875,
        "nombreModelo": "LF630 AC 1.5",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 42,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7876,
        "nombreModelo": "LF6420 AC 1.3",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 43,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11363,
        "nombreModelo": "LF6480 AC 2.0 5P 4X2 TA",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 90,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10453,
        "nombreModelo": "LF7160L1 AC 5P 4X2 TM",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 88,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 1598,
        "nombreModelo": "X50 1.5L SUV 4x2",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 79,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 1599,
        "nombreModelo": "X60 1.6L SUV 4x2",
        "idMarca": 38,
        "idCategoria": 1,
        "codigoSise": 80,
        "codTipoVehiculo": 1
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa15",
    "idMarca": 221,
    "nombreMarca": "OPEL",
    "codigoSise": 494,
    "models": [
      {
        "idModelo": 11395,
        "nombreModelo": "ANTARA SELECTIVE",
        "idMarca": 221,
        "idCategoria": 1,
        "codigoSise": 7,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11587,
        "nombreModelo": "MOKKA AC 1.4 5P 4X2 TM",
        "idMarca": 221,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa1a",
    "idMarca": 150,
    "nombreMarca": "PEGASSO",
    "codigoSise": 589,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa1d",
    "idMarca": 220,
    "nombreMarca": "PONTIAC",
    "codigoSise": 510,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f992",
    "idMarca": 180,
    "nombreMarca": "BAIC",
    "codigoSise": 7355,
    "models": [
      {
        "idModelo": 10464,
        "nombreModelo": "BJ20 LUXURY",
        "idMarca": 180,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10850,
        "nombreModelo": "X25 COMFORT",
        "idMarca": 180,
        "idCategoria": 1,
        "codigoSise": 4,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10465,
        "nombreModelo": "X35 ELITE",
        "idMarca": 180,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12147,
        "nombreModelo": "X55 LUXURY",
        "idMarca": 180,
        "idCategoria": 1,
        "codigoSise": 10,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12146,
        "nombreModelo": "X65 LUXURY AC 2.0 5P 4X2 TA",
        "idMarca": 180,
        "idCategoria": 1,
        "codigoSise": 9,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f99d",
    "idMarca": 5,
    "nombreMarca": "BYD",
    "codigoSise": 590,
    "models": [
      {
        "idModelo": 6006,
        "nombreModelo": "BYD7005BEV AC",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 88,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12051,
        "nombreModelo": "E5 AC 4P",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 114,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 190,
        "nombreModelo": "F 0 GL 1L 5p",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 24,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12110,
        "nombreModelo": "F0 GL LB AG AC 1.0 5P 4X2 TA",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 38,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 10718,
        "nombreModelo": "F3 GC LF AG AC",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 106,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11792,
        "nombreModelo": "F5 GLX LF M5G AC",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 111,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5074,
        "nombreModelo": "Flyer 0.79L 5p",
        "idMarca": 5,
        "idCategoria": 6,
        "codigoSise": 8,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 201,
        "nombreModelo": "M 6 GLX 2.4L van",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 67,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 5194,
        "nombreModelo": "NEW FLYER QCJ7111A",
        "idMarca": 5,
        "idCategoria": 6,
        "codigoSise": 13,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 3101,
        "nombreModelo": "S6 SUV 4x2 2.4L L4",
        "idMarca": 5,
        "idCategoria": 6,
        "codigoSise": 31,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 209,
        "nombreModelo": "Song G LFT 1.5Turbo",
        "idMarca": 5,
        "idCategoria": 1,
        "codigoSise": 74,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5278,
        "nombreModelo": "YUAN 1.5 TM FULL",
        "idMarca": 5,
        "idCategoria": 6,
        "codigoSise": 57,
        "codTipoVehiculo": 76
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9b6",
    "idMarca": 115,
    "nombreMarca": "DOMY",
    "codigoSise": 7341,
    "models": [
      {
        "idModelo": 5873,
        "nombreModelo": "X7 AC 1.8 5P 4X2 TA",
        "idMarca": 115,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9cb",
    "idMarca": 229,
    "nombreMarca": "GAC",
    "codigoSise": 7374,
    "models": [
      {
        "idModelo": 11747,
        "nombreModelo": "GA4 1.3 T AT GL",
        "idMarca": 229,
        "idCategoria": 1,
        "codigoSise": 3,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11650,
        "nombreModelo": "GS3 1.3T AT GB",
        "idMarca": 229,
        "idCategoria": 1,
        "codigoSise": 2,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12060,
        "nombreModelo": "GS4 1.3T MT GB AC 1.3 5P 4X2 TM",
        "idMarca": 229,
        "idCategoria": 1,
        "codigoSise": 5,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12152,
        "nombreModelo": "GS7 2.0T AT GE AC 2.0 5P 4X2 TA",
        "idMarca": 229,
        "idCategoria": 1,
        "codigoSise": 7,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12153,
        "nombreModelo": "GS8 2.0T AT GT AC 2.0 5P 4X4 TA",
        "idMarca": 229,
        "idCategoria": 1,
        "codigoSise": 8,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9f6",
    "idMarca": 212,
    "nombreMarca": "KINGDOM",
    "codigoSise": 7369,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa0d",
    "idMarca": 69,
    "nombreMarca": "MOSKOVICH",
    "codigoSise": 7338,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa18",
    "idMarca": 156,
    "nombreMarca": "OROMOTO",
    "codigoSise": 503,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa42",
    "idMarca": 113,
    "nombreMarca": "TIANYE",
    "codigoSise": 650,
    "models": [
      {
        "idModelo": 10598,
        "nombreModelo": "GRANDTIGER BQ1023Y2V",
        "idMarca": 113,
        "idCategoria": 1,
        "codigoSise": 12,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa43",
    "idMarca": 56,
    "nombreMarca": "TOYOTA",
    "codigoSise": 382,
    "models": [
      {
        "idModelo": 10477,
        "nombreModelo": "4 RUNNER",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8411,
        "nombreModelo": "4D COROLLA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2315,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5456,
        "nombreModelo": "4K COROLLA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2821,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3794,
        "nombreModelo": "4Runner",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1210,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12056,
        "nombreModelo": "AA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3212,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10235,
        "nombreModelo": "AUT",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2968,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8294,
        "nombreModelo": "AWT",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1983,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6174,
        "nombreModelo": "BB",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2907,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8393,
        "nombreModelo": "BPT",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1141,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 10221,
        "nombreModelo": "BR",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2966,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8420,
        "nombreModelo": "BRT",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1764,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8399,
        "nombreModelo": "BST HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1335,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11548,
        "nombreModelo": "BWT FORTUNER",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3151,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11962,
        "nombreModelo": "CAMRY",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3201,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6001,
        "nombreModelo": "CC 4RUNNER",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2877,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8384,
        "nombreModelo": "CCT HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 849,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8388,
        "nombreModelo": "CD HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 957,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 7067,
        "nombreModelo": "CDT HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2933,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8442,
        "nombreModelo": "CFT HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2321,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5611,
        "nombreModelo": "C-HR AC",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2840,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8357,
        "nombreModelo": "COROLA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2130,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8308,
        "nombreModelo": "DD",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2005,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6137,
        "nombreModelo": "E6P LAND CRUISER PRADO",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2899,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 12099,
        "nombreModelo": "EA HIACE DIESEL",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3217,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 11991,
        "nombreModelo": "EB HIACE DIESEL",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3204,
        "codTipoVehiculo": 84
      },
      {
        "idModelo": 11808,
        "nombreModelo": "EKT HILUX 4X2 CS",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1069,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8391,
        "nombreModelo": "ELP LAND CRUSIER PRADO 3P",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1103,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8456,
        "nombreModelo": "EST HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2628,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8396,
        "nombreModelo": "ETT HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1175,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5524,
        "nombreModelo": "FJ CRUISER",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2831,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8336,
        "nombreModelo": "FORTUNER",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2091,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 2575,
        "nombreModelo": "GT86 2L 2p Boxer",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1593,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 10624,
        "nombreModelo": "GT-86 TA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3017,
        "codTipoVehiculo": 79
      },
      {
        "idModelo": 8270,
        "nombreModelo": "HIACE",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 9,
        "codTipoVehiculo": 81
      },
      {
        "idModelo": 10243,
        "nombreModelo": "HIGHLANDER",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2969,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7097,
        "nombreModelo": "HILUX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2936,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8417,
        "nombreModelo": "INNOVA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1715,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 8417,
        "nombreModelo": "INNOVA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1715,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 2677,
        "nombreModelo": "Innova7 2.7VVTi",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2081,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 8392,
        "nombreModelo": "JE COROLLA 1.6 TM",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1119,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 2679,
        "nombreModelo": "Land Cruiser 100",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2767,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 10131,
        "nombreModelo": "MATRIX",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2961,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 8446,
        "nombreModelo": "NEW COROLLA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2408,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6145,
        "nombreModelo": "NEW FORTUNER  AC 2.7 4X4 TA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2901,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 8468,
        "nombreModelo": "NEW HILUX 2.4 CD 4X4 TM",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2880,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 5312,
        "nombreModelo": "NEW INNOVA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2168,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 8326,
        "nombreModelo": "NEW RAV 4AC",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2052,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8448,
        "nombreModelo": "NEW YARIS SD",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2481,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 7328,
        "nombreModelo": "PRIUS 1223 2WD",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2958,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8274,
        "nombreModelo": "QQ YARIS SEDAN",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1105,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8329,
        "nombreModelo": "RAC4 4X4 5P TM",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2059,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6170,
        "nombreModelo": "RAV",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2906,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3843,
        "nombreModelo": "RAV4",
        "idMarca": 56,
        "idCategoria": 2,
        "codigoSise": 791,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5537,
        "nombreModelo": "RR YARIS SEDAN TA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2834,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11153,
        "nombreModelo": "SCION TC",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3098,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 12134,
        "nombreModelo": "SEQUOIA 4DR",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3220,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 6186,
        "nombreModelo": "SIENNA 3.5",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2910,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 11603,
        "nombreModelo": "SS YARIS SEDAN",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3158,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5381,
        "nombreModelo": "STOUT",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 14,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 11699,
        "nombreModelo": "TACOMA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 3169,
        "codTipoVehiculo": 80
      },
      {
        "idModelo": 5400,
        "nombreModelo": "TERCEL",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 21,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8413,
        "nombreModelo": "TOYOTA PRIUS C SPORT",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 1637,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8369,
        "nombreModelo": "TT YARIS SEDAN",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2665,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10380,
        "nombreModelo": "TUNDRA 4WD 5.7L",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2979,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 8298,
        "nombreModelo": "UU YARIS SEDAN 1.3 TM PP SIN AA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2366,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 8386,
        "nombreModelo": "VENZA",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 900,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 8353,
        "nombreModelo": "YARIS 1,5 A/A",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2121,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 6061,
        "nombreModelo": "ZZ HIACE DIESEL",
        "idMarca": 56,
        "idCategoria": 1,
        "codigoSise": 2886,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa54",
    "idMarca": 166,
    "nombreMarca": "XGJAO",
    "codigoSise": 731,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f989",
    "idMarca": 1,
    "nombreMarca": "ALFA ROMEO",
    "codigoSise": 357,
    "models": [
      {
        "idModelo": 5091,
        "nombreModelo": "146 2L 4p",
        "idMarca": 1,
        "idCategoria": 4,
        "codigoSise": 28,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 5090,
        "nombreModelo": "147 2L 4p",
        "idMarca": 1,
        "idCategoria": 2,
        "codigoSise": 29,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3,
        "nombreModelo": "156 2.5L 4p",
        "idMarca": 1,
        "idCategoria": 1,
        "codigoSise": 55,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4,
        "nombreModelo": "159 3.2L 4p",
        "idMarca": 1,
        "idCategoria": 1,
        "codigoSise": 56,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5,
        "nombreModelo": "Brera 3.2L 3p",
        "idMarca": 1,
        "idCategoria": 1,
        "codigoSise": 48,
        "codTipoVehiculo": 79
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f98b",
    "idMarca": 76,
    "nombreMarca": "AMC",
    "codigoSise": 51,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f98f",
    "idMarca": 2,
    "nombreMarca": "AUDI",
    "codigoSise": 358,
    "models": [
      {
        "idModelo": 5520,
        "nombreModelo": "A1 1.4",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 298,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 8,
        "nombreModelo": "A3 1.2LTFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 238,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 25,
        "nombreModelo": "A4 1.4TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 283,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 46,
        "nombreModelo": "A5 2.0TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 286,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 50,
        "nombreModelo": "A6 2.0TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 120,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 55,
        "nombreModelo": "A7 3.0TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 125,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 56,
        "nombreModelo": "A8 3.0TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 126,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 58,
        "nombreModelo": "New Q5 2.0TFSI ",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 287,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 60,
        "nombreModelo": "New Q7 2.0TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 289,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 61,
        "nombreModelo": "Q3 1.4TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 242,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 63,
        "nombreModelo": "Q5 2.0TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 290,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 7088,
        "nombreModelo": "Q7",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 321,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 10547,
        "nombreModelo": "Q8 TFSI",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 340,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 73,
        "nombreModelo": "TT 1.8T 1.8L",
        "idMarca": 2,
        "idCategoria": 1,
        "codigoSise": 134,
        "codTipoVehiculo": 79
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f99f",
    "idMarca": 179,
    "nombreMarca": "CAMC",
    "codigoSise": 7354,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9a4",
    "idMarca": 8,
    "nombreMarca": "CHANGHE SUZUKI",
    "codigoSise": 2828,
    "models": [
      {
        "idModelo": 10129,
        "nombreModelo": "CH6391C4 LUXURY",
        "idMarca": 8,
        "idCategoria": 1,
        "codigoSise": 13,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4141,
        "nombreModelo": "Splash 1.4L 5p",
        "idMarca": 8,
        "idCategoria": 5,
        "codigoSise": 1,
        "codTipoVehiculo": 76
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9a8",
    "idMarca": 11,
    "nombreMarca": "CHRYSLER",
    "codigoSise": 361,
    "models": [
      {
        "idModelo": 8978,
        "nombreModelo": "CHEROKEE LAREDO",
        "idMarca": 11,
        "idCategoria": 1,
        "codigoSise": 16,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9000,
        "nombreModelo": "COMPASS",
        "idMarca": 11,
        "idCategoria": 1,
        "codigoSise": 46,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9011,
        "nombreModelo": "GRAND CHEROKEE LAREDO",
        "idMarca": 11,
        "idCategoria": 1,
        "codigoSise": 69,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9014,
        "nombreModelo": "JOURNEY SXT AC",
        "idMarca": 11,
        "idCategoria": 1,
        "codigoSise": 72,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 569,
        "nombreModelo": "Pacífica Touring FWD",
        "idMarca": 11,
        "idCategoria": 1,
        "codigoSise": 82,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 9010,
        "nombreModelo": "RUBICON WRANGLER",
        "idMarca": 11,
        "idCategoria": 1,
        "codigoSise": 61,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 11318,
        "nombreModelo": "TOWN - COUNTRY",
        "idMarca": 11,
        "idCategoria": 1,
        "codigoSise": 89,
        "codTipoVehiculo": 77
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9b3",
    "idMarca": 68,
    "nombreMarca": "DAYTONA",
    "codigoSise": 622,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9d0",
    "idMarca": 26,
    "nombreMarca": "GREAT WALL",
    "codigoSise": 500,
    "models": [
      {
        "idModelo": 5549,
        "nombreModelo": " GREAT WALL M4 LUXURY",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 495,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 5305,
        "nombreModelo": "C30 CONFORT",
        "idMarca": 26,
        "idCategoria": 6,
        "codigoSise": 395,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 9821,
        "nombreModelo": "CC7150SM01 ELITE",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 13,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 7238,
        "nombreModelo": "DEER",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 528,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 896,
        "nombreModelo": "Florid 1.5L Elite 5p",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 88,
        "codTipoVehiculo": 76
      },
      {
        "idModelo": 9816,
        "nombreModelo": "GREAT WALL M4 LUXURY",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 306,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 5265,
        "nombreModelo": "H3 AC 2.0 5P 4X2 TM",
        "idMarca": 26,
        "idCategoria": 6,
        "codigoSise": 374,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3283,
        "nombreModelo": "H5 Turbo 2L Mitsubishi 174hp SUV 4x2 T/M A/A cue",
        "idMarca": 26,
        "idCategoria": 6,
        "codigoSise": 268,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 905,
        "nombreModelo": "H6 1.5Turbo 1.5L SUV 4x2 T/M A/A 2AB ABS",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 433,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 9886,
        "nombreModelo": "H9 AC 2.0 5P",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 385,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 906,
        "nombreModelo": "Haval",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 434,
        "codTipoVehiculo": 1
      },
      {
        "idModelo": 5854,
        "nombreModelo": "HOVER",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 503,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 4190,
        "nombreModelo": "M4 1.5L 5p VVT DOHC",
        "idMarca": 26,
        "idCategoria": 6,
        "codigoSise": 250,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 3294,
        "nombreModelo": "Safe 2.3L SUV",
        "idMarca": 26,
        "idCategoria": 6,
        "codigoSise": 98,
        "codTipoVehiculo": 94
      },
      {
        "idModelo": 930,
        "nombreModelo": "Sailor 2,2L",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 451,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 3297,
        "nombreModelo": "Socool 2.3L",
        "idMarca": 26,
        "idCategoria": 6,
        "codigoSise": 101,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 9826,
        "nombreModelo": "VOLEEX C30",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 32,
        "codTipoVehiculo": 77
      },
      {
        "idModelo": 3300,
        "nombreModelo": "Wingle 2.2L P/U C/S 4x2 A/A semifull",
        "idMarca": 26,
        "idCategoria": 6,
        "codigoSise": 84,
        "codTipoVehiculo": 82
      },
      {
        "idModelo": 945,
        "nombreModelo": "Wingle5 2.2L",
        "idMarca": 26,
        "idCategoria": 1,
        "codigoSise": 466,
        "codTipoVehiculo": 82
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9d9",
    "idMarca": 106,
    "nombreMarca": "HUMMER",
    "codigoSise": 498,
    "models": [
      {
        "idModelo": 11466,
        "nombreModelo": "HUMMER H3",
        "idMarca": 106,
        "idCategoria": 1,
        "codigoSise": 9,
        "codTipoVehiculo": 94
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039f9e9",
    "idMarca": 73,
    "nombreMarca": "JIANSHE",
    "codigoSise": 444,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039f9ef",
    "idMarca": 191,
    "nombreMarca": "JOYLONG",
    "codigoSise": 2825,
    "models": [
      {
        "idModelo": 10649,
        "nombreModelo": "HKL6600C AC 2.5 4P 4X2 TM DIESEL",
        "idMarca": 191,
        "idCategoria": 1,
        "codigoSise": 19,
        "codTipoVehiculo": 83
      }
    ]
  },
  {
    "_id": "60993e8c3656b5b1d039fa0c",
    "idMarca": 91,
    "nombreMarca": "MORRIS",
    "codigoSise": 53,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa0e",
    "idMarca": 120,
    "nombreMarca": "MOTOR 1",
    "codigoSise": 450,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa34",
    "idMarca": 98,
    "nombreMarca": "SKYGO",
    "codigoSise": 600,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa44",
    "idMarca": 162,
    "nombreMarca": "TRAILMASTER",
    "codigoSise": 7348,
    "models": []
  },
  {
    "_id": "60993e8c3656b5b1d039fa58",
    "idMarca": 89,
    "nombreMarca": "Z1",
    "codigoSise": 7324,
    "models": []
  }
]