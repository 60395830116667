import {
	SET_QUOTE_LOADING,
	SET_QUOTE_DATA,
	SET_QUOTE_ERROR,
	LOADING_COUNTRIES,
	SET_COUNTRIES,
	ERROR_COUNTRIES,
	SET_QUOTE_TYPE,
	CHANGE_GLOBAL_STATE,
	SET_PRODUCT,
	SET_LOADINGPRODUCT,
	SET_NUMBERPASSENGERS,
	SET_EMPTY_STATE,
	SET_DATA_RESPONSE,
	SET_WPP_VIEW,
} from "./../../types";

export const initialState = {
	quoteType: "",
	loading: false,
	loadingProducts: true,
	data: [],
	error: null,
	loadingCountries: true,
	countries: [],
	departure: [
		{ label: "América del Norte", value: "1" },
		{ label: "Europa-Medio Oriente", value: "2" },
		{ label: "América Central & Caribe", value: "3" },
		{ label: "América del Sur", value: "4" },
		{ label: "Africa", value: "5" },
		{ label: "Asia", value: "6" },
		{ label: "Oceania", value: "7" },
		{ label: "Tarjeta Anual", value: "10" },
		{ label: "Ecuador", value: "593" },
	],
	selectedProduct: null,
	errorCountries: null,
	numberPassengers: 0,
	dataResponse: null,
	openWppFloat: false,
};

export const QuoteReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case SET_QUOTE_TYPE:
			return {
				...state,
				quoteType: actions.payload,
			};

		case SET_QUOTE_LOADING:
			return {
				...state,
				loading: true,
				error: null,
			};

		case SET_QUOTE_DATA:
			return {
				...state,
				loading: false,
				data: [actions.payload],
				error: null,
			};

		case SET_QUOTE_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
			};

		case LOADING_COUNTRIES:
			return {
				...state,
				loadingCountries: true,
				errorCountries: null,
			};

		case SET_COUNTRIES:
			return {
				...state,
				loadingCountries: false,
				countries: actions.payload,
				errorCountries: null,
			};

		case CHANGE_GLOBAL_STATE:
			return {
				...state,
				data: {
					...state.data,
					clientInfo: state.data.clientInfo
						? {
								...state.data.clientInfo,
								...actions.payload,
						  }
						: { ...actions.payload },
				},
			};

		case SET_PRODUCT:
			return {
				...state,
				selectedProduct: actions.payload,
			};

		case SET_LOADINGPRODUCT:
			return {
				...state,
				loadingProducts: false,
			};

		case SET_NUMBERPASSENGERS:
			return {
				...state,
				numberPassengers: actions.payload,
			};

		case ERROR_COUNTRIES:
			return {
				...state,
				loadingCountries: false,
				errorCountries: actions.payload,
			};

		case SET_EMPTY_STATE:
			return {
				...state,
				data: [],
				numberPassengers: 0,
				quoteType: "",
				selectedProduct: null,
				error: null,
				loadingProducts: true,
				loading: false,
			};

		case SET_DATA_RESPONSE:
			return {
				...state,
				dataResponse: actions.payload,
			};

		case SET_WPP_VIEW:
			return {
				...state,
				openWppFloat: actions.payload,
			};

		default:
			return state;
	}
};
