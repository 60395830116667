import { SET_ALERT } from './../../types';

export const initialState = {
  type: null,
  message: null
}

export const AlertReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_ALERT:
      return {
        ...actions.payload
      }
  
    default:
      return state;
  }
}