import { useContext, useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useGA4React } from "ga-4-react";

import { Header } from "../ui/Header";
import { Footer } from "../ui/Footer";
import { quoteContext } from "../../context/quote/QuoteContext";
import FloatingWhatsApp from "../ui/WppFloat.jsx";

import BackgroundGyB from "./../../assets/img/background.png";
// import { useGaTracker } from "../../hooks/useGaTracker";

const useStyles = makeStyles((theme) => ({
	layout: {
		height: ({ pathname, loadingProducts, loadingCountries }) => {
			let height = "";

			if (pathname !== "/") {
				height = "100vh";
			}

			if (pathname === "/quote") {
				height = "100vh";
			}

			if (pathname === "/share") {
				if (loadingCountries) {
					height = "100vh";
				} else {
					height = "fit-content";
				}
			}

			if (pathname === "/glossary") {
				height = "fit-content";
			}

			if (pathname === "/quote/products") {
				if (loadingProducts) {
					height = "100vh";
				} else {
					height = "fit-content";
				}
			}

			if (pathname === "/quote/travel") {
				if (loadingProducts) {
					height = "100vh";
				} else {
					height = "fit-content";
				}
			}

			return height;
		},
		background: ({ pathname }) =>
			pathname !== "/" ? `url(${BackgroundGyB})` : null,
		backgroundSize: ({ pathname }) => {
			let size = null;

			if (pathname !== "/") {
				size = "cover";
			}

			if (pathname === "/glossary") {
				size = "contain";
			}

			return size;
		},

		[theme.breakpoints.down(900)]: {
			height: ({ pathname, loadingProducts }) => {
				let height = "fit-content";

				if (pathname === "/") {
					height = "fit-content";
				} else {
					height = "100vh";
				}

				if (pathname === "/quote/travel") {
					if (loadingProducts) {
						height = "100vh";
					} else {
						height = "fit-content";
					}
				}

				return height;
			},
		},
		[theme.breakpoints.down(700)]: {
			height: ({ pathname, loadingProducts }) => {
				let height = "fit-content";

				if (pathname === "/quote") {
					height = "fit-content";
				}

				if (pathname === "/share") {
					height = "100vh";
				}

				if (pathname === "/quote/products") {
					if (loadingProducts) {
						height = "100vh";
					} else {
						height = "fit-content";
					}
				}

				if (pathname === "/quote/travel") {
					if (loadingProducts) {
						height = "100vh";
					} else {
						height = "fit-content";
					}
				}

				return height;
			},
		},
	},
	layout__content: {
		height: ({ pathname, loadingProducts }) => {
			let height = "100%";

			if (pathname === "/quote/products" || pathname === "/quote/travel") {
				if (loadingProducts) {
					height = "1000px";
				} else {
					height = "100%";
				}
			}

			return height;
		},
		display: ({ pathname }) => (pathname === "/quote/product" ? "flex" : null),
		flexDirection: ({ pathname }) =>
			pathname === "/quote/product" ? "column" : null,
		overflowY: ({ pathname }) =>
			pathname === "/quote/products" ? "scroll" : null,
		padding: ({ pathname }) =>
			(pathname === "/quote" || pathname === "/quote/products") && "20px 0",
	},
}));

export const Layout = ({ children }) => {
	const [openwpp, setOpenWpp] = useState(false);

	const { pathname } = useLocation();

	const ga = useGA4React("G-V1VR9LT1RY");

	const { loadingProducts, loadingCountries } = useContext(quoteContext);

	const classes = useStyles({ pathname, loadingProducts, loadingCountries });

	useEffect(() => {
		(function () {
			if (
				(pathname === "/quote/products" || pathname === "/quote/travel") &&
				!loadingProducts
			) {
				setOpenWpp(true);
			} else {
				setOpenWpp(false);
			}
		})();

		return () => setOpenWpp(false);
	}, [pathname, loadingProducts, setOpenWpp]);

	useEffect(() => {
		(async function () {
			if (ga) {
				await ga.pageview(window.location.pathname);
				await ga.gtag("config", "G-V1VR9LT1RY", {
					page_path: window.location.pathname,
				});
			}
		})();
	}, [ga]);

	useEffect(() => {
		(async function () {
			if (ga) {
				await ga.pageview(window.location.pathname);
				await ga.gtag("config", "G-V1VR9LT1RY", {
					page_path: window.location.pathname,
				});
			}
		})();
		// eslint-disable-next-line
	}, [window.location.pathname]);

	return (
		<Box
			className={classes.layout}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			{pathname !== "/" && <Header />}

			<Box className={classes.layout__content}>{children}</Box>
			<FloatingWhatsApp
				open={openwpp}
				phoneNumber="593985689332"
				statusMessage="En línea"
				accountName="Agente GB"
				chatMessage="Hola, gracias por permitir que te cuidemos como a nosotros mismos. ¿Necesitas ayuda para elegir o comprar tu seguro?"
				allowClickAway
				allowEsc
				placeholder="Escríbenos..."
			/>

			<Footer />
		</Box>
	);
};
