import { useState } from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import BarChartIcon from "@material-ui/icons/BarChart";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import NoteIcon from "@material-ui/icons/Note";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

import { MainContainer } from "../components/containers/MainContainer";
import { CardGlossary } from "../components/ui/Cards";

const useStyles = makeStyles((theme) => ({
	glossary: {},
}));

export const Glossary = () => {
	const classes = useStyles();

	const [rows] = useState([
		{
			icon: AttachMoneyIcon,
			title: "Deducible",
			text: "El valor que debes asumir antes de acceder a los beneficios de tu poliza.*Todos los beneficios están sujetos a deducible, salvo que expresamente se indique lo contrario.",
		},
		{
			icon: PersonPinCircleIcon,
			title: "UCR",
			text: "Usual, Customary and Reasonable (UCR) por sus siglas en ingles. Significa según lo usual, razonable y acostumbrado. Este término se usa para indicar el valor máximo a pagar por un servicio según la región donde se originan los gastos.",
		},
		{
			icon: AttachMoneyIcon,
			title: "Limite Máximo",
			text: "Indica el monto máximo que una compañía de seguros pagará por un gasto cubierto.",
		},
		{
			icon: AttachMoneyIcon,
			title: "Valor presentado",
			text: "Valor total facturado por el proveedor en su moneda local.",
		},
		{
			icon: DateRangeIcon,
			title: "Fecha de servicio",
			text: "Mes, dia y anio en el que ocurren los servicios.",
		},
		{
			icon: ReceiptIcon,
			title: "Número de factura",
			text: "Numero de cuenta o factura asignado por el proveedor.",
		},
		{
			icon: AttachMoneyIcon,
			title: "Monto no cubierto",
			text: "Los gastos facturados que no se consideran cubiertos segun los términos del contrato.",
		},
		{
			icon: AssignmentIndIcon,
			title: "Tipo de servicio",
			text: "Descripción de los servicios recibidos por el asegurado.",
		},
		{
			icon: AssignmentIndIcon,
			title: "Proveedor de servicio",
			text: "Nombre del médico, hospital, centro, proveedor o persona que cobró por los servicios.",
		},
		{
			icon: VisibilityOffIcon,
			title: "Exclusiones",
			text: "Situación descrita la póliza o contrato que no será cubierta y por tanto tampoco indemnizada.",
		},
		{
			icon: PermContactCalendarIcon,
			title: "Responsabilidad del asegurado",
			text: "Parte de los gastos facturados que le corresponde asumir al asegurado según lo determinado en el contrato. Pueden corresponder a el deducible, exclusiones, excesos según UCR, cualquier cantidad sobre el monto máximo de la cobertura, etc.",
		},
		{
			icon: BarChartIcon,
			title: "Coaseguro",
			text: "Porción / Porcentaje de los gastos facturados que forman parte de la responsabilidad del paciente.",
		},
		{
			icon: AccessibilityIcon,
			title: "Cobertura",
			text: "O beneficio.",
		},
		{
			icon: NoteIcon,
			title: "Notas",
			text: "Comentarios, explicación de los montos no cubiertos o solicitud adicional de documentos. Ampliación del detalle.",
		},
		{
			icon: TrendingDownIcon,
			title: "Descuento",
			text: "Porción de los gastos facturados que representan un ahorro otorgado por el proveedor. No corresponden a la responsabilidad del paciente.",
		},
		{
			icon: DateRangeIcon,
			title: "Preexistencia",
			text: "Condición médica diagnosticada antes de la fecha inicial de cobertura de la póliza.",
		},
	]);

	return (
		<>
			<Box className={classes.glossary} height="100%" py={6}>
				<MainContainer>
					<Grid container spacing={5}>
						{rows.map((item, index) => (
							<Grid key={index} item xs={12} sm={6} md={4} lg={4}>
								<CardGlossary {...item} />
							</Grid>
						))}
					</Grid>
				</MainContainer>
			</Box>
		</>
	);
};
