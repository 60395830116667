import { useEffect } from "react";
import {
	makeStyles,
	Modal,
	Fade,
	Backdrop,
	Container,
	Grid,
	Box,
	Button,
	Typography,
	IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

import { WppButton } from "./Buttons";
import { InputYear } from "./Inputs";
import { Title } from "./Titles";
import MainLogo from "./../../assets/img/GBLogo.svg";

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(2),
		borderRadius: "7px",
		maxHeight: "450px",
		maxWidth: "550px",
		height: "100%",
		width: "100%",

		"& .MuiContainer-root": {
			height: "100%",

			"& .MuiGrid-root ": {
				height: "100%",
			},
		},
	},
	quotingSuccessModal__content: {
		backgroundColor: theme.palette.common.white,
		borderRadius: 20,
	},
	quotingSuccessModal__content_container: {
		maxWidth: "265px",
		width: "100%",
		margin: "auto",
	},
}));

export const MainModal = (props) => {
	const classes = useStyles();

	const {
		open,
		numberAdults,
		numberMinors20,
		numberAdults70,
		onHandleClose,
		onSetNext,
		onHandleChangeStatePassenger,
	} = props;

	const handleClickButton = () => {
		onSetNext((c) => !c);
		onHandleClose();
	};

	const showInputDate = (type, count) => {
		const arry = [];

		for (let index = 1; index <= count; index++) {
			arry.push(index);
		}

		return arry.map((item, index) => (
			<>
				<Grid
					key={index}
					item
					xs={12}
					sm={6}
					md={4}
					lg={4}
					style={{ height: "fit-content" }}
				>
					<InputYear
						text="Fecha de nacimiento"
						name="dateOfBirth"
						datebirth={type}
						onHandleChange={onHandleChangeStatePassenger}
					/>
				</Grid>
			</>
		));
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onHandleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Container maxWidth="lg">
						<Grid container>
							<Grid item xs={12}>
								<Box
									height="100%"
									display="flex"
									flexDirection="column"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box>
										<Title variant="h5" text="title here" />
									</Box>

									<Box
										height="100%"
										width="100%"
										display="flex"
										flexDirection="column"
									>
										<Box
											height="100%"
											display="flex"
											flexDirection="column"
											justifyContent="space-around"
										>
											{numberAdults && (
												<Box display="flex" flexDirection="column">
													<Box>
														<Title
															variant="subtitle1"
															text="Fecha de nacimiento para adultos"
															color="#616161"
														/>
													</Box>
													<Box width="100%" display="flex">
														<Container
															maxWidth="lg"
															style={{ padding: 0, marginTop: 5 }}
														>
															<Grid container spacing={2}>
																{showInputDate("adults", numberAdults)}
															</Grid>
														</Container>
													</Box>
												</Box>
											)}

											{numberMinors20 && (
												<Box display="flex" flexDirection="column">
													<Box>
														<Title
															variant="subtitle1"
															text="Fecha de nacimiento para menores"
															color="#616161"
														/>
													</Box>
													<Box width="100%" display="flex">
														<Container
															maxWidth="lg"
															style={{ padding: 0, marginTop: 5 }}
														>
															<Grid container spacing={2}>
																{showInputDate("minors20", numberMinors20)}
															</Grid>
														</Container>
													</Box>
												</Box>
											)}

											{numberAdults70 && (
												<Box display="flex" flexDirection="column">
													<Box>
														<Title
															variant="subtitle1"
															text="Fecha de nacimiento para menores"
															color="#616161"
														/>
													</Box>
													<Box width="100%" display="flex">
														<Container
															maxWidth="lg"
															style={{ padding: 0, marginTop: 5 }}
														>
															<Grid container spacing={2}>
																{showInputDate("adults70", numberAdults70)}
															</Grid>
														</Container>
													</Box>
												</Box>
											)}
										</Box>
									</Box>

									<Box>
										<Button
											className={classes.modal__button}
											variant="contained"
											color="secondary"
											onClick={handleClickButton}
										>
											Continuar
										</Button>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</div>
			</Fade>
		</Modal>
	);
};

export const QuotingSuccessModal = ({
	open,
	onHandleClose,
	onHandleResetStateProvider,
}) => {
	const classes = useStyles();

	const history = useHistory();

	const handleNewQuote = () => {
		onHandleResetStateProvider();

		history.push("/");
	};

	useEffect(() => {
		return () => onHandleClose();
		// eslint-disable-next-line
	}, []);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onHandleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box className={classes.quotingSuccessModal__content} py={2} px={3}>
					<Box
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						mb={1}
					>
						<IconButton size="small" onClick={onHandleClose}>
							<CloseIcon fontSize="small" style={{ color: "#989797" }} />
						</IconButton>
					</Box>
					<Box display="flex" justifyContent="center" mb={4}>
						<img src={MainLogo} alt="main logo" height="50" />
					</Box>
					<Box id="transition-modal-title" mt={1} mb={4}>
						<Typography
							variant="subtitle1"
							align="center"
							style={{ fontSize: "1.10rem" }}
						>
							¡Cotización exitosa!
						</Typography>
						<Typography variant="h6" align="center">
							Un asesor te contactará de inmediato.
						</Typography>
					</Box>

					<Box className={classes.quotingSuccessModal__content_container}>
						<Box
							id="transition-modal-description"
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<WppButton text="Escribenos" number="593985689332" />
							<Button
								variant="outlined"
								color="secondary"
								style={{ marginTop: 20 }}
								onClick={handleNewQuote}
							>
								Nueva cotización
							</Button>
						</Box>

						<Box marginTop={4} marginBottom={1}>
							<Typography variant="body1" align="center">
								Gracias por permitir que te cuidemos como a nosotros mismos.
							</Typography>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};
