import { useState } from 'react';
import {
	makeStyles,
	Grid,
	ButtonGroup,
	Button,
	Box,
	Typography,
	Hidden,
} from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
// import FavoriteIcon from "@material-ui/icons/Favorite";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { InputTextField, InputDate, CustomSelect } from './../ui/Inputs';
import { CardHome } from './../ui/Cards';
import { MainButton, WppButton, PhoneButton } from './../ui/Buttons';
import { SimpleLoading } from '../ui/Loadings';

const useStyles = makeStyles((theme) => ({
	home__buttonGroup: {
		width: '120px',
		transition: 'all .3s ease-in-out',

		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
			color: 'white',
		},
	},
	home__buttonGroup_active: {
		backgroundColor: theme.palette.secondary.light,
		color: theme.palette.common.white,

		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	selectCustom: {
		'& .MuiInputBase-root': {
			color: 'white',
		},
		'& input': {
			color: 'white !important',
		},
	},
	containerButtonCall: {
		[theme.breakpoints.down(600)]: {
			flexDirection: 'column',
		},

		'& button': {
			color: 'white',
			borderColor: 'white',
		},
	},
	selectCustom__wpp: {
		color: theme.palette.common.white,

		'& .MuiButtonBase-root': {
			color: '#9a324e !important',
		},
	},
	gridInfo: {
		[theme.breakpoints.down(950)]: {
			marginTop: 40,
		},
	},
}));

const genres = [
	{
		label: 'Hombre',
		value: 'M',
	},
	{
		label: 'Mujer',
		value: 'F',
	},
];
const statusCivil = [
	{
		label: 'Casado/a',
		value: '2',
	},
	{
		label: 'Soltero/a',
		value: '1',
	},
	{
		label: 'Viudo/a',
		value: '3',
	},
	{
		label: 'Divorciado/a',
		value: '4',
	},
];

export const ContentHomeForm = (props) => {
	const {
		state: {
			email,
			name,
			lastname1,
			lastname2,
			birthdate,
			plate,
			date_start,
			date_return,
			health,
			wpp,
			departure: departureState,
		},
		quote,
		loadingVehicle,
		loadingCountries,
		countries,
		departure,
		onSetQuote,
		onHandleChangeState,
		onHandleChangeStateObj,
		onHandleSubmit,
		onHandleChangeNumberPassengers,
	} = props;

	const classes = useStyles();

	const [passengers] = useState([
		{ label: '0', value: 0 },
		{ label: '1', value: 1 },
		{ label: '2', value: 2 },
		{ label: '3', value: 3 },
		{ label: '4', value: 4 },
		{ label: '5', value: 5 },
		{ label: '6', value: 6 },
		{ label: '7', value: 7 },
		{ label: '8', value: 8 },
	]);

	const handleClick = (event) => {
		event.preventDefault();

		onSetQuote(event.currentTarget.getAttribute('id'));

		const buttons = document.querySelectorAll('.containerButtons button');
		buttons.forEach((button) => {
			button.classList.remove(classes.home__buttonGroup_active);
		});

		event.currentTarget.classList.add(classes.home__buttonGroup_active);
	};

	const showInputs = () => {
		if (quote === 'cars') {
			return (
				<>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputTextField
							name="email"
							text="Email"
							placeholder="Ej. example@gmail.com"
							value={email}
							onHandleChange={onHandleChangeState}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputTextField
							type="text"
							name="name"
							text="Nombres"
							placeholder="Ej. Mike"
							value={name}
							onHandleChange={onHandleChangeState}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputTextField
							type="text"
							name="lastname1"
							text="Primer apellido"
							placeholder="Ej. Giménez"
							value={lastname1}
							onHandleChange={onHandleChangeState}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputTextField
							name="lastname2"
							text="Segundo apellido"
							placeholder="Ej. Chester"
							value={lastname2}
							onHandleChange={onHandleChangeState}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputDate
							name="birthdate"
							travel
							birthdate
							text="Fecha de nacimiento"
							value={birthdate}
							onHandleChange={onHandleChangeStateObj}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className={`animate__animated animate__fadeIn inputCustom ${classes.selectCustom}`}
					>
						<CustomSelect
							name="genre"
							text="Genero"
							data={genres}
							onHandleChange={onHandleChangeStateObj}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						className={`animate__animated animate__fadeIn inputCustom ${classes.selectCustom}`}
					>
						<CustomSelect
							name="civilStatus"
							text="Estado Civil"
							data={statusCivil}
							onHandleChange={onHandleChangeStateObj}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputTextField
							name="plate"
							text="Placa vehicular"
							placeholder="Ej. GSL5490"
							value={plate}
							onHandleChange={onHandleChangeState}
							limit={8}
							uppercase
						/>
					</Grid>
				</>
			);
		}

		if (quote === 'travel') {
			return (
				<>
					<Grid
						item
						xs={12}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputTextField
							name="email"
							text="Email"
							placeholder="Ej. example@gmail.com"
							value={email}
							onHandleChange={onHandleChangeState}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className={`animate__animated animate__fadeIn inputCustom ${classes.selectCustom}`}
					>
						<CustomSelect
							name="departure"
							text="Origen"
							nameLabel="country"
							data={countries}
							defaultSelect={departureState}
							onHandleChange={onHandleChangeStateObj}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className={`animate__animated animate__fadeIn inputCustom ${classes.selectCustom}`}
					>
						<CustomSelect
							name="arrival"
							text="Destino"
							data={departure}
							onHandleChange={onHandleChangeStateObj}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputDate
							name="date_start"
							travel
							mindate
							text="Fecha partida"
							value={date_start}
							onHandleChange={onHandleChangeStateObj}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						className="animate__animated animate__fadeIn inputCustom"
					>
						<InputDate
							name="date_return"
							travel
							text="Fecha regreso"
							value={date_return}
							onHandleChange={onHandleChangeStateObj}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						className="animate__animated animate__fadeIn"
						style={{ zIndex: 99 }}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4} className={classes.selectCustom}>
								<CustomSelect
									name="adults"
									text="Adultos"
									defaultSelect
									data={passengers}
									onHandleChange={onHandleChangeStateObj}
									onHandleChangeNumberPassengers={
										onHandleChangeNumberPassengers
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} className={classes.selectCustom}>
								<CustomSelect
									name="minors20"
									text="Menores"
									defaultSelect
									data={passengers}
									onHandleChange={onHandleChangeStateObj}
									onHandleChangeNumberPassengers={
										onHandleChangeNumberPassengers
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={4}
								className={classes.selectCustom}
							>
								<CustomSelect
									name="adults70"
									text="Mayores 70"
									defaultSelect
									data={passengers}
									onHandleChange={onHandleChangeStateObj}
									onHandleChangeNumberPassengers={
										onHandleChangeNumberPassengers
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</>
			);
		}

		if (quote === 'health') {
			return (
				<Grid
					item
					xs={12}
					className="animate__animated animate__fadeIn inputCustom"
				>
					<InputTextField
						name="health"
						text="Placa vehicular"
						value={health}
						onHandleChange={onHandleChangeState}
					/>
				</Grid>
			);
		}
	};

	return (
		<Grid item xs={12} sm={12} md={6}>
			<CardHome
				icon={AccountCircleIcon}
				// title="Cotizar"
				// text="Compara tus planes en distintos ramos, de inmediato."
			>
				{loadingCountries ? (
					<SimpleLoading />
				) : (
					<>
						<ButtonGroup
							variant="outlined"
							color="secondary"
							className="containerButtons animate__animated animate__fadeIn"
						>
							<Button
								id="cars"
								className={`${classes.home__buttonGroup} ${classes.home__buttonGroup_active}`}
								onClick={handleClick}
								// disabled
							>
								<DriveEtaIcon />
								Vehículos
							</Button>

							<Button
								id="travel"
								className={`${classes.home__buttonGroup}`}
								onClick={handleClick}
							>
								<FlightTakeoffIcon />
								Viajes
							</Button>

							{/* <Button
								id="health"
								className={classes.home__buttonGroup}
								onClick={handleClick}
								disabled
							>
								<FavoriteIcon />
								Salud
							</Button> */}
						</ButtonGroup>

						<Box marginTop={3} className="animate__animated animate__fadeIn">
							<form onSubmit={onHandleSubmit}>
								<Grid container spacing={2}>
									{showInputs()}

									<Grid item xs={12} className={classes.selectCustom__wpp}>
										<InputTextField
											name="wpp"
											text="Celular"
											placeholder="Ej. 0923489776"
											value={wpp}
											onHandleChange={onHandleChangeState}
											limit={10}
											textHelpter={
												<>
													<WhatsAppIcon
														fontSize="small"
														style={{ marginRight: 5 }}
													/>{' '}
													Recibe tu cotización en WhatsApp. Habilitado sólo para
													Ecuador
												</>
											}
										/>
									</Grid>

									<Grid item xs={12}>
										<MainButton
											size
											text="Continuar"
											loading={loadingVehicle}
										/>
									</Grid>
								</Grid>
							</form>
						</Box>
					</>
				)}
			</CardHome>
		</Grid>
	);
};

export const ContentHomeContact = () => {
	const classes = useStyles();

	return (
		<Grid item xs={12} sm={12} md={6} className={classes.gridInfo}>
			<CardHome
				icon={AccountCircleIcon}
				title="Listos para ayudarte"
				text="Gilbert & Boloña empresa con 30 años de experiencia en el mercado de los seguros. Nuestro servicio es asesorarte adaptándonos a tus necesidades para cuidar de tu familia, patrimonio, empresa y salud con el seguro más conveniente."
			>
				<Box
					paddingBottom={3}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
				>
					<Box
						className={classes.containerButtonCall}
						display="flex"
						alignItems="center"
					>
						<PhoneButton number="04-463-3383" />

						<Hidden only={['md', 'lg', 'xl']}>
							<Box marginX={2}>
								<Typography variant="h6" style={{ color: 'white' }}>
									O
								</Typography>
							</Box>
							<WppButton text="WhatsApp" number="+593985689332" />
						</Hidden>
					</Box>

					<Hidden only={['xs', 'sm']}>
						<Box marginY={2}>
							<Typography variant="h6" style={{ color: 'white' }}>
								O escríbenos a WhatsApp
							</Typography>
						</Box>

						<WppButton text="WhatsApp" number="+593985689332" />
					</Hidden>
				</Box>
			</CardHome>
		</Grid>
	);
};
