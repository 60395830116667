import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

export const LottieAnimation = () => {
	return (
		<>
			<Player
				autoplay
				loop
				src="https://assets2.lottiefiles.com/private_files/lf30_uyye9ch7.json"
				style={{ height: "300px", width: "300px" }}
			/>
		</>
	);
};
