import { memo } from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useQuote } from "../hooks/useQuote";

import { MainContainer } from "../components/containers/MainContainer";
import { TitleMain, TitleWithLineBreak } from "../components/ui/Titles";
import { CustomIconButton, MainButton } from "../components/ui/Buttons";

import { InfoVehicle } from "../components/containers/InfoVehicle";
import { FormVehicle } from "../components/containers/FormVehicle";
import { InfoClient } from "../components/containers/InfoClient";

const useStyles = makeStyles((theme) => ({
	quote: {
		height: "100%",

		"& .mainContainer": {
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
	},
	quote__card: {
		maxWidth: "500px",
		width: "100%",
		borderRadius: "7px",
		margin: "auto",
		backgroundColor: theme.palette.common.white,
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",

		"& .mainTitle": {
			paddingTop: "0 !important",
			textTransform: "capitalize",
		},
		"& .mainButton": {
			margin: "auto",
			display: "flex",
		},

		[theme.breakpoints.down(600)]: {
			padding: "10px 0px !important",
			maxWidth: "100%",
			marginBottom: 15,
		},
	},
	quote__form: {
		[theme.breakpoints.down(600)]: {
			padding: "0 20px",
		},
	},
}));

export const Quote = memo(() => {
	const classes = useStyles();

	// custom hook
	const {
		loading,
		brands,
		models,
		data,
		state,
		identification,
		email,
		phone,
		plate,
		registration,
		surnames,
		showsurnames,
		showrange,
		handleChangeState,
		handleChangeSurnames,
		handleChangeStateObj,
		handleVerify,
		handleBlur,
		handleBlurSurnames,
		handleSubmit,
		setMovedSlider,
	} = useQuote();

	const showData = () => {
		if (!registration) {
			return (
				<InfoVehicle
					state={state}
					plate={plate}
					showrange={showrange}
					onHandleChangeStateObj={handleChangeStateObj}
					onSetMovedSlider={() => setMovedSlider(true)}
				/>
			);
		} else {
			return (
				<FormVehicle
					data={data}
					brands={brands}
					models={models}
					state={state}
					onHandleVerify={handleVerify}
					onHandleChangeState={handleChangeState}
					onHandleChangeStateObj={handleChangeStateObj}
				/>
			);
		}
	};

	const showUser = () => {
		if (Object.keys(data).length > 0) {
			return (
				<InfoClient
					data={data}
					state={state}
					surnames={surnames}
					showsurnames={showsurnames}
					onHandleChangeState={handleChangeState}
					onHandleBlur={handleBlur}
					onHandleChangeSurnames={handleChangeSurnames}
					onHandleBlurSurnames={handleBlurSurnames}
					onHandleChangeStateObj={handleChangeStateObj}
				/>
			);
		}
	};

	return (
		<Box className={classes.quote}>
			<MainContainer>
				<Grid container justify="center">
					<Grid item xs={12} sm={12} md={6}>
						<Box className={classes.quote__card} paddingX={5} paddingY={2}>
							<TitleMain variant="h6" text="Tu vehículo" color grid />

							{showData()}

							{registration && !loading ? (
								<Box marginTop={3} display="flex" justifyContent="center">
									<CustomIconButton
										icon={ExpandMoreIcon}
										onHandleClick={handleVerify}
									/>
								</Box>
							) : (
								<Box
									marginTop={3}
									className="animate__animated animate__fadeIn"
								>
									<form onSubmit={handleSubmit}>
										<Grid container spacing={3} className={classes.quote__form}>
											{showUser()}

											<Grid item xs={12} sm={6} md={6}>
												<TitleWithLineBreak
													title="Cédula"
													text={identification}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={6}>
												<TitleWithLineBreak title="Email" text={email} />
											</Grid>
											<Grid item xs={12} sm={6} md={6}>
												<TitleWithLineBreak title="Celular" text={phone} />
											</Grid>

											<Grid item xs={12} style={{ marginTop: 15 }}>
												<MainButton text="Continuar" loading={loading} />
											</Grid>
										</Grid>
									</form>
								</Box>
							)}
						</Box>
					</Grid>
				</Grid>
			</MainContainer>
		</Box>
	);
});
