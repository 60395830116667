import { createContext, useReducer } from 'react';

import { SET_ALERT } from './../../types';
import { initialState, AlertReducer } from './AlertReducer';

export const alertContext = createContext();

const AlertProvider = ({ children }) => {

  const [state, dispatch] = useReducer(AlertReducer, initialState);

  const handleSetAlert = (data) => {
    dispatch({
      type: SET_ALERT,
      payload: data
    })
  }

  return (
    <alertContext.Provider value={{
      type: state.type,
      message: state.message,
      handleSetAlert
    }}>
      {children}
    </alertContext.Provider>
  )
}

export default AlertProvider;