import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

import { SimpleSelect } from "../ui/Inputs";

const useStyles = makeStyles((theme) => ({
	products__title_one: {
		display: "flex",
		alignItems: "center",

		[theme.breakpoints.down(600)]: {
			fontSize: 15,
		},
	},
	products__title_two: {
		display: "flex",
		alignItems: "center",
		textTransform: "uppercase",

		[theme.breakpoints.down(600)]: {
			fontSize: 15,
		},
	},
	products__containerInfo: {
		[theme.breakpoints.down(600)]: {
			marginBottom: 25,
		},
	},
	products__containerOrderBy: {
		display: "flex",
		justifyContent: "space-between !important",
		alignItems: "center",

		[theme.breakpoints.down(600)]: {
			flexDirection: "column",

			"& h6": {
				margin: "15px 0",

				// "&:last-child": {
				// 	margin: 0,
				// },
			},
		},
	},
	products__containerOrderByTwo: {
		[theme.breakpoints.down(600)]: {
			marginTop: 20,
		},
	},
	products__orderby: {
		width: "20%",
		backgroundColor: theme.palette.common.white,

		[theme.breakpoints.down(800)]: {
			marginTop: 14,
			width: "40%",
			margin: "auto",
		},
		[theme.breakpoints.down(600)]: {
			width: "60%",
			margin: 0,
			marginLeft: "auto",
		},
	},
}));

export const FilterResults = React.memo(
	({ data, orderby, items, email, onHandleChangeOrder }) => {
		const classes = useStyles();

		return (
			<Box
				className={
					data.hasOwnProperty("vehicleInfo")
						? classes.products__containerOrderBy
						: classes.products__containerOrderByTwo
				}
				display="flex"
				justifyContent="flex-end"
				alignItems="center"
				py={2}
				px={2}
			>
				{data.hasOwnProperty("vehicleInfo") && (
					<Box className={classes.products__containerInfo}>
						<Typography
							variant="h6"
							color="secondary"
							className={classes.products__title_one}
						>
							<PermContactCalendarIcon
								fontSize="small"
								color="secondary"
								style={{ marginRight: 5 }}
							/>
							{data.clientInfo?.dniNumber + " - "}
							{data.clientInfo?.email ? data.clientInfo?.email : email}
						</Typography>

						<Typography
							variant="h6"
							color="secondary"
							className={classes.products__title_two}
						>
							<DriveEtaIcon
								fontSize="small"
								color="secondary"
								style={{ marginRight: 5 }}
							/>
							{data.vehicleInfo?.plate +
								" - " +
								data.vehicleInfo?.brand +
								" " +
								data.vehicleInfo?.model +
								" " +
								data.vehicleInfo?.year}
						</Typography>
					</Box>
				)}

				<Box className={classes.products__orderby}>
					<SimpleSelect
						name="order"
						text="Ordernar precio por"
						value={orderby}
						items={items}
						onHandleChange={onHandleChangeOrder}
					/>
				</Box>
			</Box>
		);
	}
);
