import { useState } from "react";
import { makeStyles, Box, Link, Button, Hidden } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

import LogoSinTrans from "./../../assets/img/GBLogo.svg";
import Logo from "./../../assets/img/logo_trans.png";

const useStyles = makeStyles((theme) => ({
	header: {
		padding: "0 20px",
		"& img": {
			height: "8vh",

			[theme.breakpoints.down(600)]: {
				height: "11vh",
			},
		},
	},
	header__btn: {
		display: ({ pathname }) => (pathname === "/glossary" ? "none" : "flex"),
	},
}));

export const Header = () => {
	const { pathname } = useLocation();

	const classes = useStyles({ pathname });

	const [url] = useState(window.location.origin);

	const history = useHistory();

	return (
		<header className={classes.header}>
			<Box
				position="relative"
				display="flex"
				justifyContent="center"
				alignItems="center"
				paddingTop={pathname !== "/" ? 2 : 0}
				paddingBottom={2}
			>
				<Box display="flex">
					<Link href={url}>
						{pathname !== "/" ? (
							<img src={LogoSinTrans} alt="main logo" />
						) : (
							<img src={Logo} alt="main logo" />
						)}
					</Link>
				</Box>
				{pathname !== "/share" && (
					<Hidden only={["xs", "sm"]}>
						<Box display="flex" position="absolute" right="0">
							<Button
								className={classes.header__btn}
								variant="contained"
								color="secondary"
								onClick={() => history.push("/glossary")}
							>
								Glosario
							</Button>
						</Box>
					</Hidden>
				)}
			</Box>
		</header>
	);
};
