import React from "react";
import { Container } from "@material-ui/core";

export const MainContainer = ({ children }) => {
	return (
		<Container className="mainContainer" maxWidth="lg">
			{children}
		</Container>
	);
};
