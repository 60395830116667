import { createContext, useReducer } from 'react';

import { SET_USER } from './../../types';
import { initialState, UserReducer } from './UserReducer';

export const userContext = createContext();

const UserProvider = ({ children }) => {
	const [state, dispatch] = useReducer(UserReducer, initialState);

	const handleSetInfoUser = (data) => {
		// console.log("data user", data);

		dispatch({
			type: SET_USER,
			payload: { ...data },
		});
	};

	return (
		<userContext.Provider
			value={{
				identification: state.identification,
				email: state.email,
				plate: state.plate,
				phone: state.phone,
				name: state.name,
				lastname1: state.lastname1,
				lastname2: state.lastname2,
				genre: state.genre,
				civilStatus: state.civilStatus,
				birthdate: state.birthdate,
				handleSetInfoUser,
			}}
		>
			{children}
		</userContext.Provider>
	);
};

export default UserProvider;
