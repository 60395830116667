import { memo, useCallback } from "react";
import {
	makeStyles,
	Box,
	Typography,
	Link,
	Container,
	Button,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Paper,
} from "@material-ui/core";
import {
	FacebookMessengerShareButton,
	FacebookMessengerIcon,
	WhatsappShareButton,
	WhatsappIcon,
	EmailShareButton,
	EmailIcon,
} from "react-share";
import PaymentIcon from "@material-ui/icons/Payment";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Title, TitleWithValue } from "./Titles";
import { CustomParagraph } from "./Paragraphs";
import { InputDate, InputTextField } from "./Inputs";

const useStyles = makeStyles((theme) => ({
	cardHome: {
		maxWidth: "364px",
		width: "100%",
		margin: "auto",

		[theme.breakpoints.down(900)]: {
			maxWidth: "564px !important",
		},

		[theme.breakpoints.down(700)]: {
			padding: 0,
			maxWidth: "380px !important",
		},
	},
	cardWithIcon: {
		display: "block",
		width: "100%",
		height: "100%",
		position: "relative",
		overflow: "hidden",
		borderRadius: "20px",
		boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

		"&:hover #titleCard": {
			transform: "translateY(100%)",
		},
		"&:hover #containerContent": {
			transform: "none",
		},
	},
	cardWithIcon__containerImg: {
		height: "100%",
		width: "100%",

		"& img": {
			height: "100%",
			width: "100%",
			objectFit: "cover",
		},
	},
	cardWithIcon__containerTitle: {
		bottom: "0",
		position: "absolute",
		backgroundColor: "#9f0844b8",
		color: "white",
		fontSize: "1.5rem",
		padding: "10px",
		marginLeft: "auto",
		marginRight: "auto",
		left: "50px",
		right: "50px",
		textAlign: "center",
		transition: "transform 0.2s ease-out",

		"& svg": {
			marginRight: 7,
		},
		"& span": {
			fontWeight: 600,

			[theme.breakpoints.down(600)]: {
				fontSize: "1rem",
			},
		},
	},
	cardWithIcon__containerContent: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transition: "all .2s ease-in",
		transform: "translateY(-100%)",
		background: "rgba(45, 53, 89, 0.85)",
		width: "100%",
		height: "100%",
	},
	cardWithIcon__title: {
		color: "white",

		"& svg": {
			fontSize: "2rem",
			marginRight: 7,
		},
		"& span": {
			fontSize: "1.6rem",
			fontWeight: 600,
		},
	},
	cardWithIcon__description: {
		color: "white",
		fontWeight: 400,
		margin: "15px 0",
	},
	cardWithIcon__containerButton: {
		color: "white",
		display: "flex",
		alignItems: "center",
		width: "122px",
		justifyContent: "space-between",
		backgroundColor: "#a03044",
		height: "37px",
		borderRadius: 5,
		transition: "all .3s ease-in",

		"& span:first-child": {
			width: "100%",
			padding: "0 6px",
		},
		"& span:last-child": {
			backgroundColor: "#21294e",
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			padding: "0 6px",
		},

		"&:hover": {
			cursor: "pointer",
			textDecoration: "none",
			backgroundColor: "#1f1f1f",
		},
		"&:hover span:last-child": {
			backgroundColor: "#1f1f1f !important",
		},
	},
	cardShare: {
		maxWidth: "350px",
		height: "fit-content",
		width: "100%",
		borderRadius: 10,
		boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
		backgroundColor: theme.palette.common.white,
		// boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",

		[theme.breakpoints.down(700)]: {
			maxHeight: "fit-content",
			height: "fit-content",
			margin: "auto",
		},
	},
	cardShare__content: {
		height: "100%",
		borderBottom: "1px solid #e8d4d4",
	},
	cardShare__separator: {
		backgroundColor: theme.palette.secondary.light,
		borderRadius: 7,

		"& h6": {
			margin: 0,
			fontSize: "1.1rem",
		},
	},
	cardShare__total: {
		"& h6": {
			color: theme.palette.secondary.main,
			margin: 0,
		},
	},
	cardShare__containerTitlePay: {
		"& p": {
			margin: 0,
		},
	},
	cardShare__containerImg: {
		maxHeight: ({ travel }) => (travel ? "80px" : "60px"),
		maxWidth: "130px",
		height: "100%",
		width: "100%",

		"& img": {
			height: ({ travel }) => (travel ? "60%" : "100%"),
			width: ({ travel }) => (travel ? null : "100%"),
			objectFit: "contain",
		},
	},
	cardShare__taxTitle: {
		"& h6": {
			color: theme.palette.secondary.main,
			fontSize: "1rem",
		},
	},
	cardShare__description: {
		fontSize: 16,
		color: theme.palette.grey[800],
		fontWeight: 500,
	},
	older: {
		backgroundColor: theme.palette.secondary.dark,
		color: "white",
		borderRadius: "20px",
		padding: "5px 10px",
		maxHeight: "30px",
		height: "100%",
	},
	passenger: {
		width: "100%",
		borderRadius: 10,
		marginBottom: 7,
		display: "flex",
		justifyContent: "space-between",

		"&:last-child": {
			marginBottom: 0,
		},
	},
	passenger__icon: {
		padding: "0 15px 0 0",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	cardShare__atravelIcon: {
		maxWidth: "100px",
		width: "100%",
		textAlign: "center",
	},
	cardShare__accordeon: {
		boxShadow: "none !important",
		border: "none !important",

		"& .MuiAccordionSummary-root.Mui-expanded": {
			minHeight: "50px !important",
		},

		"&:before": {
			content: "none",
		},
	},
	cardShare__linkUrl: {
		marginLeft: 15,
		textDecoration: "none",

		"&:hover": {
			textDecoration: "none",
		},
	},
	cardGlossary: {
		minHeight: 270,
		height: "100%",

		"&:hover": {
			cursor: "pointer",
			"& .cardHover": {
				color: theme.palette.common.white,
				backgroundColor: theme.palette.secondary.main,
			},
		},
	},
	cardGlossary__icon: {
		top: "-22px",
		left: "44%",
		color: theme.palette.secondary.main,
		borderRadius: "50%",
		height: "50px",
		width: "50px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		border: `1px solid ${theme.palette.secondary.main}`,
		transition: "all .3s ease-in",
	},
	cardGlossary__content: {
		height: "100%",
		padding: "10px 48px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center",
	},
	cardGlossary__title: {
		fontWeight: 600,
		fontSize: "1.2rem",
		color: "#676767",
		marginBottom: 2,
	},
	cardGlossary__text: {
		color: "#777777",
	},
}));

export const CardHome = ({ icon: Icon, title, text, children }) => {
	const classes = useStyles();

	return (
		<Box
			className={classes.cardHome}
			height="100%"
			display="flex"
			flexDirection="column"
			paddingX={4}
		>
			<Box display="flex" flexDirection="column">
				{/* <Box textAlign="center">
          <Icon color="secondary" fontSize="large" />
        </Box> */}
				{title && (
					<Box marginBottom={2} textAlign="center">
						<Title variant="h5" color="white" text={title} />
					</Box>
				)}
				{text && (
					<Box textAlign="center" marginBottom={2}>
						<CustomParagraph text={text} color />
					</Box>
				)}
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				{children}
			</Box>
		</Box>
	);
};

export const CardWithIcon = ({ img, icon: Icon, title, text, url }) => {
	const classes = useStyles();

	return (
		<Box className={classes.cardWithIcon}>
			<div className={classes.cardWithIcon__containerImg}>
				<img src={img} alt="icon" loading="lazy" />
			</div>

			<Box
				className={classes.cardWithIcon__containerTitle}
				display="flex"
				alignItems="center"
				justifyContent="center"
				id="titleCard"
			>
				<Icon /> <span>{title}</span>
			</Box>
			<Box
				className={classes.cardWithIcon__containerContent}
				id="containerContent"
			>
				<Box paddingY={3} paddingX={5} display="flex" flexDirection="column">
					<Box
						className={classes.cardWithIcon__title}
						display="flex"
						alignItems="center"
					>
						<Icon /> <span>{title}</span>
					</Box>
					<Typography
						className={classes.cardWithIcon__description}
						variant="body1"
					>
						{text}
					</Typography>
					{/* <Link
						className={classes.cardWithIcon__containerButton}
						href={url}
						target="blank"
					>
						<span>Ver más</span>
						<span>
							<ArrowForwardIosIcon />
						</span>
					</Link> */}
				</Box>
			</Box>
		</Box>
	);
};

export const CardShare = memo((props) => {
	const {
		product,
		priceProduct,
		travel,
		urlShare,
		selectedPlan,
		expanded,
		accordeon,
		shared,
		fileUrlPdf,
		majorPassenger,
		onHandleChangeAccordeon,
		onGetNameCountries,
		onHandlePurchasePlan,
	} = props;

	const classes = useStyles({ travel });

	const getMajorPassenger = useCallback(() => {
		if (!majorPassenger) {
			const major = product.customers.find(
				(item) => item.lastname === "MAYOR 70"
			);

			if (major) {
				return (
					<Box display="flex" alignItems="center" marginTop={1}>
						<Box
							className={classes.older}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
						>
							<span>+70</span>
						</Box>
					</Box>
				);
			}
		}
		// eslint-disable-next-line
	}, []);

	if (travel)
		return (
			<Box className={classes.cardShare} display="flex" flexDirection="column">
				<Box
					className={classes.cardShare__content}
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					padding={2}
				>
					<Box display="flex" justifyContent="space-between">
						<Box>
							<Box className={classes.cardShare__containerImg}>
								<img src={product.logo} alt="asd" />
							</Box>
						</Box>

						{!shared && (
							<Box>
								<FacebookMessengerShareButton
									url={`${encodeURI(
										`${window.location.origin}/share?${urlShare}`
									)}`}
								>
									<FacebookMessengerIcon size={25} round />
								</FacebookMessengerShareButton>

								<WhatsappShareButton
									url={`${encodeURI(
										`${window.location.origin}/share?${urlShare}`
									)}`}
									style={{ margin: "0 5px" }}
								>
									<WhatsappIcon size={25} round />
								</WhatsappShareButton>

								<EmailShareButton
									url={`${encodeURI(
										`${window.location.origin}/share?${urlShare}`
									)}`}
								>
									<EmailIcon size={25} round />
								</EmailShareButton>
							</Box>
						)}
					</Box>

					<Container maxWidth="md" style={{ padding: "0 10px" }}>
						<Box display="flex" justifyContent="space-between">
							<Box
								className={classes.cardShare__atravelIcon}
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<FlightTakeoffIcon color="secondary" />
								{onGetNameCountries(product.departure, "origin")}
							</Box>
							<Box
								className={classes.cardShare__atravelIcon}
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<FlightLandIcon color="secondary" />
								{onGetNameCountries(product.arrival, "destination")}
							</Box>
							<Box
								className={classes.cardShare__atravelIcon}
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<DateRangeIcon color="secondary" />
								{product.coverageDays} Días
							</Box>
						</Box>

						<Box display="flex" marginTop={2}>
							<Box>
								<TitleWithValue title="Producto" value={product.product} />
								{/* <TitleWithValue title="Tasa" value={product.productRate} /> */}
								{getMajorPassenger()}
							</Box>
						</Box>

						<Box paddingY={2}>
							<Typography className={classes.cardShare__description}>
								{product.rateDescription}
							</Typography>
						</Box>

						{!selectedPlan && (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								textAlign="center"
							>
								<Button
									variant="outlined"
									color="secondary"
									size="small"
									onClick={() =>
										shared
											? console.log("aaa share selec product")
											: onHandlePurchasePlan(product)
									}
								>
									Adquirir plan
								</Button>

								<Link
									href={`${shared ? fileUrlPdf : product.fileUrl}`}
									target="blank"
									download
									style={{
										marginLeft: 15,
										textDecoration: "none !important",
									}}
								>
									<Button variant="outlined" color="secondary" size="small">
										Coberturas
									</Button>
								</Link>
							</Box>
						)}

						<Box marginTop={2}>
							{/* <TitleWithValue
								title="Sub Total"
								value={`$ ${product.subtotal}`}
							/> */}
							<Box
								className={classes.cardShare__separator}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								paddingX={2}
								paddingY={1}
							>
								<Title text="Total" variant="subtitle1" color="white" />
								<Title
									text={`$ ${product.total}`}
									variant="subtitle1"
									color="white"
								/>
							</Box>
						</Box>
					</Container>
				</Box>
			</Box>
		);

	return (
		<Box className={classes.cardShare} display="flex" flexDirection="column">
			<Box
				className={classes.cardShare__content}
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				padding={2}
			>
				<Box display="flex" justifyContent="space-between">
					<Box style={{ textTransform: "capitalize" }}>
						<Box className={classes.cardShare__containerImg}>
							<img src={product.logo} alt="asd" />
						</Box>
						{/* {product.source} */}
					</Box>

					{!shared && (
						<Box>
							<FacebookMessengerShareButton
								url={`${encodeURI(
									`${window.location.origin}/share?${urlShare}`
								)}`}
							>
								<FacebookMessengerIcon size={25} round />
							</FacebookMessengerShareButton>

							<WhatsappShareButton
								url={`${encodeURI(
									`${window.location.origin}/share?${urlShare}`
								)}`}
								style={{ margin: "0 5px" }}
							>
								<WhatsappIcon size={25} round />
							</WhatsappShareButton>

							<EmailShareButton
								url={`${encodeURI(
									`${window.location.origin}/share?${urlShare}`
								)}`}
							>
								<EmailIcon size={25} round />
							</EmailShareButton>
						</Box>
					)}
				</Box>

				<Box>
					<Title text={product.productName} variant="subtitle1" />
				</Box>

				<Box>
					<TitleWithValue title="Valor comercial" value={`$ ${priceProduct}`} />
					<TitleWithValue
						title="Tasa"
						value={`${product.netPrimeRateLabel.trim().replace("%", "")} %`}
					/>
					<TitleWithValue title="Prima neta" value={`$ ${product.netPrime}`} />

					<Accordion
						expanded={expanded === accordeon}
						onChange={onHandleChangeAccordeon(accordeon)}
						className={classes.cardShare__accordeon}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							style={{ padding: 0, margin: 0, height: "50px" }}
						>
							<Box className={classes.cardShare__taxTitle} textAlign="left">
								<Title text="Detalle de impuestos" variant="h6" />
							</Box>
						</AccordionSummary>

						<AccordionDetails style={{ padding: 0 }}>
							<Box display="flex" flexDirection="column">
								<TitleWithValue
									title="Derecho de emisión"
									value={`$ ${product.policyFee}`}
								/>
								<TitleWithValue
									title="Seguro Campesino"
									value={`$ ${product.impSeguroCampesino}`}
								/>
								<TitleWithValue
									title="Sup. de Bancos"
									value={`$ ${product.impSuperBancos}`}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>

					<TitleWithValue title="I.V.A. (12%)" value={`$ ${product.tax}`} />

					<Box
						className={classes.cardShare__separator}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						marginTop={2}
						paddingX={2}
						paddingY={1}
					>
						<Title
							text="Prima total (anual)"
							variant="subtitle1"
							color="white"
						/>
						<Title
							text={`$ ${product.totalPrime}`}
							variant="subtitle1"
							color="white"
						/>
					</Box>
				</Box>
			</Box>
			<Box className={classes.cardShare__footer} padding={2}>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					marginBottom={1}
					className={classes.cardShare__containerTitlePay}
				>
					<PaymentIcon style={{ marginRight: 5 }} />
					<Title text="Formas de pago" />
				</Box>

				<TitleWithValue
					title="6 pagos sin interés"
					value={`$ ${(product.totalPrime / 6).toFixed(2)}`}
				/>
				<TitleWithValue
					title="12 pagos sin interés"
					value={`$ ${(product.totalPrime / 12).toFixed(2)}`}
				/>
				<TitleWithValue
					title="10 débitos a cuenta bancaria"
					value={`$ ${(product.totalPrime / 10).toFixed(2)}`}
				/>
			</Box>

			<Box display="flex" justifyContent="center" py={2}>
				<Button
					variant="outlined"
					color="secondary"
					size="small"
					onClick={() =>
						shared
							? console.log("aaa share selec product")
							: onHandlePurchasePlan(product)
					}
				>
					Adquirir plan
				</Button>
			</Box>
		</Box>
	);
});

export const CardPassenger = memo(({ count, passengers }) => {
	const classes = useStyles();

	return (
		<Box className={classes.passenger}>
			<Container maxWidth="lg" style={{ height: "fit-content" }}>
				<Grid container spacing={2}>
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={4}
						style={{ height: "fit-content !important" }}
					>
						<InputTextField
							name={`passenger${count}`}
							text="Nombres"
							onHandleChange={() => console.log("change state")}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={4}
						style={{ height: "fit-content !important" }}
					>
						<InputTextField
							name={`passenger${count}`}
							text="Primer apellido"
							onHandleChange={() => console.log("change state")}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={4}
						style={{ height: "fit-content !important" }}
					>
						<InputTextField
							name={`passenger${count}`}
							text="Segundo apellido"
							onHandleChange={() => console.log("change state")}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						style={{ height: "fit-content !important" }}
					>
						<InputDate
							name="birth_date"
							text="Fecha de nacimiento"
							value={new Date()}
							onHandleChange={() => console.log("aa")}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						style={{ height: "fit-content !important" }}
					>
						<InputTextField
							name={`passenger${count}`}
							text="Numero de cedula"
							onHandleChange={() => console.log("change state")}
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
});

export const CardGlossary = memo(({ icon: Icon, title, text }) => {
	const classes = useStyles();

	return (
		<Paper elevation={3} square style={{ height: "100%" }}>
			<Box position="relative" className={classes.cardGlossary}>
				<Box
					position="absolute"
					className={`cardHover ${classes.cardGlossary__icon}`}
				>
					<Icon />
				</Box>
				<Box className={classes.cardGlossary__content}>
					<Typography className={classes.cardGlossary__title}>
						{title}
					</Typography>
					<Typography className={classes.cardGlossary__text}>{text}</Typography>
				</Box>
			</Box>
		</Paper>
	);
});
