import { formatDate } from "./date";

export const validationString = (str) =>
	new Promise((resolve, reject) => {
		const expReg = /[<>?!#$&"']+/;

		if (str !== "" && str.trim() !== "" && str.length > 0) {
			if (!expReg.exec(str)) {
				resolve(str);
			} else {
				reject({
					message: "No se permiten caracteres especiales",
				});
			}
		} else {
			reject({
				message: "Todos los campos son obligatorios",
			});
		}
	});

export const validationDate = (date) =>
	new Promise((resolve, reject) => {
		console.log("date", date);
		if (date !== null) {
			resolve(formatDate(date));
		} else {
			reject({
				message: "La fecha es requerida",
			});
		}
	});

export const validateQuantity = (str) => {
	let strSanitized = str;

	if (str && str.length > 16) {
		strSanitized = str.substring(0, 16) + "...";
	}

	return strSanitized;
};

export const validatePhoneNumber = (phone) =>
	new Promise((resolve, reject) => {
		if (phone.length >= 10) {
			const first_position = phone.charAt(0);
			// console.log("first position", first_position);

			if (first_position === "0") {
				// console.log("phone", phone);
				// console.log("phone saneade", phone.substring(1));
				// console.log("number", "593" + phone.substring(1));
				return resolve("593" + phone.substring(1));
			} else {
				// console.log("number", "593" + phone);
				return resolve("593" + phone);
			}
		} else {
			reject({
				message: "Numero de celular invalido",
			});
		}
	});

export const numberFormat = (str) => {
	var formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	return formatter.format(Number(str));
};

export const EsCedulaValida = (Cedula) =>
	new Promise((resolve, reject) => {
		var NumDigitos = Cedula.length;
		if (NumDigitos === 10) {
			if (EsDigito(Cedula)) {
				var strprov = Cedula.substr(0, 2);
				var strdigv = Cedula.substr(9, 1);
				var prov = parseInt(strprov, 10);
				var digv = parseInt(strdigv, 10);
				if (prov >= 1 && prov <= 24) {
					var digito = getDigitoVerificador(Cedula);
					resolve(digv === digito);
					// Validar = digv == digito;
				} else reject({ message: "la cédula no es valida " });
			} else reject({ message: "la cédula no es valida " });
		} // El Numero de Digitos no es 10
		else reject({ message: "la cédula debe tener 10 digitos" });
	});

function EsDigito(Numero) {
	var Validar = true;
	var NumDigitos = Numero.length;
	for (var i = 0; i < NumDigitos; i++) {
		var c = Numero[i];
		var EsDig = c >= "0" && c <= "9";
		if (!EsDig) {
			Validar = false;
			break;
		}
	}
	return Validar;
}

function getDigitoVerificador(Cedula) {
	var Total = 0;
	for (var i = 0; i < 9; i++) {
		var dig = parseInt(Cedula[i], 10);
		if (i % 2 === 0) {
			// El numero impar se multiplica por 2 pero por el indice base 0 1 es 0, 2 es 1, etc
			var Multiplicacion = dig * 2;
			if (Multiplicacion > 9) Multiplicacion -= 9;
			Total += Multiplicacion;
		} else Total += dig;
	}
	var Resultado = 0;
	if (Total > 0 && Total <= 10) Resultado = 10 - Total;
	if (Total > 10 && Total <= 20) Resultado = 20 - Total;
	if (Total > 20 && Total <= 30) Resultado = 30 - Total;
	if (Total > 30 && Total <= 40) Resultado = 40 - Total;
	if (Total > 40 && Total <= 50) Resultado = 50 - Total;
	if (Total > 50 && Total <= 60) Resultado = 60 - Total;
	if (Total > 60 && Total <= 70) Resultado = 70 - Total;
	if (Total > 70 && Total <= 80) Resultado = 80 - Total;
	if (Total > 80 && Total <= 90) Resultado = 90 - Total;
	if (Total > 90 && Total <= 100) Resultado = 100 - Total;
	if (Total > 100 && Total <= 110) Resultado = 110 - Total;
	if (Total > 110 && Total <= 120) Resultado = 120 - Total;
	if (Resultado === 10) Resultado -= 10;
	return Resultado;
}
