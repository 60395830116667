import { useState, useEffect } from 'react';
import {
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	FormControlLabel,
	Checkbox,
	InputAdornment,
	Select,
	makeStyles,
} from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
	DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';

const useStyles = makeStyles((theme) => ({
	inputTextFied: {
		'& .MuiFormHelperText-root': {
			color: '#c5c4c4',
			marginLeft: 0,
			display: 'flex',
			marginTop: 10,
		},
		'& input': {
			textTransform: ({ uppercase }) => (uppercase ? 'uppercase' : null),
		},
	},
}));

export const InputTextField = (props) => {
	const {
		type = 'text',
		name,
		text,
		value,
		onHandleChange,
		onHandleBlur,
		placeholder,
		disabled,
		iconDecoration,
		icon: Icon,
		limit,
		textHelpter,
		uppercase,
	} = props;

	const classes = useStyles({ uppercase });

	if (iconDecoration) {
		return (
			<>
				<TextField
					type={type}
					variant="outlined"
					className="animate__animated animate__fadeIn"
					size="small"
					fullWidth
					label={text}
					name={name}
					disabled={disabled ? true : false}
					id={name}
					onChange={onHandleChange}
					value={value}
					placeholder={placeholder ? placeholder : ''}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Icon />
							</InputAdornment>
						),
						inputProps: { min: 0 },
					}}
				/>
			</>
		);
	}

	if (limit) {
		if (type === 'number') {
			return (
				<>
					<TextField
						type={type}
						variant="outlined"
						className={`animate__animated animate__fadeIn ${classes.inputTextFied}`}
						size="small"
						fullWidth
						label={text}
						name={name}
						disabled={disabled ? true : false}
						id={name}
						onChange={onHandleChange}
						value={value}
						placeholder={placeholder ? placeholder : ''}
						helperText={textHelpter ? textHelpter : ''}
						InputProps={{
							inputProps: { min: 0 },
						}}
						onBlur={onHandleBlur ? onHandleBlur : null}
					/>
				</>
			);
		} else {
			return (
				<>
					<TextField
						type={type}
						variant="outlined"
						className={`animate__animated animate__fadeIn ${classes.inputTextFied}`}
						size="small"
						fullWidth
						label={text}
						name={name}
						disabled={disabled ? true : false}
						id={name}
						onChange={onHandleChange}
						value={value}
						placeholder={placeholder ? placeholder : ''}
						helperText={textHelpter ? textHelpter : ''}
						inputProps={{
							maxLength: limit,
						}}
						// InputProps={{
						// 	inputProps: { min: type === "number" ? 0 : null },
						// }}
						onBlur={onHandleBlur ? onHandleBlur : null}
					/>
				</>
			);
		}
	}

	return (
		<>
			<TextField
				type={type}
				variant="outlined"
				className="animate__animated animate__fadeIn"
				size="small"
				fullWidth
				label={text}
				name={name}
				disabled={disabled ? true : false}
				id={name}
				onChange={onHandleChange}
				value={value}
				placeholder={placeholder ? placeholder : ''}
			/>
		</>
	);
};

export const InputDate = ({
	name,
	text,
	value,
	birthdate,
	birthdate2,
	travel,
	mindate,
	onHandleChange,
}) => {
	const handleChange = (date) => {
		// console.log("date", date.toLocaleDateString());

		if (birthdate) {
			var diff_ms = Date.now() - date.getTime();
			var age_dt = new Date(diff_ms);

			const age = Math.abs(age_dt.getUTCFullYear() - 1970);

			console.log('date', date);
			console.log('date2', new Date(date));
			console.log(
				'date.toLocaleDateString()',
				date.toLocaleDateString('en-GB')
			);

			onHandleChange({
				[name]: date,
				birthdateDate: date.toLocaleDateString('en-GB'),
				age,
			});
		} else {
			onHandleChange({
				[name]: date,
			});
		}
	};

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
			{mindate ? (
				<KeyboardDatePicker
					autoOk
					fullWidth
					size="small"
					name={name}
					variant="inline"
					inputVariant="outlined"
					label={text}
					minDate={mindate ? new Date() : null}
					format="dd/MM/yyyy"
					value={value}					
					InputAdornmentProps={{ position: 'start' }}
					onChange={handleChange}
				/>
			) : (
				<KeyboardDatePicker
					autoOk
					fullWidth
					size="small"
					name={name}
					variant="inline"
					inputVariant="outlined"
					label={text}
					format="dd/MM/yyyy"
					value={value}
					InputAdornmentProps={{ position: 'start' }}
					onChange={handleChange}
				/>
			)}
		</MuiPickersUtilsProvider>
	);
};

export const CheckboxCustom = ({
	name,
	checked,
	onHandleChangeCheck,
	text,
}) => {
	if (text)
		return (
			<FormControlLabel
				control={
					<Checkbox
						name={name}
						checked={checked}
						onChange={onHandleChangeCheck}
					/>
				}
				label={text}
			/>
		);

	return (
		<Checkbox
			checked={checked}
			onChange={onHandleChangeCheck}
			inputProps={{ 'aria-label': 'primary checkbox' }}
		/>
	);
};

export const CustomSelect = ({
	name,
	text,
	data,
	nameLabel,
	defaultSelect,
	onHandleChange,
	onHandleChangeNumberPassengers,
}) => {
	const [state, setState] = useState('');

	const handleChange = (e) => {
		setState(e.target.value);

		if (onHandleChangeNumberPassengers) {
			onHandleChangeNumberPassengers(name, Number(e.target.value));
		} else {
			if (onHandleChange) {
				if (nameLabel) {
					const { label } = data.find((item) => item.value === e.target.value);

					onHandleChange({
						[e.target.name]: e.target.value,
						[nameLabel]: label ? label : e.target.value,
					});
				} else {
					onHandleChange({
						[e.target.name]: e.target.value,
					});
				}
			}
		}
	};

	useEffect(() => {
		(function () {
			if (defaultSelect) {
				if (nameLabel === 'country') {
					setState(defaultSelect);
					return;
				}
				setState(data[0].value);
			}
		})();
		// eslint-disable-next-line
	}, [setState]);

	return (
		<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
			<InputLabel id={name}>{text}</InputLabel>
			<Select
				id={name}
				name={name}
				value={state}
				onChange={handleChange}
				label={text}
			>
				{data.map((item, index) => (
					<MenuItem key={index} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export const InputYear = ({ name, text, value, datebirth, onHandleChange }) => {
	const handleChange = (date) => {
		// console.log("year", date);

		const year = new Date(date).getFullYear();

		if (!datebirth) {
			onHandleChange({
				yearSanitized: year,
				[name]: date,
			});
		} else {
			onHandleChange(datebirth, {
				yearSanitized: year,
				[name]: date,
			});
		}
	};

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
			<DatePicker
				views={['year']}
				fullWidth
				size="small"
				name={name}
				variant="inline"
				inputVariant="outlined"
				openTo="year"
				label={text}
				value={value}
				onChange={handleChange}
			/>
		</MuiPickersUtilsProvider>
	);
};

export const SimpleSelect = ({ name, text, value, items, onHandleChange }) => {
	return (
		<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
			<InputLabel id={name}>{text}</InputLabel>
			<Select
				id={name}
				name={name}
				value={value}
				onChange={onHandleChange}
				label={text}
			>
				{items.map((item, index) => (
					<MenuItem key={index} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
