import { createContext, useReducer, useContext } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import {
	LOADING_COUNTRIES,
	SET_COUNTRIES,
	ERROR_COUNTRIES,
	SET_QUOTE_LOADING,
	SET_QUOTE_DATA,
	SET_QUOTE_ERROR,
	SET_QUOTE_TYPE,
	CHANGE_GLOBAL_STATE,
	SET_PRODUCT,
	SET_LOADINGPRODUCT,
	SET_NUMBERPASSENGERS,
	SET_EMPTY_STATE,
	SET_DATA_RESPONSE,
	SET_WPP_VIEW,
} from './../../types';

import { userContext } from '../user/UserProvider';
import { clientAxios } from '../../config/axios';

import { initialState, QuoteReducer } from './QuoteReducer';
// import { mookVehicle } from "../../utils/mookVehicle";

export const quoteContext = createContext();

const QuoteProvider = ({ children }) => {
	const [state, dispatch] = useReducer(QuoteReducer, initialState);

	const {
		identification,
		email,
		plate,
		phone,
		name,
		lastname1,
		lastname2,
		genre,
		civilStatus,
		birthdate,
	} = useContext(userContext);

	const handleGetCountries = async () => {
		dispatch({
			type: LOADING_COUNTRIES,
		});

		try {
			const { data } = await clientAxios.get('/quotes/travel/parameters');
			// console.log(data);

			const countries = [];

			data.forEach((item) => {
				if (item.Dial && item.Dial !== '') {
					countries.push({ label: item.Name, value: item.Dial });
				}
			});

			const payload = countries.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}
				return 0;
			});

			dispatch({
				type: SET_COUNTRIES,
				payload,
			});
		} catch (error) {
			console.log(error);
			dispatch({
				type: ERROR_COUNTRIES,
				payload: error.message,
			});
		}
	};

	const handleGetDataVehicle = async (request) => {
		dispatch({
			type: SET_QUOTE_LOADING,
		});

		try {
			const { data } = await clientAxios.post('/quotes/vehicle', {
				...request,
			});
			console.log('vehiculos', data);

			if (data.status !== 'error') {
				if (data.data.hasOwnProperty('vehicleInfo')) {
					await axios.post(
						'https://opoz9x2iva.execute-api.sa-east-1.amazonaws.com/dev/storeLead',
						data
					);
				}
			}

			dispatch({
				type: SET_QUOTE_DATA,
				payload: data.data,
			});
			dispatch({
				type: SET_DATA_RESPONSE,
				payload: data,
			});
			dispatch({
				type: SET_QUOTE_TYPE,
				payload: 'vehicle',
			});

			return 'oka';
		} catch (error) {
			console.log(error);
			dispatch({
				type: SET_QUOTE_ERROR,
				payload: error.messsage,
			});
			return Promise.reject({ message: 'Error interno, intenta de nuevo ' });
		}
	};
	const handleGetProductVehicle = async (url, movedslider) => {
		dispatch({
			type: SET_QUOTE_LOADING,
		});

		// console.log("url query string", url);

		try {
			const { data } = await clientAxios.post(encodeURI(url), {
				plate,
				dniNumber: identification,
				email,
				name,
				lastname1,
				lastname2,
				genre,
				civilStatus,
				birthdate,
				mobilePhoneNumber: phone,
			});
			// console.log("data provider", data);

			//store lead
			await axios.post(
				'https://opoz9x2iva.execute-api.sa-east-1.amazonaws.com/dev/storeLead',
				data
			);

			// other services
			if (movedslider) {
				// console.log("response query string", data);

				await axios.post(
					'https://0qih23d4ge.execute-api.us-west-2.amazonaws.com/test/sendEmail',
					data
				);
				await axios.post(
					'https://btt9nkdaul.execute-api.us-west-2.amazonaws.com/dev/sendMessage',
					data
				); 
			} else {
				 console.log("context data", state.dataResponse);

				await axios.post(
					'https://0qih23d4ge.execute-api.us-west-2.amazonaws.com/test/sendEmail',
					data
				);
				await axios.post(
					'https://btt9nkdaul.execute-api.us-west-2.amazonaws.com/dev/sendMessage',
					data
				); 
			}

			dispatch({
				type: SET_DATA_RESPONSE,
				payload: data,
			});
			dispatch({
				type: SET_QUOTE_DATA,
				payload: data.data,
			});
			return true;
		} catch (error) {
			console.log(error);
			swal('Vuelva a intentarlo', error.message, 'warning');
			dispatch({
				type: SET_QUOTE_ERROR,
				payload: error.messsage,
			});
		}
	};

	const handleGetDateTravel = async (travel, numberPassengers) => {
		dispatch({
			type: SET_QUOTE_LOADING,
		});

		try {
			console.log("travel params => ", travel);
			console.log("numberPassengers params => ", numberPassengers);
			//console.log("clientAxios => ", clientAxios.getUri())
			var data = null
			var ok = false;
			var res= null
			await clientAxios.post('/quotes/travel', travel).then(_data => {
				console.log("data response ", _data)
				data = _data
			}).catch(err => {
				console.log("Error catch 1.0  =>", err.message)	
				res ={
					status:false,
					data:err.message
				}
			});

			if(data !== null){
				console.log("viajes: =>", data.data);
				if (data.status !== 'error') {
					if(data.data.codigo === 1){
						console.log("entramos por if.....")
						res = {
							status : false,
							data: data.data.descripcion
						}
					}else{
						console.log("creamos el storeLead")
						await axios.post(
							'https://opoz9x2iva.execute-api.sa-east-1.amazonaws.com/dev/storeLead',
							data
						);

						dispatch({
							type: SET_QUOTE_DATA,
							payload: data.data,
						});
						dispatch({
							type: SET_QUOTE_TYPE,
							payload: 'travel',
						});
						dispatch({
							type: SET_NUMBERPASSENGERS,
							payload: numberPassengers,
						});
						ok = true;
						res = {
							status : ok,
							data: data
						}
						//return true;
					}				
				}			
			}
			return res;

		} catch (error) {
			console.log("error catch =>", error);
			dispatch({
				type: SET_QUOTE_ERROR,
				payload: error.messsage,
			});
			return Promise.reject({ message: 'Error interno, intenta de nuevo ' });
		}
	};

	// not glued

	const handleChangeGlobalState = (data) => {
		dispatch({
			type: CHANGE_GLOBAL_STATE,
			payload: data,
		});
	};

	const handleSetLoadingProduct = () => {
		dispatch({
			type: SET_LOADINGPRODUCT,
		});
	};

	const handleSetProduct = (product) => {
		dispatch({
			type: SET_PRODUCT,
			payload: product,
		});
	};

	const handleNameCountries = (code, type) => {
		let country = '';

		if (type === 'origin') {
			state.countries.forEach((item) => {
				if (item.value === code.toString()) {
					country = item.label;
				}
			});
		} else {
			state.departure.forEach((item) => {
				if (item.value === code.toString()) {
					country = item.label;
				}
			});
		}

		return country;
	};

	const handleResetStateProvider = () => {
		dispatch({
			type: SET_EMPTY_STATE,
		});
	};

	const handleSetOpenWpp = (status) => {
		dispatch({
			type: SET_WPP_VIEW,
			payload: status,
		});
	};

	return (
		<quoteContext.Provider
			value={{
				quoteType: state.quoteType,
				loading: state.loading,
				loadingProducts: state.loadingProducts,
				data: state.data,
				error: state.error,
				loadingCountries: state.loadingCountries,
				countries: state.countries,
				errorCountries: state.errorCountries,
				selectedProduct: state.selectedProduct,
				departure: state.departure.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				}),
				numberPassengers: state.numberPassengers,
				dataResponse: state.dataResponse,
				openWppFloat: state.openWppFloat,
				handleGetCountries,
				handleGetDataVehicle,
				handleGetProductVehicle,
				handleGetDateTravel,
				handleChangeGlobalState,
				handleSetProduct,
				handleSetLoadingProduct,
				handleNameCountries,
				handleResetStateProvider,
				handleSetOpenWpp,
			}}
		>
			{children}
		</quoteContext.Provider>
	);
};

export default QuoteProvider;
