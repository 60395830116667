import { SET_USER } from './../../types';

export const initialState = {
	identification: '0123456789',
	email: '',
	plate: '',
	phone: '',
	name: '',
	lastname1: '',
	lastname2: '',
	genre: '',
	civilStatus: '',
	birthdate: '',
};

export const UserReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case SET_USER:
			return {
				...state,
				...actions.payload,
			};

		default:
			return state;
	}
};
