import { memo, useContext } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import { quoteContext } from "../context/quote/QuoteContext";

// import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from "./PrivateRoute";

import { Layout } from "../components/containers/Layout";
import { Home } from "../pages/Home";
import { Quote } from "../pages/Quote";
import { Products } from "../pages/Products";
import { Share } from "../pages/Share";
import { QuoteTravelProduct } from "../pages/QuoteTravelProduct";
import { Glossary } from "../pages/Glossary";

export const RouterApp = memo(() => {
	const { quoteType } = useContext(quoteContext);

	return (
		<Router>
			<Layout>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/share" component={Share} />
					<Route exact path="/glossary" component={Glossary} />

					<Route exact path="/quote" component={Quote} />
					<PrivateRoute
						exact
						path="/quote/products"
						data={quoteType}
						component={Products}
					/>
					<PrivateRoute
						exact
						path="/quote/travel"
						data={quoteType}
						component={Products}
					/>
					<PrivateRoute
						exact
						path="/quote/product"
						data={quoteType}
						component={QuoteTravelProduct}
					/>
					<Redirect to="/" />
				</Switch>
			</Layout>
		</Router>
	);
});
