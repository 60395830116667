import { memo } from "react";
import { Box, Hidden, makeStyles, Button, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { Title } from "../ui/Titles";

const useStyles = makeStyles((theme) => ({
	products__btns: {
		[theme.breakpoints.down(600)]: {
			flexDirection: "column",

			"& button:first-child": {
				marginBottom: 20,
			},
		},
	},
	btnDonwload: {
		textDecoration: "none",
		color: "#fff",
		backgroundColor: theme.palette.secondary.main,
		padding: "7px 15px",
		borderRadius: "5px",

		"&:hover": {
			textDecoration: "none",
		},
	},
}));

export const ResultQuotingTitle = memo(
	({ findmajor, dataResponse, onHandleResetStateProvider }) => {
		const classes = useStyles();

		const history = useHistory();

		const handleNewQuote = () => {
			onHandleResetStateProvider();

			history.push("/");
		};

		console.log("response", dataResponse);

		return (
			<Box marginTop={2} textAlign="center">
				<Title
					text="Las mejores opciones de cobertura"
					variant="h6"
					color="#9a324e"
				/>
				{/* <Title
					text="Contáctanos directamente"
					variant="subtitle1"
					color="#6f6c6d"
				/> */}
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					className={classes.products__btns}
					my={2}
				>
					{/* <PhoneButton number="04-463-3383" /> */}
					<Button variant="outlined" color="secondary" onClick={handleNewQuote}>
						Nueva cotización
					</Button>

					<Hidden only={["xs", "sm"]}>
						<Box marginX={2} />
					</Hidden>

					{/* <WppButton text="+593 99 713 0694" number="+593 99 713 0694" /> */}

					{dataResponse?.fileUrl && (
						<Link
							href={dataResponse.fileUrl}
							download
							target="blank"
							className={classes.btnDonwload}
						>
							DESCARGAR PDF
						</Link>
					)}
				</Box>

				{findmajor && (
					<Box marginTop={2}>
						<Title
							text="+70: Planes exclusivos para adultos mayores de 70 años"
							variant="subtitle1"
							color="#6f6c6d"
						/>
					</Box>
				)}
			</Box>
		);
	}
);
