import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	titleMain: {
		fontWeight: 600,
		color: ({ color }) => (color ? theme.palette.secondary.main : "white"),

		[theme.breakpoints.down(600)]: {
			fontSize: "1.5rem",
		},
	},
	title: {
		fontWeight: 500,
		marginBottom: 5,
		color: ({ color }) =>
			color ? (color !== "" ? color : "white") : "initial",
		textTransform: ({ uppercase }) => (uppercase ? "uppercase" : null),
	},
	titleWithValue: {
		display: "flex",
		alignItems: "center",
		color: theme.palette.grey[600],
		fontWeight: 500,

		"& p": {
			color: ({ boldValue }) =>
				boldValue ? theme.palette.grey[600] : theme.palette.grey[500],
			fontWeight: ({ boldValue }) => (boldValue ? 500 : 0),
		},

		[theme.breakpoints.down(700)]: {
			fontSize: 16,
		},
	},
	titleLineBreak: {
		fontWeight: 500,
		fontSize: 18,
		color: theme.palette.grey[900],
	},
	titleLineBreak2: {
		fontSize: 18,
		color: theme.palette.grey[700],
		wordBreak: "break-all",
	},
}));

export const TitleMain = ({ variant = "h4", color, text, grid }) => {
	const classes = useStyles({ color });

	if (grid)
		return (
			<Grid container>
				<Grid item xs={12}>
					<Box paddingY={2} className="mainTitle">
						<Typography
							className={classes.titleMain}
							variant={variant}
							align="center"
						>
							{text}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		);

	return (
		<Box paddingY={2} className="mainTitle">
			<Typography
				className={classes.titleMain}
				variant={variant}
				align="center"
			>
				{text}
			</Typography>
		</Box>
	);
};

export const Title = ({ variant, color, text, uppercase }) => {
	const classes = useStyles({ color, uppercase });

	return (
		<Typography className={classes.title} variant={variant}>
			{text}
		</Typography>
	);
};

export const TitleWithValue = ({ title, value, boldValue }) => {
	const classes = useStyles({ boldValue });

	return (
		<>
			<Typography className={classes.titleWithValue} variant="subtitle1">
				{title}: <Typography variant="body1">&nbsp;&nbsp;{value}</Typography>
			</Typography>
		</>
	);
};

export const TitleWithLineBreak = ({ title, text }) => {
	const classes = useStyles();

	return (
		<Box display="flex" flexDirection="column">
			<Typography className={classes.titleLineBreak}>{title}</Typography>
			<Typography className={classes.titleLineBreak2}>{text}</Typography>
		</Box>
	);
};

// all proptypes
TitleMain.propTypes = {
	variant: PropTypes.string,
	text: PropTypes.any.isRequired,
};
Title.propTypes = {
	variant: PropTypes.string,
	color: PropTypes.string,
	text: PropTypes.any.isRequired,
};
TitleWithValue.propTypes = {
	title: PropTypes.string,
	value: PropTypes.any,
	boldValue: PropTypes.bool,
};
