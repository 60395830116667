import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Box, Grid } from '@material-ui/core';
import swal from 'sweetalert';

import { userContext } from '../context/user/UserProvider';
import { quoteContext } from '../context/quote/QuoteContext';

import { Header } from './../components/ui/Header';
import { MainContainer } from '../components/containers/MainContainer';
import { TitleMain } from '../components/ui/Titles';
import { CardWithIcon } from '../components/ui/Cards';
import {
	ContentHomeContact,
	ContentHomeForm,
} from '../components/containers/ContentHome';
import BackgroundHome from './../assets/img/cotizador-01-background.png';

import { mookCards } from './../utils/mookCards';
import { getDaysAfter } from './../utils/date';
import {
	validationString,
	validationDate,
	EsCedulaValida,
	validatePhoneNumber,
} from '../utils/validations';

const useStyles = makeStyles((theme) => ({
	home: {
		'& .mainContainer': {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			padding: '30px 24px',
		},
	},
	home__banner: {
		height: '100vh',
		background: `linear-gradient(rgba(0,0,0,.3),rgb(0 0 0 / 77%)),url(${BackgroundHome})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundAttachment: 'fixed',

		[theme.breakpoints.down(950)]: {
			height: 'fit-content',
		},
	},
	home__containerGridContent: {
		height: '100%',
		marginTop: 15,

		'& .inputCustom': {
			'& .MuiFormLabel-root': {
				color: 'white',
			},
			'& input': {
				color: 'white',
			},
			'& fieldset': {
				borderColor: 'rgb(255 255 255 / 30%) !important',
			},
		},

		'& .MuiFormLabel-root': {
			color: 'white',
		},
		'& input': {
			color: 'white',
		},
		'& fieldset': {
			borderColor: 'rgb(255 255 255 / 30%) !important',
		},

		[theme.breakpoints.down(600)]: {
			marginTop: 5,
		},
	},
}));

export const Home = () => {
	const classes = useStyles();

	const [state, setState] = useState({
		dniNumber: '',
		name: '',
		lastname1: '',
		lastname2: '',
		birthdate: null, //new Date()
		civilStatus: '',
		genre: '',
		email: '',
		plate: '',
		departure: '593',
		arrival: '',
		adults: 0,
		minors20: 0,
		adults70: 0,
		date_start: new Date(),
		date_return: new Date(getDaysAfter()),
		health: '',
		wpp: '',
	});
	const [quote, setQuote] = useState('cars');

	const history = useHistory();

	const {
		loading,
		loadingCountries,
		countries,
		departure,
		handleGetCountries,
		handleGetDataVehicle,
		handleGetDateTravel,
	} = useContext(quoteContext);
	const { handleSetInfoUser } = useContext(userContext);

	const handleChangeState = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeStateObj = (data) => {
		setState({
			...state,
			...data,
		});
	};

	const handleChangeNumberPassengers = (type, number) => {
		if (type === 'adults') {
			setState({
				...state,
				adults: Number(number),
			});
		}

		if (type === 'minors20') {
			setState({
				...state,
				minors20: Number(number),
			});
		}

		if (type === 'adults70') {
			setState({
				...state,
				adults70: Number(number),
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			if (quote === 'cars') {
				await validationString(state.name);
				await validationString(state.lastname1);
				await validationString(state.lastname2);
				await validationString(state.civilStatus);
				await validationDate(state.birthdate);
				await validationString(state.genre);
				await validationString(state.email);
				await validationString(state.plate);
				await validationString(state.wpp);
				const phone = await validatePhoneNumber(state.wpp);

				// const plateSanitized = await EsCedulaValida(state.dniNumber);

				const request = {
					name: state.name,
					lastname1: state.lastname1,
					lastname2: state.lastname2,
					genre: state.genre,
					birthdate: state.birthdateDate,
					civilStatus: state.civilStatus,
					email: state.email,
					plate: state.plate,
					mobilePhoneNumber: phone,
				};

				// console.log('vehicle request', request);

				const response = await handleGetDataVehicle(request);
				if (response === 'oka') {
					handleSetInfoUser({
						...request,
						phone,
					});
					history.push('/quote');
				}
			}

			if (quote === 'travel') {
				await validationString(state.email);
				await validationString(state.departure);
				await validationString(state.arrival);
				await validationDate(state.date_start);
				await validationDate(state.date_return);
				await validatePhoneNumber(state.wpp);

				if (state.arrival !== state.departure) {
					let request = {
						email: state.email,
						departure: state.departure,
						arrival: state.arrival,
						periodStart: await validationDate(state.date_start),
						periodEnd: await validationDate(state.date_return),
						adultPassenger: state.adults,
						minorPassenger: state.minors20,
						majorPassenger: state.adults70,
						mobilePhoneNumber: state.wpp,
					};

					// console.log('post travel', request);
					// console.log('number passengers', (state.adults+state.minors20+state.adults70));

					const numberPassengers =
						state.adults + state.minors20 + state.adults70;

					console.log("numberPassengers", numberPassengers)
					const response = await handleGetDateTravel(request, numberPassengers);
					console.log("response handleGetDateTravel => ", response)

					if (response.status) {
						history.push('/quote/travel');
					}else{
						if(response.data !== null){
							swal('Vuelva a intentarlo',response.data, 'info');
							//swal("¡Bien!", "Has hecho clic en el botón :)", "success");
						}
					}
				}
			}
		} catch (error) {
			console.log(error);
			swal('Vuelva a intentarlo', error.message, 'info');
		}
	};

	useEffect(() => {
		(async function () {
			await handleGetCountries();
		})();
		// eslint-disable-next-line
	}, []);

	return (
		<Box className={classes.home}>
			<section className={classes.home__banner}>
				<MainContainer>
					<Header />

					<TitleMain
						text="Nuestro propósito es cuidarte como a nosotros mismos"
						grid
					/>

					<Grid container className={classes.home__containerGridContent}>
						<ContentHomeForm
							state={state}
							quote={quote}
							loadingVehicle={loading}
							loadingCountries={loadingCountries}
							countries={countries}
							departure={departure}
							onSetQuote={setQuote}
							onHandleChangeState={handleChangeState}
							onHandleChangeStateObj={handleChangeStateObj}
							onHandleSubmit={handleSubmit}
							onHandleChangeNumberPassengers={handleChangeNumberPassengers}
						/>

						<ContentHomeContact />
					</Grid>
				</MainContainer>
			</section>

			<MainContainer>
				<TitleMain color text="Nuestros servicios" grid />

				<Grid container spacing={2}>
					{mookCards.map((card, index) => (
						<Grid key={index} item xs={12} sm={6} md={4}>
							<CardWithIcon
								img={card.img}
								icon={card.icon}
								title={card.title}
								text={card.text}
								url={card.url}
							/>
						</Grid>
					))}
				</Grid>
			</MainContainer>
		</Box>
	);
};
