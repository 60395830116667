import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
	mainCarousel: {
		height: "100%",

		"& .slick-arrow": {
			maxHeight: "40px",
			maxWidth: "40px",
			height: "100%",
			width: "100%",
			padding: "10px",
			backgroundColor: "#9a324e !important",
			borderRadius: "50%",
		},
		"& .slick-slider": {
			height: "100% !important",
			display: "flex",
			flexDirection: "column",

			"& ul": {
				position: "initial !important",
				padding: "0 0 16px !important",
				bottom: "0 !important",
			},
			"& .slick-list": {
				height: "100% !important",
				padding: "0 60px 0 30px !important",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",

				[theme.breakpoints.down(600)]: {
					padding: "0 0px 0 0px !important",
				},
			},
			"& .slick-track": {
				padding: "15px 0",
			},
		},

		"& .slick-prev": {
			zIndex: 9999,

			[theme.breakpoints.down(600)]: {
				position: "absolute",
				top: "-36px",
				left: 0,
			},
		},
		"& .slick-next": {
			zIndex: 9999,

			[theme.breakpoints.down(600)]: {
				position: "absolute",
				right: "434px !important",
				top: "-36px",
			},
			[theme.breakpoints.down(570)]: {
				right: "370px !important",
			},
			[theme.breakpoints.down(500)]: {
				right: "270px !important",
			},
			[theme.breakpoints.down(400)]: {
				right: "240px !important",
			},
			// [theme.breakpoints.down(380)]: {
			// 	right: "220px !important",
			// },
		},
	},
}));

const settings = {
	// centerMode: true,
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 600,
			settings: {
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

export const MainCarousel = ({ children }) => {
	const classes = useStyles();

	return (
		<Box className={classes.mainCarousel}>
			<Slider {...settings}>{children}</Slider>
		</Box>
	);
};
