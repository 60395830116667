const getBaseURL = () => {
	 console.log(' AMBIENTE ', process.env.REACT_APP_ENVIRONMENT)

	switch (process.env.REACT_APP_ENVIRONMENT) {
		case "local":
			 console.log('Base URL ', process.env.REACT_APP_API_URL_LOCAL)
			return process.env.REACT_APP_API_URL_LOCAL;
		case "dev":
			 console.log('Base URL ', process.env.REACT_APP_API_URL_DEV)
			return process.env.REACT_APP_API_URL_DEV;
		case "production":
			 console.log('Base URL ', process.env.REACT_APP_API_URL_PRO)
			return process.env.REACT_APP_API_URL_PRO;
		default:
			return "Error occurs";
	}
};

export const environment = {
	typeEnvironment: process.env.REACT_APP_ENVIRONMENT,
	// baseUrl: (process.env.REACT_APP_ENVIRONMENT === 'dev') ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PRO,
	baseURL: getBaseURL(),
	apiKey: process.env.REACT_APP_API_KEY,
};
