import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

import { quoteContext } from "../context/quote/QuoteContext";
import { userContext } from "../context/user/UserProvider";
import { formatDate } from "../utils/date";

import { mookBrand } from "../utils/mookBrandsModels";

export const useQuote = () => {
	const history = useHistory();

	const [brands, setBrands] = useState([]);
	const [models, setModels] = useState([]);
	const [state, setState] = useState({
		brand: "",
		model: "",
		nameBrand: "",
		nameModel: "",
		year: new Date(),
		yearSanitized: new Date().getFullYear(),
		price: 0,
		porcentaje: 0,
		identification: "",
		email: "",
		fullName: "",
		name: "",
		surname: "",
		lastname: "",
		birthdateDate: new Date(),
		birthdate: "",
		age: 0,
	});
	const [surnames, setSurnames] = useState({
		name: "",
		surname: "",
		lastname: "",
	});

	const [registration, setRegistration] = useState(false);
	const [showsurnames, setShowSurnames] = useState(false);
	const [showrange, setShowRange] = useState(false);
	const [movedslider, setMovedSlider] = useState(false);

	const { identification, email, plate, phone } = useContext(userContext);
	const { loading, data, handleGetProductVehicle, handleChangeGlobalState } =
		useContext(quoteContext);

	const handleChangeState = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeSurnames = (e) => {
		setSurnames({
			...surnames,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeStateObj = (data) => {
		setState({
			...state,
			...data,
		});
	};

	const handleVerify = () => {
		// console.log("verify", state);
		if (
			(state.brand !== "" || state.nameBrand !== "") &&
			(state.model !== "" || state.nameModel) &&
			(state.year !== "" || state.yearSanitized) &&
			state.price.trim() !== ""
		) {
			setRegistration(false);
		}
	};

	const handleSetBrand = () => {
		const brands = mookBrand.map((item) =>
			item.models.length > 0
				? { label: item.nombreMarca, value: item.idMarca }
				: null
		);

		const brandsSenate = brands
			.filter((item) => item !== null)
			.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}
				return 0;
			});

		setBrands(brandsSenate);
	};

	const handleSetModel = () => {
		const models = mookBrand.map((item) =>
			item.models.length > 0 ? item.models : null
		);
		const modelsSenate = models.filter((item) => item !== null);

		let modelsArrObj = [];
		modelsSenate.forEach((item) => {
			item.forEach((model) => {
				modelsArrObj.push(model);
			});
		});

		const compareModel = modelsArrObj.map((item) =>
			item.idMarca === state.brand
				? { label: item.nombreModelo, value: item.idModelo }
				: null
		);
		const getModelCurrent = compareModel.filter((item) => item !== null);

		setModels(getModelCurrent);
	};

	const handleBlur = (e, type) => {
		if (type === "stateLocal") {
			if (e.target.value !== "") {
				handleChangeStateObj({
					[e.target.name]: e.target.value.toUpperCase(),
				});
			}
		} else {
			if (e.target.value !== "") {
				handleChangeGlobalState({
					[e.target.name]: e.target.value,
				});
			}
		}
	};

	const handleBlurSurnames = (e) => {
		if (e.target.value !== "") {
			handleChangeStateObj({
				[e.target.name]: e.target.value.toUpperCase(),
			});

			if (e.target.name === "surname" && surnames.lastname !== "") {
				setShowSurnames((c) => !c);
			}

			if (e.target.name === "lastname" && surnames.surname !== "") {
				setShowSurnames((c) => !c);
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (data[0].quotingResults && data[0].quotingResults?.length > 0) {
			if (movedslider) {
				const urlFetch = `quotes/vehicle?brand=${state.nameBrand}&model=${state.nameModel}&year=${state.yearSanitized}&price=${state.porcentaje}`;

				const response = await handleGetProductVehicle(urlFetch, movedslider);

				if (response) {
					history.push("quote/products");
				}
			} else {
				const urlFetch = `quotes/vehicle?brand=${state.nameBrand}&model=${state.nameModel}&year=${state.yearSanitized}&price=${state.price}`;

				const response = await handleGetProductVehicle(urlFetch, movedslider);

				if (response) {
					history.push("quote/products");
				}
			}
		} else {
			if (state.email === "" && state.identification === "") {
				history.push("/");
				return;
			} else {
				const urlFetch = getUrlCustomer();
				// console.log(urlFetch);

				const response = await handleGetProductVehicle(urlFetch, movedslider);

				if (response) {
					history.push("quote/products");
				}
			}
		}
	};

	const getUrlCustomer = () => {
		let url = "";

		if (showrange) {
			url = `quotes/vehicle?brand=${state.nameBrand}&model=${state.nameModel}&year=${state.yearSanitized}&price=${state.porcentaje}`;
		} else {
			url = `quotes/vehicle?brand=${state.nameBrand}&model=${state.nameModel}&year=${state.yearSanitized}&price=${state.price}`;
		}

		if (data[0].clientInfo.name === "") {
			url += `&name=${state.name}`;
		}

		if (data[0].clientInfo.surname === "") {
			url += `&surname=${state.surname}`;
		}

		if (data[0].clientInfo.lastname === "") {
			url += `&lastname=${state.lastname}`;
		}

		if (data[0].clientInfo.birthdate === "") {
			url += `&birthdate=${state.birthdateDate.toISOString()}`;
		}

		return url;
	};

	useEffect(() => {
		(function () {
			ReactGA.pageview("/quote");
		})();
	}, []);

	useEffect(() => {
		(function () {
			// console.log("data", data);
			// console.log("entro aqui 1");
			if (data.length === 0) {
				history.push("/");
				return;
			}

			if (
				typeof data[0].vehicleInfo === "object" &&
				Object.keys(data[0].vehicleInfo).length > 0
			) {
				setState((c) => ({
					...c,
					nameBrand: data[0].vehicleInfo.brand,
					nameModel: data[0].vehicleInfo.model,
					price: data[0].vehicleInfo.price,
					porcentaje: data[0].vehicleInfo.price,
					yearSanitized:
						data[0].vehicleInfo.year !== ""
							? data[0].vehicleInfo.year
							: new Date().getFullYear(),
				}));

				if (data[0].vehicleInfo.price !== 0) {
					setShowRange((c) => !c);
				}

				if (
					data[0].vehicleInfo.brand !== "" &&
					data[0].vehicleInfo.model !== "" &&
					data[0].vehicleInfo.year !== "" &&
					data[0].vehicleInfo.price !== 0
				) {
					setRegistration(false);
				} else {
					setMovedSlider(true);
					setRegistration(true);
				}
			} else {
				setRegistration(true);
			}

			if (
				typeof data[0].clientInfo === "object" &&
				Object.keys(data[0].clientInfo).length > 0
			) {
				setState((c) => ({
					...c,
					identification: data[0].clientInfo.dniNumber,
					email: data[0].clientInfo.email,
					fullName: data[0].clientInfo.fullName,
					name: data[0].clientInfo.name,
					surname: data[0].clientInfo.surname,
					lastname: data[0].clientInfo.lastname,
					birthdate:
						data[0].clientInfo.birthdate !== "" &&
						formatDate(data[0].clientInfo.birthdate),
					age: data[0].clientInfo.age,
				}));

				if (
					data[0].clientInfo.surname !== "" &&
					data[0].clientInfo.lastname !== ""
				) {
					setShowSurnames(true);
				} else {
					setMovedSlider(true);
				}
			}
		})();
		// eslint-disable-next-line
	}, [setState]);

	useEffect(() => {
		(function () {
			// console.log("entro aqui 2");
			handleSetBrand();
		})();
	}, []);

	useEffect(() => {
		(function () {
			// console.log("entro aqui 3");
			handleSetModel();
		})();
	}, [state.brand]);

	return {
		loading,
		brands,
		models,
		data,
		state,
		identification,
		email,
		phone,
		plate,
		registration,
		surnames,
		showsurnames,
		showrange,
		handleChangeState,
		handleChangeSurnames,
		handleChangeStateObj,
		handleVerify,
		handleBlur,
		handleBlurSurnames,
		handleSubmit,
		setMovedSlider,
	};
};
