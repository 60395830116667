import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  customParagraph: {
    color: ({ color }) => color ? '#dbddde' : '#5a5f64',
  },
  customParagraphIcon: {
    display: 'flex',
    alignItems: 'center',
    color: ({ color }) => color ? 'white' : 'black',

    '& svg': {
      marginRight: 5
    }
  }
}));

export const CustomParagraph = ({ text, color }) => {
  const classes = useStyles({ color });
  
  return (
    <Typography
      className={classes.customParagraph}
      variant="body1"
    >
      {text}
    </Typography>
  )
}

export const CustomParagraphIcon = ({ icon: Icon, text, color }) => {
  const classes = useStyles({ color });

  return (
    <>
      <Typography
        className={classes.customParagraphIcon}
        variant="body1"
        align="center"
      >
        <Icon /> {text}
      </Typography>
    </>
  )
}