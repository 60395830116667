import {
	memo,
	useState,
	useContext,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

import { userContext } from "../context/user/UserProvider";
import { quoteContext } from "../context/quote/QuoteContext";

import { MainContainer } from "../components/containers/MainContainer";
import { LinearDeterminate } from "../components/ui/Loadings";
import { MainCarousel } from "../components/containers/MainCarousel";
import { CardShare } from "../components/ui/Cards";
import { LottieAnimation } from "../components/ui/LottieAnimation";
import { QuotingSuccessModal } from "../components/ui/Modal";
import { ResultQuotingTitle } from "../components/containers/ResultQuotingTitle";
import { FilterResults } from "../components/containers/FilterResults";

const useStyles = makeStyles((theme) => ({
	products: {
		height: "100%",
		"& .mainContainer": {
			height: "100%",
			maxWidth: "1300px",

			"& .MuiGrid-container": {
				height: "100%",
			},
		},
	},
	products__containerGridProgress: {
		marginTop: 40,
	},
	products__containerSlider: {
		display: "flex",
		flexDirection: "column",
	},
}));

export const Products = memo(() => {
	const classes = useStyles();

	// const history = useHistory();

	const [hideprogress, setHideProgress] = useState(false);
	const [findmajor, setFindMajor] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [openmodal, setOpenModal] = useState(false);

	const [orderby, setOrderBy] = useState("desc");
	const [items] = useState([
		{ label: "Mayor", value: "asc" },
		{ label: "Menor", value: "desc" },
	]);

	const {
		quoteType,
		loadingProducts,
		data,
		dataResponse,
		handleSetLoadingProduct,
		handleNameCountries,
		handleResetStateProvider,
	} = useContext(quoteContext);
	const { email } = useContext(userContext);

	const handleChangeAccordeon = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handlePurchasePlan = useCallback(
		(product) => {
			// handleSetProduct(product);
			// history.push("/quote/product");
			setOpenModal((c) => !c);
		},
		[setOpenModal]
	);

	const handleChangeOrder = useCallback(
		({ target }) => setOrderBy(target.value),
		[setOrderBy]
	);

	const cardsVehicle = useMemo(() => {
		if (orderby === "asc") {
			return data[0].quotingResults.sort(
				(a, b) => parseFloat(b.totalPrime) - parseFloat(a.totalPrime)
			);
		} else {
			return data[0].quotingResults.sort(
				(a, b) => parseFloat(a.totalPrime) - parseFloat(b.totalPrime)
			);
		}
	}, [orderby, data]);

	const cardsTravel = useMemo(() => {
		if (orderby === "asc") {
			return data[0].quotingResults.sort(
				(a, b) => parseFloat(b.total) - parseFloat(a.total)
			);
		} else {
			return data[0].quotingResults.sort(
				(a, b) => parseFloat(a.total) - parseFloat(b.total)
			);
		}
	}, [orderby, data]);

	const showCards = () => {
		if (quoteType === "vehicle") {
			return cardsVehicle.map((item, index) => (
				<CardShare
					key={index}
					product={item}
					priceProduct={data[0].vehicleInfo.price}
					urlShare={`type=vehicle&logo=${item.logo}&productName=${
						item.productName
					}&priceProduct=${
						data[0].vehicleInfo.price
					}&netPrimeRateLabel=${item.netPrimeRateLabel
						.trim()
						.replace("%", "")}&netPrime=${item.netPrime}&policyFee=${
						item.policyFee
					}&impSeguroCampesino=${item.impSeguroCampesino}&impSuperBancos=${
						item.impSuperBancos
					}&tax=${item.tax}&totalPrime=${item.totalPrime}`}
					accordeon={index + 1}
					expanded={expanded}
					onHandleChangeAccordeon={handleChangeAccordeon}
					onHandlePurchasePlan={handlePurchasePlan}
				/>
			));
		}

		if (quoteType === "travel") {
			return cardsTravel.map((item, index) => (
				<CardShare
					key={index}
					travel
					product={item}
					urlShare={`type=travel&logo=${item.logo}&departure=${
						item.departure
					}&arrival=${item.arrival}&coverageDays=${item.coverageDays}&product=${
						item.product
					}&fileUrl=${item.fileUrl}&rateDescription=${
						item.rateDescription
					}&total=${item.total}&selectedPlan=${true}&major=${false}`}
					onGetNameCountries={handleNameCountries}
					onHandlePurchasePlan={handlePurchasePlan}
				/>
			));
		}
	};

	const showTitleMajor = () => {
		if (quoteType === "travel") {
			data.forEach((item) => {
				// console.log(item.quotingResults);
				const response = item.quotingResults.find(
					(c) => c.lastname === "MAYOR 70"
				);

				if (response && !findmajor) {
					setFindMajor(true);
				}
			});
		}
	};

	useEffect(() => {
		(function () {
			ReactGA.pageview("/quote");

			showTitleMajor();
		})();
		// eslint-disable-next-line
	}, []);

	// console.log("data", data);

	return (
		<Box className={classes.products}>
			<MainContainer>
				{!hideprogress && loadingProducts && (
					<Grid container className={classes.products__containerGridProgress}>
						<Grid item xs={12}>
							<LinearDeterminate
								setHiddenProgress={setHideProgress}
								handleSetLoadingProduct={handleSetLoadingProduct}
							/>
							<Typography variant="h6" align="center">
								Estamos procesando tu solicitud, por favor espera un momento...
							</Typography>
							<LottieAnimation />
						</Grid>
					</Grid>
				)}

				{!loadingProducts && (
					<Grid container className="animate__animated animate__zoomIn">
						<Grid item xs={12} className={classes.products__containerSlider}>
							<ResultQuotingTitle
								findmajor={findmajor}
								dataResponse={dataResponse}
								onHandleResetStateProvider={handleResetStateProvider}
							/>

							<FilterResults
								data={data[0]}
								orderby={orderby}
								items={items}
								email={email}
								onHandleChangeOrder={handleChangeOrder}
							/>

							<MainCarousel>{showCards()}</MainCarousel>
						</Grid>
					</Grid>
				)}
			</MainContainer>
			<QuotingSuccessModal
				open={openmodal}
				onHandleClose={() => setOpenModal((c) => !c)}
				onHandleResetStateProvider={handleResetStateProvider}
			/>
		</Box>
	);
});
